import produce from 'immer';
import moment from 'moment';
import {
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_FAILURE,
  POST_PRODUCT_SUCCESS,
  POST_PRODUCT_REQUEST,
  POST_PRODUCT_FAILURE,
  ON_SELECT_PRODUCT,
  ON_CHANGE_FILTER
} from './constants';
import { parseJson, groupBy } from '@utils/helper';

const DEFAULT_FILTER = {
  name: ''
}

export const initialState = {
  list: [],
  loading: false,
  postLoading: false,
  selectedProduct: {},
  materialList: [],
  filter: { ...DEFAULT_FILTER }
};

const parseMeta = item => {
  const { metaInfo, wooVariationIds, deliveryDate, ...rest } = item;
  return {
    ...rest,
    metaInfo: parseJson(metaInfo, {}),
    wooVariationIds: parseJson(wooVariationIds, {}),
    deliveryDate: deliveryDate > 0 ? moment(deliveryDate * 1000) : 0,
  }
}

const initData = list => {
  return list.map(parseMeta);
}

/* eslint-disable default-case, no-param-reassign */
const mainReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_PRODUCT_REQUEST: {
        draft.loading = true;
        break;
      }
      case GET_PRODUCT_SUCCESS: {
        const data = action.payload;
        const list = initData(data);
        draft.list = list;
        draft.loading = false;
        const materialList = groupBy(list, 'material');
        draft.materialList = materialList.sort((a, b) => a.localeCompare(b));
        console.log(materialList);
        break;
      }
      case GET_PRODUCT_FAILURE: {
        draft.list = [];
        draft.loading = false;
        break;
      }
      case POST_PRODUCT_REQUEST: {
        draft.postLoading = true;
        break;
      }
      case POST_PRODUCT_SUCCESS: {
        const data = parseMeta(action.payload);
        const { productId } = data;
        const ind = draft.list.findIndex(x => x.productId === productId);
        if (ind > -1) {
          draft.list[ind] = {
            ...state.list[ind],
            ...data
          }
        }
        draft.postLoading = false;
        break;
      }
      case POST_PRODUCT_FAILURE: {
        draft.postLoading = false;
        break;
      }
      case ON_SELECT_PRODUCT: {
        draft.selectedProduct = action.payload;
        break;
      }
      case ON_CHANGE_FILTER: {
        const { fieldName, fieldValue } = action.payload;
        if (fieldValue !== undefined) {
          draft.filter[fieldName] = fieldValue;
        } else {
          draft.filter = { ...DEFAULT_FILTER }
        }

        break;
      }
    }
  });

export default mainReducer;
