import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Row, Col } from 'antd';

import { useInjectSaga } from '@utils/injectSaga';
import { useInjectReducer } from '@utils/injectReducer';
import { getPlacesAction, getOnlineAction, getCheckInHistoryAction } from './dashboard.actions';
import reducer from './dashboard.reducer';
import saga from './dashboard.saga';

import Place from './Place';
import History from './History';

const key = 'dashboard';

function Dashboard(props) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    props.getPlaces();
    props.getOnline();
    props.getHistory();
  });

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div style={{ marginBottom: 16 }}>
        <Row>
          <Col sm={24}>
            <h2>Dashboard</h2>
          </Col>
        </Row>
        <Place />
        <History />
      </div>
    </>
  );
}

Dashboard.propTypes = {
  getPlaces: PropTypes.func,
  getOnline: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = dispatch => ({
  getPlaces: () => dispatch(getPlacesAction()),
  getOnline: () => dispatch(getOnlineAction()),
  getHistory: () => dispatch(getCheckInHistoryAction()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Dashboard);
