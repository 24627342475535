import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Row, Col, Spin, Table, Drawer, Button, Icon } from 'antd';
import { getUserListAction } from '@/global.actions';
import { makeSelectUserList, makeSelectUserListLoading } from '@/global.selectors';
import UserDetail from './detail';

function UserList({userList, userListLoading, getUserList}) {
  const [selectedUser, setSelectedUser] = useState({});

  const handleSave = () => {
    setSelectedUser({});
    getUserList();
  }
  const handleSelect = (item) => {
    setSelectedUser(item);
  }
  const renderAction = (id, rec) => {
    return (
      <>
        <Button icon="edit" onClick={() => handleSelect(rec)} />
      </>
    )
  }

  const columns = [
    {
      dataIndex: 'id',
      title: 'Mã NV'
    },
    {
      dataIndex: 'name',
      title: 'Họ Tên'
    },
    {
      dataIndex: 'avatar',
      title: 'Avatar',
      render: text => <img alt="Avatar" src={text} width={50} height={50} />
    },
    {
      dataIndex: 'active',
      title: 'Active',
      render: text => text ? <Icon type="check-circle" theme="twoTone" /> : '',
      sorter: (a, b) => b.active - a.active
    },
    {
      dataIndex: 'bankName',
      title: 'Tên NH',
    },
    {
      dataIndex: 'bankAccount',
      title: 'Số TK',
    },
    {
      dataIndex: 'phone',
      title: 'Điện thoại',
    },
    {
      render: renderAction
    },
  ]
  const data = userList.filter(x => x.role > 1).sort((a,b) => b.active - a.active);
  const activeEmployee = data.filter(x => x.active).length;
  return (<>
    <Row gutter={16}>
      <Col sm={14}>
        <h2>Nhân Viên ({activeEmployee}/{data.length})</h2>
      </Col>
    </Row>
    <Spin spinning={userListLoading}>
      <Row gutter={16}>
        <Table
          dataSource={data}
          columns={columns}
          rowKey="id" />
      </Row>
    </Spin>
    <Drawer visible={selectedUser.id} onClose={handleSelect} width="50%" title={`Cập nhật thông tin nhân viên ${selectedUser.name}`}>
      <UserDetail
        user={selectedUser}
        onSave={handleSave}
        onCancel={handleSelect}
      />
    </Drawer>
  </>)
}

UserList.propTypes = {
};

UserList.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  userList: makeSelectUserList(),
  userListLoading: makeSelectUserListLoading(),
});
const mapDispatchToProps = dispatch => ({
  getUserList: () => dispatch(getUserListAction()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(UserList);