import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import request from '@utils/request';
import { formatNumber } from '@utils/helper';
import { Row, Col, Spin } from 'antd';
import { Treemap } from '@ant-design/plots';
import RelativeDate from '@common/RelativeDate';
import SumTable from '@common/SumTable';

function ProductQuantity() {
  const [tableData, setTableData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const { isLoading: isLoadingSaleQuantity, mutate: getSaleQuantity } = useMutation(({ timeRange }) => {
    const { from, to } = timeRange;
    return request.get(`v1/statistics/products?type=qty&from=${from}&to=${to}`);
  },
    {
      onSuccess: (response) => {
        setSaleData(response.data);
      },
    });
  const setSaleData = (resData = []) => {
    const newData = [...tableData];
    resData.forEach(x => {
      const { groupName } = x;
      const ind = newData.findIndex(n => n.groupName === groupName);
      if(ind > -1){
        let qty = parseInt(x.qty, 0);
        newData[ind].saleQuantity += qty;
      }
    });
    setTableData(newData);
  }
  const { isLoading, mutate } = useMutation(() => {
    return request.get(`v1/product-meta/quantities`);
  },
    {
      onSuccess: (response) => {
        initData(response.data);
      },
    })
  const initData = (resData) => {
    const tableData = [];
    resData.filter(x => x.available > 0 && x.size !== 'RRE').forEach(x => {
      const { groupName, shortName, available, branchId } = x;
      const quantity = parseInt(available, 0);
      let ind = tableData.findIndex(t => t.shortName === shortName);
      if (ind < 0) {
        tableData.push({
          no: tableData.length + 1,
          shortName,
          groupName,
          quantity: 0,
          branch1Quantity: 0,
          branch2Quantity: 0,
          branch3Quantity: 0,
          branch4Quantity: 0,
          saleQuantity: 0
        });
        ind = tableData.length - 1;
      }
      tableData[ind].quantity += quantity;
      tableData[ind][`branch${branchId}Quantity`] += quantity;
    });
    tableData.sort((a, b) => b.quantity - a.quantity);
    const treeData = tableData.map(t => ({
      name: t.groupName,
      value: t.quantity,
    }))
    setTableData(tableData);
    setChartData({
      name: 'root',
      children: treeData
    });
  }
  const onChangeDate = (type, timeRange) => {
    getSaleQuantity({ timeRange });
  }
  useEffect(() => {
    mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const columns = [
    {
      title: 'SKU',
      dataIndex: 'shortName',
    },
    {
      title: 'Sản phẩm',
      dataIndex: 'groupName',
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      align: 'right',
      render: formatNumber,
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: 'Đã bán',
      dataIndex: 'saleQuantity',
      align: 'right',
      render: formatNumber,
      sorter: (a, b) => a.saleQuantity - b.saleQuantity,
    },
    {
      title: 'Shop 48 HVH',
      dataIndex: 'branch1Quantity',
      align: 'right',
      render: formatNumber,
      sorter: (a, b) => a.branch1Quantity - b.branch1Quantity,
    },
    {
      title: 'Shop 222 NDC',
      dataIndex: 'branch3Quantity',
      align: 'right',
      render: formatNumber,
      sorter: (a, b) => a.branch3Quantity - b.branch3Quantity,
    },
    {
      title: 'Shop 257 NH',
      dataIndex: 'branch4Quantity',
      align: 'right',
      render: formatNumber,
      sorter: (a, b) => a.branch4Quantity - b.branch4Quantity,
    },
    {
      title: 'Kho',
      dataIndex: 'branch2Quantity',
      align: 'right',
      render: formatNumber,
      sorter: (a, b) => a.branch2Quantity - b.branch2Quantity,
    }
  ]
  const numberColumns = ['quantity','saleQuantity', 'branch1Quantity', 'branch3Quantity', 'branch4Quantity', 'branch2Quantity'];
  return (<>
    <Row gutter={16}>
      <Col sm={14}>
        <h2>Sản phẩm tồn</h2>
      </Col>
      <Col sm={10}>
        <RelativeDate onChange={onChangeDate} />
      </Col>
    </Row>
    <Spin spinning={isLoading || isLoadingSaleQuantity}>
      <Row gutter={16} style={{ marginTop: '2rem' }}>
        <Treemap data={chartData} colorField="name" />
      </Row>
      <Row gutter={16} style={{ marginTop: '2rem' }}>
        <Col sm={24}>
          <SumTable
            numberColumns={numberColumns}
            pagination={false}
            dataSource={tableData}
            columns={columns}
            rowKey="shortName" />
        </Col>
      </Row>
    </Spin>
  </>)
}

ProductQuantity.propTypes = {
};

ProductQuantity.defaultProps = {
};

export default ProductQuantity;
