import {
  GET_PRODUCT_LIST_REQUEST,
  GET_PRODUCT_LIST_FAILURE,
  GET_PRODUCT_LIST_SUCCESS,
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_FAILURE,
  GET_USER_LIST_SUCCESS,
  GET_PRODUCT_META_LIST_REQUEST,
  GET_PRODUCT_META_LIST_FAILURE,
  GET_PRODUCT_META_LIST_SUCCESS,
} from './global.constants';

export const getProductListAction = payload => ({ type: GET_PRODUCT_LIST_REQUEST, payload });
export const getProductListSuccess = payload => ({ type: GET_PRODUCT_LIST_SUCCESS, payload });
export const getProductListFailure = payload => ({ type: GET_PRODUCT_LIST_FAILURE, payload });

export const getUserListAction = payload => ({ type: GET_USER_LIST_REQUEST, payload });
export const getUserListSuccess = payload => ({ type: GET_USER_LIST_SUCCESS, payload });
export const getUserListFailure = payload => ({ type: GET_USER_LIST_FAILURE, payload });

export const getProductMetaListAction = payload => ({ type: GET_PRODUCT_META_LIST_REQUEST, payload });
export const getProductMetaListSuccess = payload => ({ type: GET_PRODUCT_META_LIST_SUCCESS, payload });
export const getProductMetaListFailure = payload => ({ type: GET_PRODUCT_META_LIST_FAILURE, payload });