import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Avatar } from 'antd';

import { makeSelectUserList } from '@/global.selectors';

class UserAvatar extends PureComponent {
  render() {
    const { userId, userList, className, mode } = this.props;
    const user = userList.find(x => x.id === userId);
    if (!user) { return null; }
    if (mode === 'avatar') {
      return <Avatar className={className} src={user.avatar} />
    }
    if (mode === 'short') {
      const names = user.name.split(' ');
      return names[0];
    }
    return user.name;
  }
}

UserAvatar.propTypes = {
  userId: PropTypes.number,
  className: PropTypes.string,
  mode: PropTypes.string,
};

UserAvatar.defaultProps = {
  userId: 0,
  className: '',
  mode: 'avatar'
};

const mapStateToProps = createStructuredSelector({
  userList: makeSelectUserList(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, memo)(UserAvatar);
