
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import querystring from 'query-string';
import request from '@utils/request';
import { formatNumber } from '@utils/helper';
import { Table, Row, Col, Spin } from 'antd';
import './style.less';

function Statistic({ categoryList, year, month }) {
  const [list, setList] = useState([]);
  const { mutate: getStat, isLoading } = useMutation(({ year, month }) => {
    const option = {
      year,
      month
    }
    const qs = querystring.stringify(option);
    return request.get(`v1/tracking-inboxs/statistic?${qs}`);
  },
    {
      onSuccess: (response) => {
        const { data } = response;
        setList(data);
      },
    });
  useEffect(() => {
    getStat({ month, year });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const renderCategoryName = id => {
    const item = categoryList.find(x => x.id === id);
    if(item) return item.name;
    return id;
  }
  const columns = [
    {
      dataIndex: 'trackingCategoryId',
      title: 'Danh mục',
      render: renderCategoryName
    },
    {
      dataIndex: 'qty',
      title: 'Số lượng',
      align: 'right',
      render: formatNumber
    }
  ]
  return (
    <Spin spinning={isLoading}>
      <h3>Tháng {month}/{year}</h3>
      <Row gutter={8}>
        <Col sm={24}>
          <Table columns={columns} dataSource={list} pagination={false} />
        </Col>
      </Row>
    </Spin>
  )
}

Statistic.propTypes = {
  year: PropTypes.number,
  month: PropTypes.number,
  onClose: PropTypes.func,
};

Statistic.defaultProps = {
  deptID: 0,
  year: 0,
  month: 0,
  onClose: () => { }
};

export default Statistic;
