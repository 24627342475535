import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { key } from './index';

const mainDomain = state => state[key] || initialState;


const selectList = () => createSelector(mainDomain, substate => substate.list);
const selectLoading = () => createSelector(mainDomain, substate => substate.loading);
const selectPostLoading = () => createSelector(mainDomain, substate => substate.postLoading);
const selectProduct = () => createSelector(mainDomain, substate => substate.selectedProduct);

const selectFilter = () => createSelector(mainDomain, substate => substate.filter);

const selectMaterialList = () => createSelector(mainDomain, substate => substate.materialList);

export {
  selectList,
  selectLoading,
  selectPostLoading,
  selectProduct,
  selectFilter,
  selectMaterialList
};
