import { takeLatest, call, put, select } from 'redux-saga/effects';
import { makeSelectUser } from '@/global.selectors';
import {
  getOrderTrackingSuccess,
  getOrderTrackingFailure,
  postOrderMetaSuccess,
  postOrderMetaFailure,
  onSelectOrderAction,
} from './orderTracking.actions';
import { GET_ORDER_TRACKING_REQUEST, POST_ORDER_META_REQUEST } from './orderTracking.constants';
import { getOrderTrackingApi, postOrderMetaApi } from './orderTracking.api';


export function* getOrderTrackingSaga() {
  try {
    const user = yield select(makeSelectUser());
    let userId = 0;
    if (user.role > 1) {
      userId = user.id;
    }
    const res = yield call(getOrderTrackingApi, { userId });
    yield put(getOrderTrackingSuccess(res.data));
  } catch (error) {
    console.log(error);
    yield put(getOrderTrackingFailure(error));
  }
}

export function* postOrderMetaSaga(action) {
  try {
    const res = yield call(postOrderMetaApi, action.payload);
    yield put(postOrderMetaSuccess(res.data));
    yield put(onSelectOrderAction({}));
  } catch (error) {
    yield put(postOrderMetaFailure(error));
  }
}

export default function* boardSaga() {
  yield takeLatest(GET_ORDER_TRACKING_REQUEST, getOrderTrackingSaga);
  yield takeLatest(POST_ORDER_META_REQUEST, postOrderMetaSaga);
}
