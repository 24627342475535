import PropTypes from 'prop-types';

const { shape, number, string, bool, oneOf } = PropTypes;

export const orderType = shape({
  id: number,
  orderCode: string,
  note: string,
  image: string,
  loading: bool,
  error: oneOf([bool, string]),
  status: number,
  isUrgent: bool,
  isChange: bool,
  createdAt: number,
  updatedAt: number,
});
