import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Table from '@common/Table';
import { formatNumber } from '@utils/helper';

const SumTable = ({ columns, dataSource, numberColumns, ...rest }) => {
  const totalArr = {};
  numberColumns.forEach(col => {
    totalArr[col] = dataSource.reduce((prev, curr) => {
      let currentValue = curr[col] || 0;
      if (typeof curr[col] === 'string') {
        currentValue = parseInt(curr[col], 0);
      }
      return prev + currentValue;
    }, 0);
  })
  const newColumns = columns.map(c => {
    if (numberColumns.includes(c.dataIndex)) {
      const title = (<><b>{c.title}</b><br /><i>{formatNumber(totalArr[c.dataIndex] || 0)}</i></>)
      return {
        ...c,
        title,
        orgTitle: c.title,
      }
    }
    return c;
  })
  return (
    <Table columns={newColumns} dataSource={dataSource} {...rest} />
  );
}

SumTable.propTypes = {
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  numberColumns: PropTypes.array,
};

SumTable.defaultProps = {
  columns: [],
  dataSource: [],
  numberColumns: [],
}

export default memo(SumTable);
