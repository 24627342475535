export const GET_PRODUCT_LIST_REQUEST = 'global/GET_PRODUCT_LIST_REQUEST';
export const GET_PRODUCT_LIST_SUCCESS = 'global/GET_PRODUCT_LIST_SUCCESS';
export const GET_PRODUCT_LIST_FAILURE = 'global/GET_PRODUCT_LIST_FAILURE';

export const GET_USER_LIST_REQUEST = 'global/GET_USER_LIST_REQUEST';
export const GET_USER_LIST_SUCCESS = 'global/GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAILURE = 'global/GET_USER_LIST_FAILURE';

export const GET_PRODUCT_META_LIST_REQUEST = 'global/GET_PRODUCT_META_LIST_REQUEST';
export const GET_PRODUCT_META_LIST_SUCCESS = 'global/GET_PRODUCT_META_LIST_SUCCESS';
export const GET_PRODUCT_META_LIST_FAILURE = 'global/GET_PRODUCT_META_LIST_FAILURE';