import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useMutation } from 'react-query';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import request from '@utils/request';
import querystring from 'query-string';
import { formatNumber, formatDate, formatDateTime } from '@utils/helper';
import { makeSelectUserList, makeSelectUser } from '@/global.selectors';
import UserInfo from '@common/UserInfo';
import { Row, Col } from 'antd';
import Table from '@common/Table';
import Detail from './Detail';


const getDate = () => {
  const curr = new Date();
  const day = curr.getDate();
  let month = curr.getMonth() + 1;
  let year = curr.getFullYear();
  if (day <= 5) {
    month -= 1;
  }
  if (month === 0) {
    month = 12;
    year -= 1;
  }
  if (window.location.search) {
    let params = querystring.parse(window.location.search);
    if (params.year) year = params.year;
    if (params.month) month = params.month;
  }
  if (window.location.search) {
    let params = querystring.parse(window.location.search);
    if (params.year) year = params.year;
    if (params.month) month = params.month;
  }
  return { month, year };
}

function CheckInHistory(props) {
  const [checkInHistory, setCheckInHistory] = useState([]);
  const [data, setData] = useState([]);
  const [date] = useState(getDate());
  const { isLoadingCheckIn, mutate: getCheckinHistory } = useMutation(() => {
    return request.get(`v1/admin/check-in-history?year=${date.year}&month=${date.month}`);
  },
    {
      onSuccess: (response) => {
        const ciHistory = initHistory(response.data);
        setCheckInHistory(ciHistory);
        
      },
    });
  const initHistory = data => {
    const result = [];

    data.forEach(ci => {
      const createdDate = formatDate(ci.createdAt * 1000);
      const createdDateTime = formatDateTime(ci.createdAt * 1000);
      const ind = result.findIndex(x => x.userId === ci.userId && x.createdDate === createdDate);
      if (ind < 0) {
        if (ci.type === 1) {
          result.push({
            id: result.length + 1,
            createdDate,
            placeId: ci.placeId,
            createdAt: ci.createdAt,
            userId: ci.userId,
            sIn: createdDateTime,
            sOut: '',
            cIn: ci.createdAt,
            cOut: ci.createdAt,
            hour: 0,
            min: 0,
          })
        }
      } else {
        if (ci.type === 1) {
          result[ind].sIn = `${result[ind].sIn}, ${createdDateTime}`;
          result[ind].cIn = ci.createdAt;
          result[ind].cOut = ci.createdAt;
        } else {
          if (result[ind].cOut <= result[ind].cIn) {
            result[ind].sOut = result[ind].sOut ? `${result[ind].sOut}, ${createdDateTime}` : createdDateTime;
            result[ind].cOut = ci.createdAt;
            const { cOut, cIn } = result[ind];
            const minutes = (cOut - cIn) / 60;
            result[ind].hour += Math.floor(minutes / 60);
            result[ind].min += Math.floor(minutes % 60);
          }
        }
      }

    });
    setData(result);
    const tResult = [];
    const employeeList = props.userList.filter(x => x.role > 1 && x.active);
    const empList = props.user.role > 1 ? [employeeList.find(x => x.id === props.user.id)] : employeeList;
    empList.forEach(item => {
      let salHour = (item.hour || 9);
      if (item.id === 12) salHour = 8;
      const hourInday = salHour - 1;
      const empFilter = result.filter(x => x.userId === item.id);
      let day = 0, hourPerDay = 0, minPerDay = 0, hour = 0, min = 0, lastDate = '';

      empFilter.forEach(ef => {
        if (item.empType === 2) {
          hour += ef.hour;
          min += ef.min;
        } else {
          const { createdDate } = ef;
          if (lastDate !== createdDate) {
            if (hourPerDay) {
              hour += hourPerDay;
            }
            if (minPerDay) {
              min += minPerDay;
            }
            lastDate = createdDate;
            hourPerDay = ef.hour;
            minPerDay = ef.min;
            if (hourPerDay >= hourInday) {
              day += 1;
              hour += hourPerDay - salHour;
              min += minPerDay;
              hourPerDay = 0;
              minPerDay = 0;
            }
          } else {
            minPerDay += ef.min;
            hourPerDay += ef.hour + Math.floor(minPerDay / 60);
            minPerDay = minPerDay % 60;
            if (hourPerDay === hourInday - 1 && hour) {
              hourPerDay += 1;
              hour -= 1;
            }
            if (hourPerDay >= hourInday) {
              day += 1;
              hour += hourPerDay - salHour;
              min += minPerDay;
              hourPerDay = 0;
              minPerDay = 0;
            }
          }
        }
        hour += Math.floor(min / 60);
        min = min % 60;
      });

      if (day || hour) {
        tResult.push({
          id: tResult.length + 1,
          userId: item.id,
          day,
          hour
        })
      }
    })
    return tResult;
  }
  useEffect(() => {
    getCheckinHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userList])
  const columns = [
    {
      title: 'ID',
      dataIndex: 'userId',
    },
    {
      title: 'Nhân viên',
      render: (text, rec) => <UserInfo userId={rec.userId} />
    },
    {
      title: 'Ngày công',
      dataIndex: 'day',
      align: 'right',
      render: formatNumber
    },
    {
      title: 'Giờ công',
      dataIndex: 'hour',
      align: 'right',
      render: formatNumber
    },
  ];
  const userList = props.userList.filter(x => x.role > 1 && x.active).map(x => ({ text: x.name, value: x.id }));
  const renderOut = (text, rec) => {
    if (!text) {
      return <Detail placeId={rec.placeId} userId={rec.userId} selectedDate={rec.createdDate} onSuccess={getCheckinHistory} />
    }
    return text;
  }
  const ciColumns = [
    {
      title: 'Nhân viên',
      dataIndex: 'userId',
      render: text => <UserInfo userId={text} />,
      filters: userList,
      onFilter: (value, record) => record.userId === value,
    },
    {
      title: 'Ngày',
      dataIndex: 'createdDate',
    },
    {
      title: 'Vào',
      dataIndex: 'sIn',
    },
    {
      title: 'Ra',
      dataIndex: 'sOut',
      render: renderOut
    },
    {
      title: 'Giờ',
      dataIndex: 'hour',
    },
    {
      title: 'Phút',
      dataIndex: 'min',
    },
  ]
  if (props.user.role > 1) {
    columns.splice(0, 1);
    ciColumns.splice(0, 1);
  }
  return (
    <>
      <Row gutter={16}>
        <Col sm={16}>
          <h2>Chấm công {date.month}/{date.year}</h2>
        </Col>
      </Row>
      <Row gutter={16}>

      </Row>
      <Row gutter={16}>
        <Col sm={24}>
          <Table
            loading={isLoadingCheckIn}
            dataSource={checkInHistory}
            columns={columns}
            rowKey="id"
            pagination={{ pageSize: 30 }}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col sm={24}>
          <Table
            loading={isLoadingCheckIn}
            dataSource={data}
            columns={ciColumns}
            rowKey="id"
            pagination={{ pageSize: 80 }}
          />
        </Col>
      </Row>
    </>
  );
}

CheckInHistory.propTypes = {
};

CheckInHistory.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  userList: makeSelectUserList(),
  user: makeSelectUser(),
});

const mapDispatchToProps = dispatch => ({

});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CheckInHistory);
