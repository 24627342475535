import { createSelector } from 'reselect';
import { initialState } from './dashboard.reducer';

const selectBoardDomain = state => state.dashboard || initialState;

const makeSelectPlaces = () => createSelector(selectBoardDomain, substate => substate.places);
const makeSelectPlaceLoading = () => createSelector(selectBoardDomain, substate => substate.placeLoading);

const makeSelectOnlineList = () => createSelector(selectBoardDomain, substate => substate.onlineList);
const makeSelectCheckInLoading = () => createSelector(selectBoardDomain, substate => substate.checkInLoading);

const makeSelectCheckInHistory = () => createSelector(selectBoardDomain, substate => substate.checkInHistory);
const makeSelectPlaceLocationLoading = () => createSelector(selectBoardDomain, substate => substate.placeLocationLoading);

export {
  selectBoardDomain,
  makeSelectPlaces,
  makeSelectOnlineList,
  makeSelectCheckInLoading,
  makeSelectCheckInHistory,
  makeSelectPlaceLoading,
  makeSelectPlaceLocationLoading
};
