import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { Layout } from 'antd';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { makeSelectUser } from 'global.selectors';
import SubNav from '@common/SubNav';

import { useInjectSaga } from '@utils/injectSaga';
import { useInjectReducer } from '@utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import routes from './routes';


import './style.less';

export const key = 'statistics';

function Statistics(props) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  let title = 'Thống kê';
  const selectedRoute = routes.find(x => x.path === props.location.pathname);
  if (selectedRoute) {
    title = selectedRoute.name;
  }
  const mainRoutes = routes.filter(x => (!x.permission || x.permission >= props.user.role) )
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Layout style={{ minHeight: '100vh', margin: '-16px' }}>
        <SubNav routes={mainRoutes} selectedKeys={[props.location.pathname]} />
        <Layout.Content style={{ margin: '16px' }}>
          <Route path="/statistics">
            <Switch>
              {mainRoutes.map(route => (<Route key={route.name} {...route} />))}
            </Switch>
          </Route>
        </Layout.Content>
      </Layout>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

const mapDispatchToProps = dispatch => ({
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Statistics);
