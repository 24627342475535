import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Row, Col, Input, Select, Button } from 'antd';
import { makeSelectUserList } from '@/global.selectors';
import { makeSelectFilter } from '../order.selectors';
import { onChangeFilterAction, getOrderAction } from '../order.actions';
import { ORDER_STATUS } from '../order.constants';

const { Option } = Select;

class Filter extends PureComponent {
  handleChangeInput = e => {
    const { value, dataset } = e.target;
    const { field } = dataset;
    const { onChangeFilter } = this.props;
    const data = {
      fieldName: field,
      fieldValue: value,
    };
    onChangeFilter(data);
  };

  handleChangeStatus = value => {
    const data = {
      fieldName: 'status',
      fieldValue: value,
    };
    this.props.onChangeFilter(data);
    this.props.getOrders();
  };
  handleChangeUser = value => {
    const data = {
      fieldName: 'createdBy',
      fieldValue: value,
    };
    this.props.onChangeFilter(data);
    this.props.getOrders();
  };

  handleEnter = () => {
    this.props.getOrders();
  };

  handleSearch = () => {
    this.props.getOrders();
  };

  handleReset = () => { };

  render() {
    const { filter, userList } = this.props;
    return (
      <Row gutter={8} style={{ marginTop: 10 }}>
        <Col sm={4}>
          <Select value={filter.status} defaultValue="" onChange={this.handleChangeStatus} style={{ width: '100%' }}>
            {ORDER_STATUS.map(item => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Col>
        <Col sm={4}>
          <Select value={filter.createdBy} defaultValue={0} onChange={this.handleChangeUser} style={{ width: '100%' }}>
            <Option value={0}>
              Người tạo
            </Option>
            {userList.map(item => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col sm={6}>
          <Input
            data-field="note"
            placeholder="Ghi chú"
            value={filter.note}
            onChange={this.handleChangeInput}
            onPressEnter={this.handleEnter}
          />
        </Col>
        <Col sm={6}>
          <Button type="primary" onClick={this.handleSearch}>
            Search
          </Button>
          <Button onClick={this.handleReset}>Reset</Button>
        </Col>
      </Row>
    );
  }
}

Filter.propTypes = {
  onChangeFilter: PropTypes.func,
  getOrders: PropTypes.func,
  filter: PropTypes.objectOf(PropTypes.any),
  userList: PropTypes.arrayOf(PropTypes.any),
  // user: PropTypes.objectOf(PropTypes.any),
};

Filter.defaultProps = {
  onChangeFilter: () => { },
  getOrders: () => { },
  userList: [],
  // user: {},
};

const mapStateToProps = createStructuredSelector({
  filter: makeSelectFilter(),
  userList: makeSelectUserList(),
  // user: makeSelectUser(),
});

const mapDispatchToProps = dispatch => ({
  onChangeFilter: data => dispatch(onChangeFilterAction(data)),
  getOrders: () => dispatch(getOrderAction()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Filter);
