import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useMutation } from 'react-query';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import request from '@utils/request';
import { formatNumber, awaitWrapper } from '@utils/helper';
import { PLACE_ENUM } from '@utils/constants';
import { Row, Col, Spin, Button, Modal, Input, Checkbox } from 'antd';
import { Pie, Area, Column } from '@ant-design/plots';
import RelativeDate from '@common/RelativeDate';
import ProductList from '@common/ProductList';
import SumTable from '@common/SumTable';

import { SIZE_COLUMN } from '../constants';
import { get } from 'lodash';

const MODE = ['Pie', 'Area'];
const COLUMN = ['M', 'L', 'XL', 'XXL'];

const PRODUCE = [
  { value: 'salePercent', label: '% bán' },
  { value: 'shopAvailable', label: 'Tồn shop' },
  { value: 'yukiAvailable', label: 'Tồn xưởng', editable: true },
  { value: 'produceQuantity', label: 'Sản xuất' },
]

function Product(props) {
  const [data, setData] = useState([]);
  const [mode, setMode] = useState(MODE[0]);
  const [areaData, setAreaData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isByBranch, setIsByBranch] = useState(false);
  const [timeRange, setTimeRange] = useState({});
  const [productId, setProductId] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [useShopAvailable, setUseShopAvailable] = useState(true);
  const [productLoading, setProductLoading] = useState(false);
  const [produceInfo, setProduceInfo] = useState({});
  const [produceQuantity, setProduceQuantity] = useState(0);
  const { isLoading, mutate } = useMutation(({ timeRange, productId, byBranch = undefined }) => {
    const ids = productId && productId !== '0' ? `&ids=${productId}` : '';
    const { from, to, range } = timeRange;
    let type = 'size';
    if(byBranch === undefined && isByBranch || byBranch){
      type = 'sizeBranch';
    } 
    return request.get(`v1/statistics/products?type=${type}&from=${from}&to=${to}&timeRange=${range || 'day'}${ids}`);
  },
    {
      onSuccess: (response) => {
        initData(response.data);
      },
    })
  const initData = (resData) => {
    const tableData = [];
    const chartData = [];
    const areaData = [];
    let isBranch = false;
    resData.forEach(x => {
      // const { timeRange } = x;
      if(x.branchId) isBranch = true;
      const timeRange = x.timeRange || x.branchId;
      let size = x.size;
      if (size === 'X' || size === 'XX') {
        size += 'L';
      } else if (size === 'XLX') {
        size = 'XXL';
      }
      if (SIZE_COLUMN.includes(size)) {
        let qty = parseInt(x.qty, 0);

        //table data
        const tableInd = tableData.findIndex(t => t.timeRange === timeRange);
        if (tableInd < 0) {
          const tableItem = {
            timeRange: timeRange,
            total: qty,
          };
          SIZE_COLUMN.forEach(s => {
            tableItem[s] = 0
            areaData.push({
              timeRange,
              size: s,
              qty: 0
            })
          });
          tableItem[size] = qty;
          tableData.push(tableItem);
        } else {
          tableData[tableInd][size] = qty;
          tableData[tableInd].total += qty;
        }

        //pie data
        const chartInd = chartData.findIndex(c => c.size === size);
        if (chartInd < 0) {
          const chartItem = {
            size: size,
            total: qty
          }
          chartData.push(chartItem);
        } else {
          chartData[chartInd].total += qty;
        }
        //area data
        const areaInd = areaData.findIndex(a => a.timeRange === timeRange && a.size === size);
        if (areaInd > -1) {
          areaData[areaInd].qty = qty;
        }
      }
    });
    if(isBranch){
      for(let i=0; i<tableData.length; i++){
        tableData[i].timeRange = PLACE_ENUM[tableData[i].timeRange];
      }
    }
    setTableData(tableData);
    setData(chartData);
    setAreaData(areaData);
  }
  const onChangeDate = (type, timeRange) => {
    setData([]);
    setTableData([]);
    setTimeRange(timeRange);
    mutate({ type, timeRange, productId });
  }
  const handleChangeProduct = value => {
    setProductId(value);
    mutate({ timeRange, productId: value });
  }
  const getProduceInfo = (value, size) => {
    if(value !==  'shopAvailable' || useShopAvailable){
      return get(produceInfo, `${value}.${size}`);
    }
    return '0';
  }
  const handleChangeQuantity = e => {
    const [produce, size] = e.target.id.split('-');
    const pInfo = {
      ...produceInfo
    }
    pInfo[produce][size] = e.target.value;
    pInfo.produceQuantity = calculateProduceQuantitySize(pInfo, produceQuantity);
    setProduceInfo(pInfo);
  }
  const handleChangeProduceQuantity = (e) => {
    setProduceQuantity(e.target.value);
    const produceQuantity = calculateProduceQuantitySize(produceInfo, e.target.value);
    const pInfo = {
      ...produceInfo,
      produceQuantity
    }
    setProduceInfo(pInfo);
  }
  const handleChangeUseShopAvailable = (e) => {
    setUseShopAvailable(e.target.checked);
    const pQuantity = calculateProduceQuantitySize(produceInfo, produceQuantity, e.target.checked);
    const pInfo = {
      ...produceInfo,
      produceQuantity: pQuantity
    }
    setProduceInfo(pInfo);
  }
  const calculateProduceQuantitySize = (pInfo, pQty, useSA) => {
    let totalAvailable = 0;
    let totalSalePercent = 0;
    const useSAQ = useSA !== undefined ? useSA : useShopAvailable;
    const availableQty = {};
    COLUMN.forEach(size => {
      let shopQty = 0;
      if(useSAQ){
        shopQty = parseInt(pInfo.shopAvailable[size], 0) || 0;
      }
      const yukiQty = parseInt(pInfo.yukiAvailable[size], 0) || 0;
      availableQty[size] = shopQty + yukiQty;
      totalAvailable += shopQty + yukiQty;
      totalSalePercent += pInfo.salePercent[size];
    })
    const produceQty = parseInt(pQty, 0) || 0;
    totalAvailable += produceQty;
    const produceQuantity = {};
    COLUMN.forEach(size => {
      produceQuantity[size] = Math.round(pInfo.salePercent[size] * totalAvailable / totalSalePercent) - availableQty[size];
    });
    //reinit
    const newColumns = [];
    totalAvailable = 0;
    totalSalePercent = 0;
    COLUMN.forEach(size => {
      if (produceQuantity[size] > 0) {
        totalAvailable += availableQty[size];
        totalSalePercent += pInfo.salePercent[size];
        newColumns.push(size);
      }
    })
    totalAvailable += produceQty;
    if (newColumns.length < COLUMN.length) {
      COLUMN.forEach(size => {
        if (!newColumns.includes(size)) {
          produceQuantity[size] = 0;
        } else {
          produceQuantity[size] = Math.round(pInfo.salePercent[size] * totalAvailable / totalSalePercent) - availableQty[size];
        }
      });
    }
    return produceQuantity;
  }
  const handleOpenModal = async () => {
    setShowModal(true);
    setProductLoading(true);
    setProduceQuantity(160);
    const info = {};
    PRODUCE.forEach(p => {
      const item = {};
      COLUMN.forEach(col => item[col] = 0);
      info[p.value] = item;
    })

    let productList = [];
    const [pErr, pRes] = await awaitWrapper(request.get('v1/product-meta/quantities'));
    if (!pErr) {
      productList = pRes.data.filter(x => x.groupId === productId);
    }

    //% Sale
    const filteredData = data.filter(x => COLUMN.includes(x.size));
    const total = filteredData.reduce((total, cur) => cur.total + total, 0);
    filteredData.forEach(x => {
      info.salePercent[x.size] = Math.round(x.total / total * 100);
      const product = productList.filter(p => p.size === x.size).reduce((pTotal, pCur) => pTotal + pCur.available, 0) || 0;
      if (product) info.shopAvailable[x.size] = product;
    })
    const produceQuantity = calculateProduceQuantitySize(info, 160);
    info.produceQuantity = produceQuantity;
    setProduceInfo(info);
    setProductLoading(false);
  }
  const selectByBranch = e => {
    setIsByBranch(e.target.checked);
    mutate({timeRange, productId,  byBranch: e.target.checked});
  }
  const config = {
    appendPadding: 10,
    data,
    angleField: 'total',
    colorField: 'size',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ size, percent }) => `${size}: ${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };
  const areaConfig = {
    data: areaData,
    isStack: true,
    xField: 'timeRange',
    yField: 'qty',
    seriesField: 'size',
    yAxis: {
      label: {
        formatter: formatNumber,
      }
    },
    xAxis: {
      label: {
        formatter: formatNumber,
      }
    },

    label: {
      formatter: obj => {
        return formatNumber(obj.qty);
      },
    },
  };
  const columns = [
    {
      title: isByBranch ? 'Cửa hàng': 'Ngày',
      dataIndex: 'timeRange',
    },
    {
      title: 'Tổng',
      dataIndex: 'total',
      align: 'right',
      render: formatNumber,
      sorter: (a, b) => a.total - b.total,
    }
  ]
  SIZE_COLUMN.forEach(s => {
    columns.push({
      title: s,
      dataIndex: s,
      align: 'right',
      render: formatNumber,
      sorter: (a, b) => a[s] - b[s],
    })
  })
  const numberColumns = ['total', ...SIZE_COLUMN];
  const renderChart = () => {
    if (mode === MODE[0]) return <Pie {...config} />;
    if (tableData.length === 1) return <Column {...areaConfig} />
    return <Area {...areaConfig} />
  }
  const columnWidth = 20;
  return (<>
    <Row gutter={16}>
      <Col sm={14}>
        <h2>Sản phẩm đã bán theo size</h2>
      </Col>
      <Col sm={10}>
        <RelativeDate onChange={onChangeDate} />
      </Col>
    </Row>
    <Row gutter={8} style={{ marginTop: '2rem' }}>
      <Col sm={16}>
        <ProductList onChange={handleChangeProduct} />
      </Col>
      <Col sm={4} align="right">
        <Checkbox checked={isByBranch} onChange={selectByBranch}>Thống kê theo shop</Checkbox>
      </Col>
      <Col sm={4} align="right">
        <Button.Group style={{ width: '100%' }} size="large">
          <Button onClick={handleOpenModal} type="dashed">SX</Button>
          {MODE.map(m => <Button key={m} type={mode === m ? 'primary' : 'default'} onClick={() => setMode(m)}>{m}</Button>)}
        </Button.Group>
      </Col>
    </Row>
    <Row gutter={16} style={{ marginTop: '2rem' }}>
      <Col sm={24}>
        <Spin spinning={isLoading}>
          {renderChart()}
        </Spin>
      </Col>
    </Row>
    <Row gutter={16} style={{ marginTop: '2rem' }}>
      <Col sm={24}>
        <SumTable
          numberColumns={numberColumns}
          pagination={false}
          dataSource={tableData}
          columns={columns}
          rowKey="timeRange" />
      </Col>
    </Row>
    <Modal visible={showModal} onCancel={() => setShowModal(false)} title="Sản xuất" footer={null}>
      <Spin spinning={productLoading}>
        <Row>
          <Col sm={8}>
            Số lượng sản xuất:
          </Col>
          <Col sm={16}>
            <Input value={produceQuantity} onChange={handleChangeProduceQuantity} />
          </Col>
        </Row>
        <Row>
          <Col sm={24}>
            <Checkbox checked={useShopAvailable} onChange={handleChangeUseShopAvailable}>Tính tồn shop</Checkbox>
          </Col>
        </Row>
        <Row style={{ marginTop: 16 }}>
          <Col sm={24}>
            <Input.Group compact className='tableStyle'>
              <Input value="" disabled style={{ width: `${columnWidth}%` }} />
              {COLUMN.map(col => <Input key={col} value={col} disabled style={{ width: `${columnWidth}%` }} />)}
            </Input.Group>
            {PRODUCE.map(produce => (
              <Input.Group key={produce.value} compact className='tableStyle'>
                <Input value={produce.label} disabled style={{ width: `${columnWidth}%` }} />
                {COLUMN.map(size => <Input key={`${produce.value}-${size}`} onChange={handleChangeQuantity} id={`${produce.value}-${size}`} value={getProduceInfo(produce.value, size)} disabled={!produce.editable} style={{ width: `${columnWidth}%` }} />)}
              </Input.Group>
            ))}
          </Col>
        </Row>
      </Spin>
    </Modal>
  </>)
}

Product.propTypes = {
};

Product.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
});

const mapDispatchToProps = dispatch => ({
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Product);
