import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { Layout } from 'antd';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import SubNav from '@common/SubNav';
import routes from './routes';



export const key = 'user';

function User(props) {

  let title = 'Tài khoản';
  const selectedRoute = routes.find(x => x.path === props.location.pathname);
  if (selectedRoute) {
    title = selectedRoute.name;
  }
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Layout style={{ minHeight: '100vh', margin: '-16px' }}>
        <SubNav routes={routes} selectedKeys={[props.location.pathname]} />
        <Layout.Content style={{ margin: '16px' }}>
          <Route path="/users">
            <Switch>
              {routes.map(route => (<Route key={route.name} {...route} />))}
            </Switch>
          </Route>
        </Layout.Content>
      </Layout>
    </>
  );
}

User.propTypes = {
};

const mapStateToProps = createStructuredSelector({
});

const mapDispatchToProps = dispatch => ({
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(User);
