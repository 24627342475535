import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { makeSelectUser } from '@/global.selectors';
import { Icon, Switch, Tag, Button } from 'antd';
import { orderType } from '@types';
import { formatShortDateTime } from '@utils/helper';
import { BASE_URL } from '@utils/constants';
import UserList from '@common/UserList';
import UserAvatar from './userAvatar';
import './style.less';
import LogInfo from './logInfo';
import { ORDER_STATUS, ORDER_STATUS_ENUM } from '../order.constants';

const getOrderStatus = stat => {
  const item = ORDER_STATUS.find(x => x.value === stat);
  if (!item) return stat;
  return item.label;
};

const getOrderStatusColor = stat => {
  const item = ORDER_STATUS.find(x => x.value === stat);
  if (!item) return '#ccc';
  return item.color;
};

const getImageUrl = image => {
  if (!image) {
    return '';
  }
  if (image.indexOf('http') > -1) {
    return image;
  }
  return `${BASE_URL}${image}`;
}

const OrderInfo = ({ order, onChangeType, disabled, onDelete, onCancel, user }) => {
  const renderActionButton = () => {
    if (user.role === 1) {
      if (order.status === ORDER_STATUS_ENUM.PENDING || order.status === ORDER_STATUS_ENUM.WAITING_DELETE) {
        return <Button onClick={() => onCancel(order.id)} className="orderCancel" type="danger">Xóa</Button>
      } else if (order.status === ORDER_STATUS_ENUM.DELETE) {
        return <Button onClick={() => onDelete({ id: order.id })} className="orderCancel" type="danger">Delete</Button>
      }

    } else if (order.status === ORDER_STATUS_ENUM.PENDING && !order.orderCode) {
      return <Button onClick={() => onCancel(order.id, ORDER_STATUS_ENUM.WAITING_DELETE)} className="orderCancel" type="danger">Yêu cầu Xóa</Button>
    }
    return '';
  }
  const renderRestoreButton = () => {
    if (order.status === ORDER_STATUS_ENUM.WAITING_DELETE || (order.status === ORDER_STATUS_ENUM.DELIVERY && !order.orderCode)) {
      return <Button onClick={() => onCancel(order.id, ORDER_STATUS_ENUM.PENDING)} className="orderRestore" type="dashed">Chờ xử lí</Button>
    }
    return '';
  }

  return (
    <div className="orderItemContainer" style={{ backgroundImage: `url(${getImageUrl(order.image)})` }}>
      <div className="orderId">DH{`00000${order.id}`.slice(-5)}</div>
      {order.loading && <Icon className="orderItemStatus" type="redo" spin />}
      {order.error && <Icon className="orderItemStatus" type="info-circle" />}
      {user.role > 1 ?
        <UserAvatar userId={order.createdBy} className="orderCreated" /> :
        <UserList mode="avatar" userId={order.createdBy} className="orderCreated" />}
      <div className="orderTime">{formatShortDateTime(order.createdAt * 1000)}</div>
      <Switch
        className="orderType isUrgent"
        checkedChildren="Gấp"
        unCheckedChildren="Gấp"
        checked={order.isUrgent}
        onChange={checked => onChangeType('isUrgent', checked)}
        disabled={disabled}
      />
      <Switch
        className="orderType isChange"
        checkedChildren="Đơn đổi"
        unCheckedChildren="Đơn đổi"
        checked={order.isChange}
        onChange={checked => onChangeType('isChange', checked)}
        disabled={disabled}
      />
      <Tag className={`orderStatus status-${order.status}`} color={getOrderStatusColor(order.status)}>
        {getOrderStatus(order.status)}
      </Tag>
      {renderRestoreButton()}
      {renderActionButton()}
      <LogInfo logs={order.logs} />
    </div>
  )
};

OrderInfo.propTypes = {
  order: orderType,
  disabled: PropTypes.bool,
  user: PropTypes.objectOf(PropTypes.any),
};

OrderInfo.defaultProps = {
  onChangeType: () => { },
  onDelete: () => { },
  onCancel: () => { },
  disabled: false,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, memo)(OrderInfo);