export const GET_ORDER_REQUEST = 'processOrders/GET_ORDER_REQUEST';
export const GET_ORDER_SUCCESS = 'processOrders/GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'processOrders/GET_ORDER_FAILURE';

export const POST_ORDER_REQUEST = 'processOrders/POST_ORDER_REQUEST';
export const POST_ORDER_SUCCESS = 'processOrders/POST_ORDER_SUCCESS';
export const POST_ORDER_FAILURE = 'processOrders/POST_ORDER_FAILURE';

export const PUT_ORDER_REQUEST = 'processOrders/PUT_ORDER_REQUEST';
export const PUT_ORDER_SUCCESS = 'processOrders/PUT_ORDER_SUCCESS';
export const PUT_ORDER_FAILURE = 'processOrders/PUT_ORDER_FAILURE';

export const DELETE_ORDER_REQUEST = 'processOrders/DELETE_ORDER_REQUEST';
export const DELETE_ORDER_SUCCESS = 'processOrders/DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILURE = 'processOrders/DELETE_ORDER_FAILURE';

export const HANDLE_MODAL_SHOW = 'processOrders/HANDLE_MODAL_SHOW';
export const HANDLE_MODAL_CANCEL = 'processOrders/HANDLE_MODAL_CANCEL';
export const HANDLE_MODAL_SELECT = 'processOrders/HANDLE_MODAL_SELECT';

export const ON_CHANGE_FIELD = 'processOrders/ON_CHANGE_FIELD';
export const ON_CHANGE_FILTER = 'processOrders/ON_CHANGE_FILTER';

export const ON_CHANGE_ADD_PHOTO = 'processOrders/ON_CHANGE_ADD_PHOTO';
export const ON_CHANGE_DEL_PHOTO = 'processOrders/ON_CHANGE_DEL_PHOTO';

export const GET_ORDER_ITEM_REQUEST = 'processOrders/GET_ORDER_ITEM_REQUEST';
export const GET_ORDER_ITEM_SUCCESS = 'processOrders/GET_ORDER_ITEM_SUCCESS';
export const GET_ORDER_ITEM_FAILURE = 'processOrders/GET_ORDER_ITEM_FAILURE';

export const POST_ORDER_ITEM_REQUEST = 'processOrders/POST_ORDER_ITEM_REQUEST';
export const POST_ORDER_ITEM_SUCCESS = 'processOrders/POST_ORDER_ITEM_SUCCESS';
export const POST_ORDER_ITEM_FAILURE = 'processOrders/POST_ORDER_ITEM_FAILURE';

export const ORDER_STATUS_ENUM = {
  ALL: 0,
  PENDING: 1,
  REVIEW: 2,
  COMPLETE: 3,
  CANCEL: 4,
};

export const ORDER_STATUS = [
  { value: ORDER_STATUS_ENUM.ALL, label: 'Trạng thái', color: '#000' },
  { value: ORDER_STATUS_ENUM.PENDING, label: 'Đang xử lí', color: '#f50' },
  { value: ORDER_STATUS_ENUM.REVIEW, label: 'Chờ duyệt', color: '#ff0' },
  { value: ORDER_STATUS_ENUM.COMPLETE, label: 'Hoàn thành', color: '#108ee9' },
  { value: ORDER_STATUS_ENUM.CANCEL, label: 'Đã hủy', color: '#ccc' },
];

export const DISABLE_ORDER_STATUS = [
  ORDER_STATUS_ENUM.COMPLETE,
  ORDER_STATUS_ENUM.CANCEL,
]

export const FILTER_DATE_ENUM = {
  ALL: 'all',
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  THISMONTH: 'this_month',
  LASTMONTH: 'last_month',
  CUSTOM: 'custom',
};

export const FILTER_DATE = [
  { value: FILTER_DATE_ENUM.ALL, label: 'Tất cả' },
  { value: FILTER_DATE_ENUM.TODAY, label: 'Hôm nay' },
  { value: FILTER_DATE_ENUM.YESTERDAY, label: 'Hôm qua' },
  { value: FILTER_DATE_ENUM.THISMONTH, label: 'Tháng này' },
  { value: FILTER_DATE_ENUM.LASTMONTH, label: 'Tháng trước' },
  { value: FILTER_DATE_ENUM.CUSTOM, label: 'Tùy chọn' },
];
