import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, Card, Input, Switch, Button } from 'antd';
import request from '@utils/request';
import { validateFields, awaitWrapper, pad, removeAccent } from '@utils/helper';
import UserInfo from '@common/UserInfo';
import BankQR from '@common/BankQR';
import './style.less';

class PayloadDetail extends PureComponent {
  constructor() {
    super();
    this.state = {
      loading: false,
      showQR: false,
    }
  }
  handleSubmit = async (e) => {
    e.preventDefault();
    const [err, values] = await validateFields(this.props.form);
    if (!err) {
      this.setState({ loading: true });
      const { item, year, month } = this.props;
      const payloadData = { userId: item.userId, year, month };
      Object.keys(values).forEach(k => {
        let v = values[k];
        if (v && k !== 'note' && k !== 'isDone' && typeof v === 'string') {
          v = v.replace(/\./g, '');
        }
        payloadData[k] = v;
      })

      let err;
      if (item.id) {
        [err] = await awaitWrapper(request.put(`v1/payloads/${item.id}`, payloadData));
      } else {
        [err] = await awaitWrapper(request.post(`v1/payloads/`, payloadData));
      }
      if (!err) {
        this.props.onChange();
      }
      this.setState({ loading: false });
    }
  }
  handleBankComplete = async () => {
    this.setState({ loading: true });
    const { item } = this.props;
    const payloadData = {
      isDone: true,
    }
    const [err] = await awaitWrapper(request.put(`v1/payloads/${item.id}`, payloadData));
    if (!err) {
      this.props.onChange();
    }
    this.setState({ loading: false });
  }
  getName = name => {
    const names = removeAccent(name).split(' ');
    let result = names[names.length - 1];
    for (let i = 0; i < names.length - 1; i++) {
      result += names[i][0].toUpperCase();
    }
    return result;
  }
  render() {
    const { item, form, year, month } = this.props;
    const { getFieldDecorator } = form;
    const { loading } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    let total = 0;
    let bankQRProps = {
      visible: false
    }
    if (item.id) {
      const { basicSalary, bonus, preSalary, forfeit, bankName, bankAccount, name } = item;
      total = basicSalary + bonus - preSalary - forfeit;
      if (!item.isDone) {
        bankQRProps = {
          visible: this.state.showQR,
          bankName, bankAccount, name,
          reason: `OTina%20${year}${pad(month)}`,
          amount: total,
        }
      }
    }

    return (
      <>
        <Card
          hoverable
          title={<>
            <UserInfo userId={item.userId} mode="name" />{total.toLocaleString()}
          </>}
          extra={<Button icon="qrcode" onClick={() => this.setState({ showQR: true })} />}
          disabled={loading || item.isDone}
          actions={[
            <Button disabled={loading || item.isDone} loading={loading} icon="save" onClick={this.handleSubmit}>{item.id ? 'Update' : 'Create'}</Button>,
          ]}
          style={{ marginTop: 16 }}
        >
          <Form {...formItemLayout} className="payloadForm" disabled={item.isDone}>
            <Form.Item label="Ngày công">
              {getFieldDecorator('day', { initialValue: item.day })(
                <Input
                  placeholder="Ngày công"
                />
              )}
            </Form.Item>
            <Form.Item label="Giờ công">
              {getFieldDecorator('hour', { initialValue: item.hour })(
                <Input
                  placeholder="Giờ công"
                />
              )}
            </Form.Item>
            <Form.Item label="Lương căn bản">
              {getFieldDecorator('basicSalary', { initialValue: item.basicSalary })(
                <Input
                  placeholder="Lương căn bản"
                />
              )}
            </Form.Item>
            <Form.Item label="Doanh số">
              {getFieldDecorator('sales', { initialValue: item.sales })(
                <Input
                  placeholder="Doanh số"
                />
              )}
            </Form.Item>
            <Form.Item label="Thưởng">
              {getFieldDecorator('bonus', { initialValue: item.bonus })(
                <Input
                  placeholder="Thưởng"
                />
              )}
            </Form.Item>
            <Form.Item label="Tạm ứng">
              {getFieldDecorator('preSalary', { initialValue: item.preSalary })(
                <Input
                  placeholder="Tạm ứng"
                />
              )}
            </Form.Item>
            <Form.Item label="Phạt">
              {getFieldDecorator('forfeit', { initialValue: item.forfeit })(
                <Input
                  placeholder="Phạt"
                />
              )}
            </Form.Item>
            <Form.Item label="Ghi chú">
              {getFieldDecorator('note', { initialValue: item.note })(
                <Input.TextArea
                  placeholder="Ghi chú"
                />
              )}
            </Form.Item>
            <Form.Item label="Đã thanh toán">
              {getFieldDecorator('isDone', { initialValue: item.isDone, valuePropName: 'checked' })(
                <Switch />
              )}
            </Form.Item>
          </Form>
        </Card>
        <BankQR {...bankQRProps} onOk={this.handleBankComplete} onCancel={() => this.setState({ showQR: false })} />
      </>
    );
  }
}

PayloadDetail.propTypes = {
  year: PropTypes.number,
  month: PropTypes.number,
};

PayloadDetail.defaultProps = {
  year: 0,
  month: 0,
};


const mapDispatchToProps = dispatch => ({
});

const withConnect = connect(null, mapDispatchToProps);

const PayloadDetailWrapper = Form.create()(PayloadDetail)

export default compose(withConnect, memo)(PayloadDetailWrapper);