import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { useInjectSaga } from '@utils/injectSaga';
import { useInjectReducer } from '@utils/injectReducer';
import { getOrderTrackingAction } from './orderTracking.actions';
import reducer from './orderTracking.reducer';
import saga from './orderTracking.saga';

import List from './List';
import Detail from './Detail';

export const key = 'orderTracking';

function OrderTracking(props) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    props.getOrder();
  });

  return (
    <>
      <Helmet>
        <title>Theo dõi đơn</title>
      </Helmet>
      <List />
      <Detail />
    </>
  );
}

OrderTracking.propTypes = {
  getOrder: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = dispatch => ({
  getOrder: () => dispatch(getOrderTrackingAction()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(OrderTracking);
