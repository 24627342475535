import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { makeSelectUserList } from '@/global.selectors';
import { Row, Col, Input, Table, Button } from 'antd';
import BankQR from '@common/BankQR';

function Bonus(props) {
  const [amount, setAmount] = useState('');
  const [reason, setReason] = useState('');
  const [selectedUser, setSelectedUser] = useState({});
  const columns = [
    {
      title: 'Tên',
      dataIndex: 'name'
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (_, rec) => <Button icon="edit" onClick={() => setSelectedUser(rec)} />
    },
  ]
  const dataSource = props.userList.filter(x => x.active);
  let bankQRProps = {
    visible: false
  }
  if (selectedUser.id) {
    const { bankName, bankAccount, name } = selectedUser;
    bankQRProps = {
      visible: true,
      bankName, bankAccount, name,
      reason: `${reason}`,
      amount,
    }
  }
  return (<>
    <Row gutter={16}>
      <Col sm={24}>
        <h2>Thưởng nhân viên</h2>
      </Col>
    </Row>
    <Row gutter={16} style={{ margin: '12px 0' }}>
      <Col sm={8}>
        <Input value={amount} placeholder="Số tiền" onChange={e => setAmount(e.target.value)} />
      </Col>
      <Col sm={8}>
        <Input value={reason} placeholder="Lý do" onChange={e => setReason(e.target.value)} />
      </Col>
    </Row>
    <Row gutter={16}>
      <Table columns={columns} dataSource={dataSource} />
    </Row>
    <BankQR {...bankQRProps} onCancel={() => setSelectedUser({})} />
  </>)
}

Bonus.propTypes = {
};

Bonus.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  userList: makeSelectUserList()
});

const mapDispatchToProps = dispatch => ({

});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Bonus);
