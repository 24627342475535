
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, Row, Col } from 'antd';
import { getStartUnix, getEndUnix } from '@utils/helper';

const ALL_YEAR = 'all';
const START_YEAR = 2020;
const END_YEAR = new Date().getFullYear();

export const FILTER_DATE = [
  { value: ALL_YEAR, label: 'Tối đa' },
];
for(let i = END_YEAR; i >= START_YEAR; i--){
  FILTER_DATE.push({value: `${i}`, label: `${i}`});
}

export const getDate = type => {
  let from = 0, to = 0, range = 'month';
  const date = new Date();
  switch (type) {
    case ALL_YEAR: {
      const firstDay = new Date(2020, 2, 1);
      const endDay = new Date(date.getFullYear(), 11, 31);
      from = getStartUnix(firstDay);
      to = getEndUnix(endDay);
      range = 'year';
      break;
    }
    default: {
      const year = parseInt(type);
      let firstMonth = 1;
      let endMonth = 12;
      from = getStartUnix(new Date(year, firstMonth, 1));
      to = getEndUnix(new Date(year, endMonth, 31));
      range = 'month';
      break;
    }
  }
  return { from, to, range };
}

const { SubMenu } = Menu;


function YearList(props) {
  const [dateType, setDateType] = useState(`${END_YEAR}`);
  // const [timeRange, setTimeRange] = useState({});

  const handleChangeType = item => {
    setDateType(item.key);
    const newTimeRange = getDate(item.key);
    // setTimeRange(newTimeRange);
    props.onChange(item.key, newTimeRange);
  };
  useEffect(() => {
    
    handleChangeType({ key: props.type });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let title = 'Chọn năm';
  const filterDate = FILTER_DATE.find(x => x.value === dateType);
  if (filterDate) {
    title = filterDate.label;
  }
  return (
    <Row gutter={16}>
      <Col sm={24}>
        <Menu onClick={handleChangeType} selectedKeys={dateType}>
          <SubMenu key={'relSub'} title={title} popupClassName="yearListPicker">
            {FILTER_DATE.map(item => (
              <Menu.Item key={item.value}>{item.label}</Menu.Item>
            ))}
          </SubMenu>
        </Menu>
      </Col>
    </Row>
  );
}

YearList.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func
};

YearList.defaultProps = {
  type: `${END_YEAR}`,
  onChange: () => { },
};

export default YearList;
