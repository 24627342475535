import { createSelector } from 'reselect';
import { initialState } from './global.reducer';

const selectGlobal = state => state.global || initialState;
const selectRouter = state => state.router;

const makeSelectUser = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.user,
  );
const makeSelectUserList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.userList,
  );
const makeSelectUserListLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.userListLoading,
  );

const makeSelectProductList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.productList,
  );
const makeSelectProductListLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.productListLoading,
  );
  const makeSelectProductMetaList = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.productMetaList,
  );

export { selectGlobal, selectRouter, makeSelectUser, makeSelectUserList, makeSelectUserListLoading, makeSelectProductList, makeSelectProductListLoading, makeSelectProductMetaList };
