import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SAPO_LINK = 'https://otina.mysapogo.com/admin/orders/[orderId]';

class SapoLink extends PureComponent {
  render() {
    const { orderId, orderCode } = this.props;
    const link = SAPO_LINK.replace('[orderId]', orderId);
    return <Link to={{ pathname: link }} target="_blank">{orderCode || orderId}</Link>;
  }
}

SapoLink.propTypes = {
  orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  orderCode: PropTypes.string,
};

SapoLink.defaultProps = {
  orderCode: '',
};

export default SapoLink;
