import request from '@utils/request';
import { getLocation } from 'utils/helper';
export async function getPlaces() {
  const data = {};
  return request.get('v1/admin/places', { params: data });
}

export function getOnline() {
  return request.get('v1/admin/online');
}

export function getCheckInHistory() {
  return request.get('v1/admin/check-in-history');
}

export async function checkIn(placeId, placeType, type = 1, longitude = 0, latitude = 0) {
  const data = {
    latitude,
    longitude,
    type,
    placeId,
  }
  if (navigator.geolocation) {
    const position = await getLocation(navigator);
    data.latitude = position.latitude;
    data.longitude = position.longitude;
  }
  return request.post('v1/admin/check-in', data);
}