import produce from 'immer';
import { parseJson } from '@utils/helper';
import {
  GET_ORDER_TRACKING_SUCCESS,
  GET_ORDER_TRACKING_REQUEST,
  GET_ORDER_TRACKING_FAILURE,
  POST_ORDER_META_SUCCESS,
  POST_ORDER_META_REQUEST,
  POST_ORDER_META_FAILURE,
  ON_SELECT_ORDER
} from './orderTracking.constants';

export const initialState = {
  list: [],
  loading: false,
  postLoading: false,
  selectedOrder: {}
};

const initLog = (logStr = '') => {
  const log = parseJson(logStr, []);
  return log.sort((a, b) => b.createdAt - a.createdAt);
}

const initData = list => {
  return list.map(x => ({
    ...x,
    log: initLog(x.log)
  }))
}

/* eslint-disable default-case, no-param-reassign */
const orderTrackingReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_ORDER_TRACKING_REQUEST: {
        draft.loading = true;
        break;
      }
      case GET_ORDER_TRACKING_SUCCESS: {
        const data = action.payload;
        draft.list = initData(data);
        draft.loading = false;
        break;
      }
      case GET_ORDER_TRACKING_FAILURE: {
        draft.list = [];
        draft.loading = false;
        break;
      }
      case POST_ORDER_META_REQUEST: {
        draft.postLoading = true;
        break;
      }
      case POST_ORDER_META_SUCCESS: {
        const data = action.payload;
        const ind = state.list.findIndex(x => x.orderCode === data.orderCode);
        if (ind > -1) {
          draft.list[ind] = {
            ...draft.list[ind],
            isChargeBack: data.isChargeBack,
            log: initLog(data.log)
          }
        }
        draft.postLoading = false;
        break;
      }
      case POST_ORDER_META_FAILURE: {
        draft.postLoading = false;
        break;
      }
      case ON_SELECT_ORDER: {
        draft.selectedOrder = action.payload;
        break;
      }
    }
  });

export default orderTrackingReducer;
