import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Row, Col, Table, } from 'antd';
import { formatTime, duration } from 'utils/helper';
import { makeSelectUserList, makeSelectUser } from '@/global.selectors';

import UserInfo from '@common/UserInfo';
import { makeSelectCheckInHistory, makeSelectPlaces } from '../dashboard.selectors';

class History extends PureComponent {
  renderTime = () => {
    const startYear = 2022;
    const startMonth = 2;
    const today = new Date();
    const cYear = today.getFullYear();
    const cMonth = today.getMonth() + 1;
    const list = [];
    for (let i = startYear; i <= cYear; i++) {
      for (let j = startMonth; j <= cMonth; j++) {
        list.push({
          label: `Tháng ${j}/${i}`,
          value: i * 100 + j
        })
      }
    }
    return '';
  }
  renderPlace = placeId => {
    const { places } = this.props;
    const place = places.find(x => x.id === placeId);
    return place ? place.name : 'Một nơi xa';
  }
  renderDuration = (_, row) => {
    let end = 0;
    if(row.end) end = row.end;
    else {
      end = new Date()/1000;
    }
    
    return duration(end - row.start);
  }
  render() {
    const { list, user } = this.props;
    const userList = this.props.userList.map(x => ({ text: x.name, value: x.id }));
    const columns = [
      {
        title: 'Ngày',
        dataIndex: 'createdDate',
      },
      {
        title: 'Địa chỉ',
        dataIndex: 'placeId',
        render: this.renderPlace,
      },
      {
        title: 'Vào',
        dataIndex: 'start',
        render: value => formatTime(value * 1000)
      },
      {
        title: 'Ra',
        dataIndex: 'end',
        render: value => formatTime(value * 1000)
      },
      {
        title: 'Thời gian',
        render: this.renderDuration
      },

    ]
    if (user.role === 1) {
      columns.unshift({
        title: 'Nhân viên',
        dataIndex: 'userId',
        render: userId => <UserInfo userId={userId} />,
        filters: userList,
        onFilter: (value, record) => record.userId === value,
      })
    }
    return (
      <Row gutter={16}>
        <Col sm={24}>
          <Table
            dataSource={list}
            columns={columns}
            rowKey="id"
          />
        </Col>
      </Row>
    );
  }
}

History.propTypes = {
  list: PropTypes.array,
  places: PropTypes.array,
  userList: PropTypes.array,
  user: PropTypes.object,
};

History.defaultProps = {
  list: [],
  places: [],
  userList: [],
  user: {}
};

const mapStateToProps = createStructuredSelector({
  list: makeSelectCheckInHistory(),
  places: makeSelectPlaces(),
  userList: makeSelectUserList(),
  user: makeSelectUser(),
});

const mapDispatchToProps = dispatch => ({});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(History);
