export const GET_ORDER_REQUEST = 'orders/GET_ORDER_REQUEST';
export const GET_ORDER_SUCCESS = 'orders/GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'orders/GET_ORDER_FAILURE';

export const POST_ORDER_REQUEST = 'orders/POST_ORDER_REQUEST';
export const POST_ORDER_SUCCESS = 'orders/POST_ORDER_SUCCESS';
export const POST_ORDER_FAILURE = 'orders/POST_ORDER_FAILURE';

export const PUT_ORDER_REQUEST = 'orders/PUT_ORDER_REQUEST';
export const PUT_ORDER_SUCCESS = 'orders/PUT_ORDER_SUCCESS';
export const PUT_ORDER_FAILURE = 'orders/PUT_ORDER_FAILURE';

export const DELETE_ORDER_REQUEST = 'orders/DELETE_ORDER_REQUEST';
export const DELETE_ORDER_SUCCESS = 'orders/DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILURE = 'orders/DELETE_ORDER_FAILURE';

export const HANDLE_MODAL_SHOW = 'orders/HANDLE_MODAL_SHOW';
export const HANDLE_MODAL_CANCEL = 'orders/HANDLE_MODAL_CANCEL';
export const HANDLE_MODAL_SELECT = 'orders/HANDLE_MODAL_SELECT';

export const ON_CHANGE_FIELD = 'orders/ON_CHANGE_FIELD';
export const ON_CHANGE_FILTER = 'orders/ON_CHANGE_FILTER';

export const ON_CHANGE_ADD_PHOTO = 'orders/ON_CHANGE_ADD_PHOTO';
export const ON_CHANGE_DEL_PHOTO = 'orders/ON_CHANGE_DEL_PHOTO';

export const UPDATE_STATUS_ORDER_REQUEST = 'orders/UPDATE_STATUS_ORDER_REQUEST';
export const UPDATE_STATUS_ORDER_SUCCESS = 'orders/UPDATE_STATUS_ORDER_SUCCESS';
export const UPDATE_STATUS_ORDER_FAILURE = 'orders/UPDATE_STATUS_ORDER_FAILURE';

export const POST_ORDER_LOG_REQUEST = 'orders/POST_ORDER_LOG_REQUEST';
export const POST_ORDER_LOG_SUCCESS = 'orders/POST_ORDER_LOG_SUCCESS';
export const POST_ORDER_LOG_FAILURE = 'orders/POST_ORDER_LOG_FAILURE';

export const ORDER_STATUS_ENUM = {
  ALL: 0,
  PENDING: 1,
  DELIVERY: 2,
  COMPLETE: 3,
  CANCEL: 4,
  DELETE: 5,
  WAITING_DELETE: 6
};

export const ORDER_STATUS = [
  { value: ORDER_STATUS_ENUM.ALL, label: 'Trạng thái', color: '#000' },
  { value: ORDER_STATUS_ENUM.PENDING, label: 'Chờ xử lí', color: '#f50' },
  { value: ORDER_STATUS_ENUM.DELIVERY, label: 'Đang xử lí', color: '#87d068' },
  { value: ORDER_STATUS_ENUM.COMPLETE, label: 'Hoàn thành', color: '#108ee9' },
  { value: ORDER_STATUS_ENUM.WAITING_DELETE, label: 'Chờ xóa', color: '#aaa' },
  { value: ORDER_STATUS_ENUM.CANCEL, label: 'Đã hủy', color: '#ccc' },
  { value: ORDER_STATUS_ENUM.DELETE, label: 'Đã xóa', color: '#aaa' },

];

export const DISABLE_ORDER_STATUS = [
  ORDER_STATUS_ENUM.COMPLETE,
  ORDER_STATUS_ENUM.CANCEL,
  ORDER_STATUS_ENUM.DELETE,
]

export const ORDER_TYPE_ENUM = {
  ALL: 0,
  NORMAL: 1,
  URGENT: 2,
  CHANGE: 3,
};

export const ORDER_TYPE = [
  { value: ORDER_TYPE_ENUM.ALL, label: 'Tất cả' },
  { value: ORDER_TYPE_ENUM.NORMAL, label: 'Bình Thường' },
  { value: ORDER_TYPE_ENUM.URGENT, label: 'Đơn Gấp' },
  { value: ORDER_TYPE_ENUM.CHANGE, label: 'Đơn Đổi' },
];

export const FILTER_DATE_ENUM = {
  ALL: 'all',
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  THISMONTH: 'this_month',
  LASTMONTH: 'last_month',
  CUSTOM: 'custom',
};

export const FILTER_DATE = [
  { value: FILTER_DATE_ENUM.ALL, label: 'Tất cả' },
  { value: FILTER_DATE_ENUM.TODAY, label: 'Hôm nay' },
  { value: FILTER_DATE_ENUM.YESTERDAY, label: 'Hôm qua' },
  { value: FILTER_DATE_ENUM.THISMONTH, label: 'Tháng này' },
  { value: FILTER_DATE_ENUM.LASTMONTH, label: 'Tháng trước' },
  { value: FILTER_DATE_ENUM.CUSTOM, label: 'Tùy chọn' },
];
