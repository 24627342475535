import request from '@utils/request';

export function getProductListApi() {
  return request.get('v1/product-meta/list');
}

export function getProductMetaListApi() {
  return request.get('v1/product-meta');
}

export function getUserListApi() {
  return request.get('v1/users');
}