import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Menu } from 'antd';

import { makeSelectFilter } from '../order.selectors';
import { onChangeFilterAction, getOrderAction } from '../order.actions';
import { FILTER_DATE } from '../order.constants';

const { SubMenu } = Menu;
const MenuItem = Menu.Item;

class FilterDate extends PureComponent {
  handleChangeFilterDate = item => {
    const { onChangeFilter, getOrders } = this.props;
    const data = {
      fieldName: 'date',
      fieldValue: `relative,${item.key}`,
    };
    onChangeFilter(data);
    getOrders();
  };

  render() {
    const { filter } = this.props;
    const { date } = filter;
    let title = 'Chọn ngày';
    const filterDate = FILTER_DATE.find(x => x.value === date.value);
    if (filterDate) {
      title = filterDate.label;
    }
    return (
      <Menu onClick={this.handleChangeFilterDate} selectedKeys={date.value}>
        <SubMenu title={title}>
          {FILTER_DATE.map(item => (
            <MenuItem key={item.value}>{item.label}</MenuItem>
          ))}
        </SubMenu>
      </Menu>
    );
  }
}

FilterDate.propTypes = {
  onChangeFilter: PropTypes.func,
  getOrders: PropTypes.func,
  filter: PropTypes.objectOf(PropTypes.any),
};

FilterDate.defaultProps = {
  onChangeFilter: () => { },
  getOrders: () => { },
};

const mapStateToProps = createStructuredSelector({
  filter: makeSelectFilter(),
});

const mapDispatchToProps = dispatch => ({
  onChangeFilter: data => dispatch(onChangeFilterAction(data)),
  getOrders: () => dispatch(getOrderAction()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(FilterDate);
