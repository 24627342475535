import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useMutation } from 'react-query';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import request from '@utils/request';
import querystring from 'query-string';
import findLastIndex from "lodash/findLastIndex"
import { formatNumber, formatDate, formatDateTime } from '@utils/helper';
import { PLACE, CHECKIN_PLACE, PLACE_ENUM } from 'utils/constants';
import { makeSelectUserList } from '@/global.selectors';
import UserInfo from '@common/UserInfo';
import { Row, Col, Button, Checkbox, Card } from 'antd';
import Table from '@common/Table';

const SUB_EMP = [9, 20];

function PosOrder(props) {
  const today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth();
  const day = today.getDate();
  if (day < 6) {
    month = month - 1;
    if (month < 0) {
      year -= 1;
      month = 11;
    }
  }
  if (window.location.search) {
    let params = querystring.parse(window.location.search);
    if (params.year) year = parseInt(params.year, 0);
    if (params.month) month = parseInt(params.month, 0) - 1;
  }

  const [data, setData] = useState([]);
  const [history, setHistory] = useState([]);
  const [unSelectedUser, setUnSelectedUser] = useState([]);
  // const [checkInHistory, setCheckInHistory] = useState([]);
  const [sumEmp, setSumEmp] = useState({});
  const { isLoading, mutate: getPosOrder } = useMutation(({ year, month }) => {
    const nextMonth = new Date(year, month + 1, 0);
    const lastDay = nextMonth.getDate();
    return request.get(`v1/statistics/pos-orders?from=${year}-${month + 1}-1&to=${year}-${month + 1}-${lastDay}`);
  },
    {
      onSuccess: (response) => {
        setData(response.data);
      },
    });
  const { isLoadingCheckIn, mutate: getCheckinHistory } = useMutation(({ year, month }) => {
    const newMonth = month < 12 ? month + 1 : 1;
    return request.get(`v1/admin/check-in-history?year=${year}&month=${newMonth}`);
  },
    {
      onSuccess: (response) => {
        // const ciHistory = initHistory(response.data);
        setHistory(response.data);
        // setCheckInHistory(ciHistory);
      },
    });
  const initHistory = data => {
    const result = [];
    const employeeList = props.userList.filter(x => x.dept === 2 && x.active === 1);
    data.forEach(ci => {
      if (employeeList.findIndex(x => x.id === ci.userId) > -1) {
        if (ci.type === 1) {
          result.push({
            userId: ci.userId,
            cIn: ci.createdAt,
            cOut: ci.createdAt,
            placeId: ci.placeId,
          })
        } else {
          const lastInd = findLastIndex(result, v => v.userId === ci.userId);
          if (lastInd > -1 && formatDate(ci.createdAt) === formatDate(result[lastInd].cIn)) {
            result[lastInd].cOut = ci.createdAt;
          }
        }
      }
    });
    return result;
  }
  const initData = () => {
    const ciHistory = initHistory(history);
    const sum = {};
    const employeeList = props.userList.filter(x => x.dept === 2 && x.active === 1 && !unSelectedUser.includes(x.id));
    employeeList.forEach(pe => {
      sum[`u${pe.id}`] = 0;
    })
    const tData = data.map(x => {
      const { orderCompletedAt, total, branchId } = x;
      let placeId = 0;
      switch (branchId) {
        case PLACE.SHOP48:
          placeId = CHECKIN_PLACE.SHOP48;
          break;
        case PLACE.SHOP222:
          placeId = CHECKIN_PLACE.SHOP222;
          break;
        default:
          placeId = CHECKIN_PLACE.SHOP257;
          break;
      }
      const result = { ...x };
      const ciArr = ciHistory.filter(c => c.placeId === placeId && c.cIn <= orderCompletedAt && c.cOut >= orderCompletedAt && !unSelectedUser.includes(c.userId) );

      employeeList.forEach(pe => {
        result[`u${pe.id}`] = 0;
      })
      if (ciArr.length) {
        let length = ciArr.length;
        SUB_EMP.forEach(id => {
          const ind = ciArr.findIndex(cia => cia.userId === id);
          if(ind > -1){
            length -= 0.5;
          }
        })
        const subTotal = Math.round(total / length);
        ciArr.forEach(ci => {
          if(SUB_EMP.includes(ci.userId)){
            let st = Math.round(subTotal/2);
            result[`u${ci.userId}`] = st;
            sum[`u${ci.userId}`] += st;  
          } else {
            result[`u${ci.userId}`] = subTotal;
            sum[`u${ci.userId}`] += subTotal;
          }
          
        })
      }
      return result;
    });
    setData(tData);
    setSumEmp(sum);
  }
  useEffect(() => {
    getPosOrder({ year, month });
    getCheckinHistory({ year, month });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const columns = [
    {
      title: 'Ngày',
      dataIndex: 'orderCompletedAt',
      render: text => formatDateTime(text * 1000)
    },
    {
      title: 'Shop',
      dataIndex: 'branchId',
      render: text => PLACE_ENUM[text]
    },
    {
      title: 'Mã ĐH',
      dataIndex: 'orderCode',
    },
    {
      title: 'Tổng tiền',
      dataIndex: 'total',
      align: 'right',
      render: formatNumber
    },
  ]
  props.userList.filter(x => x.dept === 2 && x.active === 1 && !unSelectedUser.includes(x.id)).forEach(e => {
    const pe = e.id;
    columns.push({
      title: (<><UserInfo userId={pe} mode="name" />{formatNumber(sumEmp[`u${pe}`])} </>),
      dataIndex: `u${pe}`,
      align: 'right',
      render: formatNumber
    })
  })
  const isSelected = (id) => {
    return !unSelectedUser.includes(id);
  }
  const selectUser = e => {
    const id = parseInt(e.target.value);
    const ind = unSelectedUser.indexOf(id);
    let newSel = [];
    if(ind < 0){
      newSel = [...unSelectedUser, id];
    } else {
      newSel = [...unSelectedUser.slice(0, ind), ...unSelectedUser.slice(ind + 1, unSelectedUser.length)];
    }
    setUnSelectedUser(newSel);
  }
  return (
    <>
      <Row gutter={16}>
        <Col sm={16}>
          <h2>Đơn POS tháng {month + 1}/{year}</h2>
        </Col>
        <Col sm={8} align="right">
          <Button onClick={initData}>Tính KPI</Button>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col sm={24}>
          <Card size="small" title="Danh sách nhân viên">
            {props.userList.filter(x => x.dept === 2 && x.active === 1).map(user => (
              <Checkbox key={user.id} checked={isSelected(user.id)} onChange={selectUser}  value={user.id}>{user.name}</Checkbox>
            ))}
          </Card>   
        </Col>
      </Row>
      <Row gutter={16} style={{marginTop: 10}}>
        <Col sm={24}>
          <Table
            loading={isLoading || isLoadingCheckIn}
            dataSource={data}
            columns={columns}
            rowKey="id"
          />
        </Col>
      </Row>
    </>
  );
}

PosOrder.propTypes = {
};

PosOrder.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  userList: makeSelectUserList()
});

const mapDispatchToProps = dispatch => ({

});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(PosOrder);
