import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import get from 'lodash/get';
import {isMoment} from 'moment';
import { Drawer, Form, Input, Button, Row, Col, AutoComplete, DatePicker, Select } from 'antd';
import { validateFields, getImageUrl } from '@utils/helper';
import { makeSelectUser } from '@/global.selectors';

import { selectProduct, selectPostLoading, selectMaterialList } from '../selectors';
import { postProductAction, onSelectProductAction } from '../actions';
import { SIZE_COLUMN, COLUMN, PRODUCE_STATUS_LIST } from '../constants';

const {Option} = Select;
class ProductDetail extends PureComponent {
  handleUpdate = async (e) => {
    e.preventDefault();
    const [err, values] = await validateFields(this.props.form);
    if (!err) {
      const metaInfo = {};
      const wooVariationIds = {};
      const { product } = this.props;
      const updateData = {
        productId: product.pId,
        sku: product.pSku.toUpperCase(),
        ...values,
        deliveryDate: 0,
      }
      if(values.deliveryDate !== null){
        updateData.deliveryDate = isMoment(values.deliveryDate) ? values.deliveryDate.unix() : 0;
      }
      SIZE_COLUMN.forEach(size => {
        metaInfo[size] = updateData[size];
        wooVariationIds[size] = updateData.wooVariation ? updateData.wooVariation[size] : '';
        delete updateData[size];
      })
      delete updateData.wooVariation;
      updateData.metaInfo = JSON.stringify(metaInfo);
      updateData.wooVariationIds = JSON.stringify(wooVariationIds);
      if (product.id) {
        updateData.id = product.id;
      }
      this.props.updateProduct(updateData);
    }
  }
  handleClose = () => {
    this.props.onSelectProduct();
  }
  handleChangeWoo = (e) => {
    const { setFieldsValue } = this.props.form;
    let wooProductId = e.target.value;
    if (wooProductId) {
      wooProductId = parseInt(wooProductId, 0);
      const updateField = {};
      SIZE_COLUMN.forEach(size => {
        wooProductId += 1;
        updateField[`wooVariation.${size}`] = wooProductId
      })
      setFieldsValue(updateField);
    }
  }
  render() {
    const { product, loading, user } = this.props;
    const { getFieldDecorator } = this.props.form;
    const isAdmin = user.role < 2;
    const columnWidth = Math.floor(100 / (COLUMN.length + 1));
    return (
      <Drawer
        title="Cập nhật sản phẩm"
        placement="right"
        onClose={this.handleClose}
        visible
        width="50%"
        className="updateProductDrawer"
      >
        <Form onSubmit={this.handleUpdate}>
          <Row gutter={16}>
            <Col sm={12}>
              <div className='imageContainer' style={{ backgroundImage: `url(${getImageUrl(product.image)})` }}>
              </div>
            </Col>
            <Col sm={12}>
              <Form.Item label="Chất liệu">
                {getFieldDecorator('material', {
                  initialValue: product.material || ''
                })(
                  <AutoComplete
                    dataSource={this.props.materialList}
                    placeholder="Chất liệu"
                  />
                )}
              </Form.Item>
              <Form.Item label="Chiều dài">
                {getFieldDecorator('length', {
                  initialValue: product.length || 0
                })(
                  <Input type="number" />
                )}
              </Form.Item>
              <Form.Item label="Chiều dài lót">
                {getFieldDecorator('underlayLength', {
                  initialValue: product.underlayLength || 0
                })(
                  <Input type="number" />
                )}
              </Form.Item>
            </Col>
          </Row>
          {isAdmin && <Row gutter={16}>
            <Col sm={12}>
              <Form.Item label="Woo Product ID">
                {getFieldDecorator('wooProductId', {
                  initialValue: product.wooProductId
                })(
                  <Input onBlur={this.handleChangeWoo} />
                )}
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item label="Woo Variation ID">
                <Input.Group compact>
                  {SIZE_COLUMN.map(size => {
                    return getFieldDecorator(`wooVariation.${size}`, {
                      initialValue: get(product, `wooVariationIds.${size}`, '')
                    })(
                      <Input placeholder={size} style={{ width: `25%` }} />
                    )
                  })}
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>}
          {isAdmin && <Row gutter={16}>
            <Col sm={12}>
              <Form.Item label="Ngày về hàng">
                {getFieldDecorator('deliveryDate', {
                  initialValue: product.deliveryDate || 0
                })(
                  <DatePicker />
                )}
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item label="Tình trạng">
                {getFieldDecorator('produceStatus', {
                  initialValue: product.produceStatus || 0
                })(
                  <Select>
                    {PRODUCE_STATUS_LIST.map(sta => <Option key={sta.value} value={sta.value}>{sta.label}</Option>)}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>}
          <Row gutter={16}>
            <Col sm={8}>
              <Form.Item label="Túi">
                {getFieldDecorator('pocket', {
                  initialValue: product.pocket || ''
                })(
                  <Input />
                )}
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item label="Xẻ">
                {getFieldDecorator('sideSlit', {
                  initialValue: product.sideSlit || ''
                })(
                  <Input />
                )}
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item label="Sau lưng">
                {getFieldDecorator('back', {
                  initialValue: product.back || ''
                })(
                  <Input />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Chi tiết">
            <Input.Group compact>
              <Input value="" disabled style={{ width: `${columnWidth}%` }} />
              {COLUMN.map(col => <Input value={col.label} disabled style={{ width: `${columnWidth}%` }} />)}
            </Input.Group>
            {SIZE_COLUMN.map(size => (
              <Input.Group compact>
                <Input value={size} disabled style={{ width: `${columnWidth}%` }} />
                {COLUMN.map(col => {
                  return getFieldDecorator(`${size}.${col.value}`, {
                    initialValue: get(product, `metaInfo.${size}.${col.value}`, '')
                  })(
                    <Input placeholder={col.label} style={{ width: `${columnWidth}%` }} />
                  )
                })}
              </Input.Group>
            ))}
          </Form.Item>
          <Form.Item>
            <Button disabled={loading} loading={loading} type="primary" htmlType="submit">Lưu</Button>
          </Form.Item>
        </Form>
      </Drawer >
    );
  }
}

ProductDetail.propTypes = {
  product: PropTypes.object,
};

ProductDetail.defaultProps = {
  product: {},
  materialList: [],
};

const mapStateToProps = createStructuredSelector({
  product: selectProduct(),
  loading: selectPostLoading(),
  user: makeSelectUser(),
  materialList: selectMaterialList(),
});

const mapDispatchToProps = dispatch => ({
  onSelectProduct: () => dispatch(onSelectProductAction({})),
  updateProduct: data => dispatch(postProductAction(data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const ProductDetailWrapper = Form.create()(ProductDetail)

export default compose(withConnect, memo)(ProductDetailWrapper);
