import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Upload, Icon, Button } from 'antd';

import { makeSelectPhoto, makeSelectPostLoading } from '../order.selectors';
import { postOrderAction, onChangeAddPhotoAction, onChangeDelPhotoAction } from '../order.actions';

import './style.less';

class OrderCreate extends PureComponent {
  beforeUpload = file => {
    return this.props.onChangeAddPhoto(file);
  }
  render() {
    const { showCreate, onChangeDelPhoto, photo, loading } = this.props;
    if (!showCreate) {
      return <Button>Thêm</Button>;
    }
    const uploadButton = (
      <div>
        <Icon spin={loading} type={loading ? "redo" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <Upload
        className="orderUpload"
        onRemove={onChangeDelPhoto}
        beforeUpload={this.beforeUpload}
        fileList={photo}
        accept="image/*,.heic"
        disabled={loading}
        showUploadList={true}
        multiple={true}
      // transformFile={this.transformFile}
      >
        {uploadButton}
      </Upload>
    );
  }
}

OrderCreate.propTypes = {
  showCreate: PropTypes.bool,
  onChangeDelPhoto: PropTypes.func,
  onChangeAddPhoto: PropTypes.func,
  photo: PropTypes.array,
  loading: PropTypes.bool,
};

OrderCreate.defaultProps = {
  showCreate: true,
};

const mapStateToProps = createStructuredSelector({
  photo: makeSelectPhoto(),
  loading: makeSelectPostLoading(),
});

const mapDispatchToProps = dispatch => ({
  onChangeAddPhoto: file => {
    dispatch(onChangeAddPhotoAction(file));
    dispatch(postOrderAction(file));
    return false;
  },
  onChangeDelPhoto: () => dispatch(onChangeDelPhotoAction()),
  postOrder: () => dispatch(postOrderAction()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(OrderCreate);
