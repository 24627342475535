import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Row, Col } from 'antd';

import { useInjectSaga } from '@utils/injectSaga';
import { useInjectReducer } from '@utils/injectReducer';
import { makeSelectUser } from '@/global.selectors';
import { getOrderAction, handleModalShowAction } from './order.actions';
import reducer from './order.reducer';
import saga from './order.saga';
import { ORDER_STATUS } from './order.constants';
import { makeSelectOrderType, makeSelectFilter } from './order.selectors';

import OrderList from './List';
import FilterDate from './Filter/filterDate';
import Filter from './Filter';
import OrderCreate from './Create';

const key = 'processOrder';

function ProcessOrder(props) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    props.getOrders();
  });

  const getTitle = () => {
    const { filter } = props;
    if (filter.status) {
      const statusItem = ORDER_STATUS.find(x => x.value === filter.status);
      if (statusItem) {
        return `Xử lí đơn - ${statusItem.label}`;
      }
    }
    return 'Xử lí đơn';
  }
  return (
    <>
      <Helmet>
        <title>{getTitle()}</title>
      </Helmet>
      <div style={{ marginBottom: 16 }}>
        <h2>
          {getTitle()}
        </h2>
        <Row>
          <Col sm={12}>
            <OrderCreate />
          </Col>
          <Col sm={6} />
          <Col sm={6}>
            <FilterDate />
          </Col>
        </Row>
        <Row>
          <Filter />
        </Row>
      </div>
      <OrderList />
    </>
  );
}

ProcessOrder.propTypes = {
  getOrders: PropTypes.func,
  handleModalShow: PropTypes.func,
  orderType: PropTypes.objectOf(PropTypes.any),
  filter: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any),
};
ProcessOrder.defaultProps = {
  orderType: {},
};

const mapStateToProps = createStructuredSelector({
  orderType: makeSelectOrderType(),
  filter: makeSelectFilter(),
  user: makeSelectUser(),
});

const mapDispatchToProps = dispatch => ({
  getOrders: () => dispatch(getOrderAction()),
  handleModalShow: () => dispatch(handleModalShowAction()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(ProcessOrder);
