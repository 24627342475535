import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Row, Col, Table, Button } from 'antd';
import SapoLink from '@common/SapoLink';
import UserInfo from '@common/UserInfo';
import { formatDateTime } from '@utils/helper';
import { makeSelectUserList, makeSelectUser } from '@/global.selectors';
import { makeSelectList, makeSelectLoading } from '../orderTracking.selectors';
import { onSelectOrderAction } from '../orderTracking.actions';

class OrderTrackingList extends PureComponent {
  renderDelivery = deliveryStatus => {
    let result = '';
    try {
      if (deliveryStatus) {
        const json = JSON.parse(deliveryStatus);
        if (json) {
          result = json.map(x => {
            const date = new Date(x.time);
            let dateValue = formatDateTime(date / 1);
            return `${dateValue}: ${x.status}`
          }).join(' | ');
        }
      }
    }
    catch (e) {
      result = 'No data';
    }
    return result;
  }
  renderLog = log => {
    if (!log || !log.createdAt) return '';
    return <p>{formatDateTime(log.createdAt * 1000)}: {log.note}</p>
  }
  renderMeta = (_, item) => {
    const { isChargeBack, log } = item;
    return (<>
      {isChargeBack ? <p><b>Đơn bồi hoàn</b></p> : ''}
      {log && this.renderLog(log[log.length - 1])}
    </>)
  }
  render() {
    const { list, loading, user } = this.props;
    const userList = user.role > 1 ? [] : this.props.userList.filter(x => x.active && x.dept === 1).map(x => ({ text: x.name, value: x.userId }));
    const columns = [
      {
        title: 'Mã đơn hàng',
        dataIndex: 'orderCode',
        render: (_, item) => <SapoLink orderId={item.id} orderCode={item.orderCode} />
      },
      {
        title: 'Tên KH',
        dataIndex: 'customerName'
      },
      {
        title: 'Điện thoại',
        dataIndex: 'phone'
      },
      {
        title: 'Số tiền',
        dataIndex: 'totalPaid'
      },
      {
        title: 'Ngày tạo',
        dataIndex: 'orderCreatedAt',
        render: item => formatDateTime(item * 1000)
      },
      {
        title: 'Người tạo',
        dataIndex: 'orderCreatedBy',
        render: item => <UserInfo userId={item} fieldName="userId" />,
        filters: userList,
        onFilter: (value, record) => record.orderCreatedBy === value,
      },
      {
        title: 'Giao hàng',
        dataIndex: 'deliveryStatus',
        render: this.renderDelivery
      },
      {
        title: 'Xử lí',
        render: this.renderMeta
      },
      {
        dataIndex: 'id',
        render: (_, record) => <Button icon="edit" onClick={() => {
          this.props.onSelectOrder(record);
        }} />
      },
    ]
    return (
      <>
        <Row gutter={16}>
          <Col sm={24}>
            <h2>THEO DÕI ĐƠN</h2>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={24}>
            <Table
              loading={loading}
              dataSource={list}
              columns={columns}
              rowKey="orderCode"
            />
          </Col>
        </Row>
      </>
    );
  }
}

OrderTrackingList.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  userList: PropTypes.array,
  user: PropTypes.object,
};

OrderTrackingList.defaultProps = {
  list: [],
  loading: false,
  userList: [],
  user: {}
};

const mapStateToProps = createStructuredSelector({
  list: makeSelectList(),
  loading: makeSelectLoading(),
  userList: makeSelectUserList(),
  user: makeSelectUser(),
});

const mapDispatchToProps = dispatch => ({
  onSelectOrder: data => dispatch(onSelectOrderAction(data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(OrderTrackingList);
