import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useMutation } from 'react-query';
import querystring from 'query-string';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import request from '@utils/request';
import { makeSelectUserList } from '@/global.selectors';
import { Row, Col, Spin } from 'antd';
import PayloadDetail from './detail';

function PayloadItem(props) {
  let MONTH = 3;
  let YEAR = 2023;
  if (window.location.search) {
    let params = querystring.parse(window.location.search);
    if (params.year) YEAR = params.year;
    if (params.month) MONTH = params.month;
  }
  const [data, setData] = useState([]);
  const { isLoading, mutate: getPayload } = useMutation(() => {
    return request.get(`v1/payloads?year=${YEAR}&month=${MONTH}`);
  },
    {
      onSuccess: (response) => {
        // initData(response.data);
        setData(response.data);
      },
    })
  useEffect(() => {
    getPayload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderItem = user => {
    const userId = user.id;
    const userItem = data.find(x => x.userId === userId);
    let item = { userId };
    if (userItem) {
      item = {
        ...userItem,
        bankName: user.bankName,
        bankAccount: user.bankAccount,
        name: user.name,
      }
    }
    return (
      <Col key={item.id} sm={6}><PayloadDetail onChange={getPayload} item={item} year={YEAR} month={MONTH} /></Col>
    )
  }
  const dataSource = props.userList.filter(x => x.active && x.id > 4 && x.dept);
  return (<>
    <Row gutter={16}>
      <Col sm={14}>
        <h2>Bảng lương {MONTH}/{YEAR}</h2>
      </Col>
    </Row>
    <Spin spinning={isLoading}>
      <Row gutter={16}>
        {/* {data.map(item => <Col key={item.id} sm={6}><PayloadDetail onChange={getPayload} item={item} year={YEAR} month={MONTH} /></Col>)} */}
        {dataSource.map(renderItem)}
      </Row>
    </Spin>
  </>)
}

PayloadItem.propTypes = {
};

PayloadItem.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  userList: makeSelectUserList()
});

const mapDispatchToProps = dispatch => ({

});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(PayloadItem);
