import produce from 'immer';
import moment from 'moment';
import {
  GET_CASH_INCOME_SUCCESS,
  ON_CHANGE_DATE_FILTER,
} from './cash.constants';

export const initialState = {
  cashIncomeList: [],
  filterDate: {
    type: 'custom',
    value: moment(),
  },
};

/* eslint-disable default-case, no-param-reassign */
const cashReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_CASH_INCOME_SUCCESS: {
        const data = action.payload;
        draft.cashIncomeList = data;
        break;
      }
      case ON_CHANGE_DATE_FILTER: {
        const { type, value } = action.payload;
        console.log(type, value);
        draft.filterDate = {
          type, value, uid: new Date() / 1
        }
        break;
      }
    }
  });

export default cashReducer;
