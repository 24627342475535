import Order from 'containers/Order';
import SignIn from 'containers/SignIn';
import NotFound from 'containers/NotFound';
import OrderDetail from 'containers/Order/Detail/modalDetail';
import ProcessOrder from 'containers/ProcessOrder';
import Cash from 'containers/Cash';
import Dashboard from 'containers/Dashboard';
import OrderTracking from 'containers/OrderTracking';
import ProductMeta from 'containers/ProductMeta';
import Statistics from 'containers/Statistics';
import User from 'containers/User';
import Support from 'containers/Support';
import Admin from 'containers/Admin';

const mainRoutes = [
  {
    exact: true,
    path: '/',
    name: 'Home',
    icon: 'home',
    component: Order,
    auth: true,
    sidebar: true,
    permission: 2,
  },
  {
    path: '/order/:id',
    name: 'Chi tiết đơn hàng',
    icon: 'login',
    component: OrderDetail,
  },
  {
    path: '/process-order',
    name: 'Xử lí đơn',
    icon: 'login',
    component: ProcessOrder,
    sidebar: true,
    permission: 2,
  },
  {
    exact: true,
    path: '/cash',
    name: 'Tiền mặt',
    icon: 'credit-card',
    component: Cash,
    auth: true,
    sidebar: true,
    permission: 2,
  },
  {
    exact: true,
    path: '/dashboard',
    name: 'Chấm công',
    icon: 'dashboard',
    component: Dashboard,
    auth: true,
    sidebar: true
  },
  {
    exact: true,
    path: '/order-tracking',
    name: 'Theo dõi đơn',
    icon: 'warning',
    component: OrderTracking,
    auth: true,
    sidebar: true,
    permission: 2,
  },
  {
    exact: true,
    path: '/product',
    name: 'Sản phẩm',
    icon: 'database',
    component: ProductMeta,
    auth: true,
    sidebar: true
  },
  {
    path: '/statistics',
    name: 'Thống kê',
    icon: 'bar-chart',
    component: Statistics,
    // permission: 1,
    auth: true,
    sidebar: true
  },
  {
    path: '/support',
    name: 'Hỗ trợ',
    icon: 'tool',
    component: Support,
    auth: true,
    sidebar: true
  },
  {
    path: '/users',
    name: 'Tài khoản',
    icon: 'login',
    component: User,
    sidebar: false
  },
  {
    path: '/admin',
    name: 'Admin',
    icon: 'setting',
    component: Admin,
    permission: 1,
    sidebar: true
  },
  {
    path: '/signin',
    name: 'Sign In',
    icon: 'login',
    component: SignIn,
  },
  {
    path: '',
    name: 'Not Found',
    icon: 'close-circle',
    component: NotFound,
  },
];

export default mainRoutes;
