import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Avatar } from 'antd';

import { makeSelectUserList } from '@/global.selectors';

class UserAvatar extends PureComponent {
  render() {
    const { userId, userList, className } = this.props;
    const user = userList.find(x => x.id === userId);
    if (!user) { return null; }
    return <Avatar className={className} src={user.avatar} />
  }
}

UserAvatar.propTypes = {
  userId: PropTypes.number,
  className: PropTypes.string,
};

UserAvatar.defaultProps = {
  userId: 0,
  className: '',
};

const mapStateToProps = createStructuredSelector({
  userList: makeSelectUserList(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, memo)(UserAvatar);
