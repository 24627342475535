import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useMutation } from 'react-query';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import request from '@utils/request';
import { formatNumber, groupBy } from '@utils/helper';
import { PLACE_ENUM, SHOP_PLACE_LIST, SOURCE_GROUP } from '@utils/constants';
import { Row, Col, Spin } from 'antd';
import { Column, Area } from '@ant-design/plots';
import RelativeDate from '@common/RelativeDate';
import SumTable from '@common/SumTable';

function Sale(props) {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [source, setSource] = useState([]);
  const [isColumn, setIsColumn] = useState(false);
  const { isLoading, mutate } = useMutation(({ type, timeRange }) => {
    const { from, to, range } = timeRange;
    return request.get(`v1/statistics/sales?from=${from}&to=${to}&timeRange=${range || 'day'}`);
  },
    {
      onSuccess: (response) => {
        initData(response.data);
      },
    })
  const initData = (resData) => {
    const tableData = [];
    const sourceData = groupBy(resData, 'source');
    const tData = [];
    resData.forEach(x => {
      const item = {
        ...x,
        total: parseInt(x.total, 0),
      }
      const tableInd = tableData.findIndex(t => t.timeRange === x.timeRange);
      const source = SOURCE_GROUP[item.source] || item.source;
      if (tableInd < 0) {
        const tableItem = {
          timeRange: item.timeRange,
          total: item.total,
        };
        sourceData.forEach(s => tableItem[s] = 0);
        SHOP_PLACE_LIST.forEach(pl => tableItem[PLACE_ENUM[pl.value]] = 0);
        tableItem[source] = tableItem[source] ? tableItem[source] + item.total : item.total;
        if(source === 'Pos'){
          tableItem[PLACE_ENUM[item.branchId]] = item.total;
        }
        tableData.push(tableItem);
      } else {
        tableData[tableInd][source] = tableData[tableInd][source] ? tableData[tableInd][source] + item.total : item.total;
        tableData[tableInd].total += item.total;
        if(source === 'Pos'){
          tableData[tableInd][PLACE_ENUM[item.branchId]] = item.total;
        }
      }

      if(source){
        const tDataInd = tData.findIndex(t => t.timeRange === item.timeRange && t.source === source);
        if(tDataInd < 0){
          tData.push(item);
        } else {
          tData[tDataInd].total += item.total;
        }
      }

    });
    setTableData(tableData);
    setSource(sourceData);
    setIsColumn(tableData.length === 1);
    console.log(tData);
    setData(tData);
  }
  const onChangeDate = (type, timeRange) => {
    setData([]);
    setTableData([]);
    mutate({ type, timeRange });
  }
  const config = {
    data,
    isStack: true,
    xField: 'timeRange',
    yField: 'total',
    seriesField: 'source',
    yAxis: {
      label: {
        formatter: formatNumber,
      }
    },
    xAxis: {
      label: {
        formatter: formatNumber,
      }
    },

    label: {
      formatter: obj => {
        return formatNumber(obj.total);
      },
    },
  };
  const columns = [
    {
      title: 'Ngày',
      dataIndex: 'timeRange',
    },
    {
      title: 'Tổng',
      dataIndex: 'total',
      align: 'right',
      render: formatNumber,
      sorter: (a, b) => a.total - b.total,
    }
  ]
  source.filter(s => s && !SOURCE_GROUP[s]).forEach(s => {
    columns.push({
      title: s,
      dataIndex: s,
      align: 'right',
      render: formatNumber,
      sorter: (a, b) => a[s] - b[s],
    })
  })
  const numberColumns = ['total', ...source];
  SHOP_PLACE_LIST.forEach(s => {
    columns.push({
      title: s.label,
      dataIndex: s.label,
      align: 'right',
      render: formatNumber,
      sorter: (a, b) => a[s] - b[s],
    });
    numberColumns.push(s.label);
  })
  return (<>
    <Row gutter={16}>
      <Col sm={14}>
        <h2>Doanh số theo nguồn bán hàng</h2>
      </Col>
      <Col sm={10}>
        <RelativeDate onChange={onChangeDate} />
      </Col>
    </Row>
    <Row gutter={16} style={{ marginTop: '1rem' }}>
      <Col sm={24}>
        <Spin spinning={isLoading}>
          {isColumn ?
            <Column {...config} />
            : <Area {...config} />
          }
        </Spin>
      </Col>
    </Row>
    <Row gutter={16} style={{ marginTop: '2rem' }}>
      <Col sm={24}>
        <SumTable
          numberColumns={numberColumns}
          pagination={false}
          dataSource={tableData}
          columns={columns}
          rowKey="timeRange" />
      </Col>
    </Row>
  </>)
}

Sale.propTypes = {
};

Sale.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
});

const mapDispatchToProps = dispatch => ({

});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Sale);
