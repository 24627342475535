import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Button, Modal, Spin, Progress, Row, Col } from 'antd';
import { makeSelectUser } from '@/global.selectors';
import request from '@utils/request';
import { selectList, selectLoading } from '../selectors';
import { getProductAction } from '../actions';
import { SIZE_COLUMN, PLACE } from '@utils/constants';

class UpdateWoo extends PureComponent {
  constructor() {
    super();
    this.state = {
      showModal: false,
      isProcessing: false,
      dataList: [],
      currentProcessIndex: 0,
    }
    this.currentProcessIndex = 0;
    this.tDoUpdateWoo = null;
  }
  handleOpenModal = () => {
    this.setState({ showModal: true });
    this.props.getList();
  }
  handleUpdateWoo = () => {
    if (this.state.isProcessing) {
      this.setState({ isProcessing: false });
    } else {
      this.setState({ isProcessing: true });
      this.currentProcessIndex = 0;
      const dataList = this.props.list.filter(x => x.wooProductId);
      this.setState({ dataList, currentProcessIndex: 0 }, () => this.doUpdateWoo());
    }

  }
  doUpdateWoo = async () => {
    if (!this.state.isProcessing || this.currentProcessIndex >= this.state.dataList.length) {
      this.setState({ isProcessing: false });
      return;
    }
    const item = this.state.dataList[this.currentProcessIndex];
    const { wooVariationIds } = item;
    const wooProductId = parseInt(item.wooProductId, 0);
    const pushData = [];
    SIZE_COLUMN.forEach((size, ind) => {
      const variationId = wooVariationIds[size] || wooProductId + ind + 1;
      if (variationId) {
        let totalQty = item[`branch${PLACE.OFFICE}${size}`] || 0;
        // if (totalQty) {
        //   const branchHVHQty = item[`branch${PLACE.SHOP48}${size}`];
        //   const branchNDCQty = item[`branch${PLACE.SHOP222}${size}`];
        //   if (branchHVHQty) totalQty -= 1;
        //   if (branchNDCQty) totalQty -= 1;
        // }
        const updateData = {
          id: variationId,
          quantity: totalQty
        }
        pushData.push(updateData);
      }
    });
    if (pushData.length) {
      const putData = {
        productId: wooProductId,
        variations: pushData
      }
      await request.put(`v1/product-meta/woo`, putData);
    }
    clearTimeout(this.tDoUpdateWoo);
    this.currentProcessIndex += 1;
    if (this.currentProcessIndex < this.state.dataList.length) {
      this.tDoUpdateWoo = setTimeout(() => { this.doUpdateWoo(); }, 500);
      this.setState({ currentProcessIndex: this.currentProcessIndex });
    } else {
      this.setState({
        isProcessing: false
      });
    }

    return;
  }
  render() {
    if (this.props.user.role > 1) return '';
    const { showModal, isProcessing } = this.state;
    const { loading } = this.props;
    const currentItem = this.state.dataList[this.state.currentProcessIndex] || {};
    const progress = isProcessing ? Math.round(((this.state.currentProcessIndex + 1) / this.state.dataList.length) * 100) : 0;
    return (
      <>
        <Button onClick={this.handleOpenModal}>Cập nhật Web</Button>
        {showModal &&
          <Modal title="Cập nhật web" visible onCancel={() => this.setState({ showModal: false })} footer={null}>
            <Spin spinning={loading}>
              <Row>
                <Col sm={24}>
                  <Progress percent={progress} />
                </Col>
              </Row>
              <Row>
                <Col sm={24}>
                  <Button onClick={this.handleUpdateWoo} type="primary">{isProcessing ? 'Stop' : 'Start'}</Button>
                  {isProcessing && `Updating ${currentItem.pName}`}
                </Col>
              </Row>

            </Spin>
          </Modal>}
      </>
    );
  }
}

UpdateWoo.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
};

UpdateWoo.defaultProps = {
  list: [],
  loading: false,
};

const mapStateToProps = createStructuredSelector({
  list: selectList(),
  loading: selectLoading(),
  user: makeSelectUser(),
});

const mapDispatchToProps = dispatch => ({
  getList: () => dispatch(getProductAction()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(UpdateWoo);
