import React, { useState } from 'react';
import { useMutation } from 'react-query';
import request from '@utils/request';
import { formatNumber, groupBy } from '@utils/helper';
import { Row, Col, Spin } from 'antd';
import YearList from '@common/YearList';
import SumTable from '@common/SumTable';


function SaleTimeQuantity() {
  const [tableData, setTableData] = useState([]);
  const [groups, setGroups] = useState([]);
  const { isLoading, mutate } = useMutation(({ timeRange }) => {
    const { from, to, range } = timeRange;
    return request.get(`v1/statistics/products?type=timeQty&from=${from}&to=${to}&timeRange=${range}`);
  },
    {
      onSuccess: (response) => {
        initData(response.data);
      },
    })
  const initData = (resData) => {
    const tableData = [];
    const timeRanges = groupBy(resData, 'timeRange');
    setGroups(timeRanges);
    resData.forEach(x => {
      let { groupName, shortName, timeRange } = x;

      let qty = parseInt(x.qty, 0);
      //table data
      const tableInd = tableData.findIndex(t => t.shortName === shortName);
      if (tableInd < 0) {
        const tableItem = {
          groupName: groupName,
          shortName: shortName,
          total: qty,
        };
        timeRanges.forEach(gr => tableItem[gr] = 0)
        tableItem[timeRange] = qty;
        tableData.push(tableItem);
      } else {
        tableData[tableInd][timeRange] = qty;
        tableData[tableInd].total += qty;
      }

    });
    tableData.sort((a, b) => b.total - a.total);
    setTableData(tableData);
  }
  const onChangeDate = (type, timeRange) => {
    setTableData([]);
    mutate({ timeRange });
  }
  const columns = [
    {
      title: 'Sản phẩm',
      dataIndex: 'groupName',
    },
    {
      title: 'SKU',
      dataIndex: 'shortName',
    }
  ];
  groups.forEach(gr => {
    columns.push({
      title: gr,
      dataIndex: gr,
      align: 'right',
      render: formatNumber,
      sorter: (a, b) => a[gr] - b[gr],
    })
  })
  columns.push({
    title: 'Tổng',
    dataIndex: 'total',
    align: 'right',
    render: formatNumber,
    sorter: (a, b) => a.total - b.total,
  })
  const numberColumns = [...groups, 'total'];
  return (<>
    <Row gutter={16}>
      <Col sm={14}>
        <h2>Sản phẩm đã bán theo thời gian</h2>
      </Col>
      <Col sm={10}>
        <YearList onChange={onChangeDate} />
      </Col>
    </Row>
    <Spin spinning={isLoading}>
      <Row gutter={16} style={{ marginTop: '2rem' }}>
        <Col sm={24}>
          <SumTable
            numberColumns={numberColumns}
            pagination={false}
            dataSource={tableData}
            columns={columns}
            rowKey="timeRange" />
        </Col>
      </Row>
    </Spin>
  </>)
}

SaleTimeQuantity.propTypes = {
};

SaleTimeQuantity.defaultProps = {
};

export default SaleTimeQuantity;
