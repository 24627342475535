import request from '@utils/request';
import { FILTER_DATE_ENUM } from './order.constants';
import { getStartUnix, getEndUnix } from '../../utils/helper';

export function getOrderAPI(filter = {}) {
  const params = { ...filter };
  if (params.date) {
    const { type, value } = params.date;
    if (type === 'relative') {
      const date = new Date();
      switch (value) {
        case FILTER_DATE_ENUM.TODAY: {
          params.from = getStartUnix(date);
          params.to = getEndUnix(date);
          break;
        }
        case FILTER_DATE_ENUM.YESTERDAY: {
          date.setDate(date.getDate() - 1);
          params.from = getStartUnix(date);
          params.to = getEndUnix(date);
          break;
        }
        case FILTER_DATE_ENUM.THISMONTH: {
          const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
          const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
          params.from = getStartUnix(firstDay);
          params.to = getEndUnix(lastDay);
          break;
        }
        case FILTER_DATE_ENUM.LASTMONTH: {
          date.setMonth(date.getMonth() - 1);
          const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
          const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
          params.from = getStartUnix(firstDay);
          params.to = getEndUnix(lastDay);
          break;
        }
        default: {
          delete params.from;
          delete params.to;
          break;
        }
      }
    }
    delete params.date;
  }
  if (!params.status) {
    delete params.status;
  }
  return request.get('v1/pre-orders', { params });
}

export function postOrderAPI(files) {
  const formData = new FormData();
  formData.append('files', files[0]);

  return request.post('v1/pre-orders', formData);
}

export const putOrderApi = payload => {
  const formData = new FormData();
  const { id, ...rest } = payload;
  Object.keys(rest).forEach(key => {
    formData.set(key, payload[key]);
  });

  return request.put(`v1/pre-orders/${id}`, formData);
};

export const deleteOrderApi = payload => {
  const { id } = payload;

  return request.delete(`v1/pre-orders/${id}`);
};

export const updateStatusOrderApi = () => {
  return request.post(`v1/pre-orders/update-status`);
};

export const postOrderLogApi = payload => {
  const { id, log } = payload;
  return request.post(`v1/pre-orders/${id}/logs`, { log });
};