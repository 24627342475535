import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Spin, Statistic } from 'antd';

function Stat({ loading, title, value }) {
  return (<Spin spinning={loading}>
    <Statistic title={title} value={value} />
  </Spin>)
}

Stat.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

Stat.defaultProps = {
  loading: false,
  title: '',
  value: '',
};


export default memo(Stat);
