
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import request from '@utils/request';
import { validateFields } from '@utils/helper';
import { Button, Input, InputNumber, Form, Row, Col, Spin } from 'antd';
import './style.less';

function TrackingForm({ item, form, category, isLoading, year, month, day, userId, onSave, onCancel }) {
  const { getFieldDecorator } = form;
  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item])
  const { isLoading: isSaving, mutate: saveTracking } = useMutation((data) => {
    if (item.id) {
      return request.put(`v1/tracking-inboxs/${item.id}`, data);
    }
    return request.post(`v1/tracking-inboxs`, data);
  },
    {
      onSuccess: (response) => {
        onSave();
      },
    });
  const handleSubmit = async e => {
    e.preventDefault();
    const [err, values] = await validateFields(form);
    if (!err) {
      const postData = {
        ...values,
        year,
        month,
        day,
        userId,
        trackingCategoryId: category.id
      }
      saveTracking(postData);
    }
  }
  return (
    <Spin spinning={isLoading || isSaving}>
      <Form onSubmit={handleSubmit} className='trackingForm'>
        <Row gutter={8}>
          <Col sm={8}>
            <Form.Item>
              {category.name}
            </Form.Item>
          </Col>
          <Col sm={4}>
            <Form.Item>
              {getFieldDecorator('quantity', {
                initialValue: item.quantity
              })(<InputNumber onPressEnter={handleSubmit} onBlur={handleSubmit} />)
              }
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item>
              {getFieldDecorator('note', {
                initialValue: item.note
              })(<Input onPressEnter={handleSubmit}  onBlur={handleSubmit}/>)
              }
            </Form.Item>
          </Col>
          <Col sm={4}>
            <Button style={{ marginTop: 5 }} type='primary' onClick={handleSubmit}>Lưu</Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  )
}

TrackingForm.propTypes = {
  form: PropTypes.object,
  category: PropTypes.object,
  item: PropTypes.object,
  day: PropTypes.number,
  year: PropTypes.number,
  month: PropTypes.number,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  userId: PropTypes.number,
};

TrackingForm.defaultProps = {
  form: {},
  category: {},
  item: {},
  year: 0,
  month: 0,
  day: 0,
  onSave: () => { },
  onCancel: () => { },
  isLoading: false,
  userId: 0
};

export default Form.create()(TrackingForm);
