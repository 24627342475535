import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Row, Col } from 'antd';

import { useInjectSaga } from '@utils/injectSaga';
import { useInjectReducer } from '@utils/injectReducer';
import { getCashIncomeAction } from './cash.actions';
import reducer from './cash.reducer';
import saga from './cash.saga';

import List from './List';
import FilterDate from './Filter/filterDate';

export const key = 'cash';

function Cash(props) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    props.getCashIncome();
  });

  return (
    <>
      <Helmet>
        <title>Cash</title>
      </Helmet>
      <div style={{ marginBottom: 16 }}>


        <Row>
          <Col sm={12}>
            <h2>Thu Tiền Mặt</h2>
          </Col>
          <Col sm={6} />
          <Col sm={6}>
            <FilterDate />
          </Col>
        </Row>
      </div>
      <List />
    </>
  );
}

Cash.propTypes = {
  getCashIncome: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = dispatch => ({
  getCashIncome: () => dispatch(getCashIncomeAction()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Cash);
