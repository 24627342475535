import { createSelector } from 'reselect';
import { initialState } from './cash.reducer';
import { key } from './index';
const selectBoardDomain = state => state[key] || initialState;

const makeSelectCashIncomeList = () => createSelector(selectBoardDomain, substate => substate.cashIncomeList);

const makeSelectFilterDate = () => createSelector(selectBoardDomain, substate => substate.filterDate);


export {
  selectBoardDomain,
  makeSelectCashIncomeList,
  makeSelectFilterDate,
};
