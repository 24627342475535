import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { DatePicker } from 'antd';

import { makeSelectFilterDate } from '../cash.selectors';
import { onChangeDateFilterAction, getCashIncomeAction } from '../cash.actions';

class FilterDate extends PureComponent {
  handleChangeFilterDate = date => {
    const { onChangeFilter, getCashIncome } = this.props;
    const data = {
      type: 'custom',
      value: date,
    };
    onChangeFilter(data);
    getCashIncome();
  };

  render() {
    const { filterDate } = this.props;
    let dateValue = null;
    if (filterDate.type === 'custom') {
      dateValue = filterDate.value;
    }
    return (
      <DatePicker value={dateValue} format="DD/MM/YYYY" onChange={this.handleChangeFilterDate} />
    );
  }
}

FilterDate.propTypes = {
  onChangeFilter: PropTypes.func,
  getCashIncome: PropTypes.func,
  filterDate: PropTypes.objectOf(PropTypes.any),
};

FilterDate.defaultProps = {
  onChangeFilter: () => { },
  getCashIncome: () => { },
};

const mapStateToProps = createStructuredSelector({
  filterDate: makeSelectFilterDate(),
});

const mapDispatchToProps = dispatch => ({
  onChangeFilter: data => dispatch(onChangeDateFilterAction(data)),
  getCashIncome: () => dispatch(getCashIncomeAction()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(FilterDate);
