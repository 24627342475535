import PosOrder from './PosOrder';
import Income from './Income';
import IncomeEmployee from './IncomeEmployee';
import Sale from './Sale';
import Order from './Order';
import Product from './Product';
import SaleQuantity from './SaleQuantity';
import SaleTimeQuantity from './SaleTimeQuantity';
import SaleMaterial from './SaleMaterial';
import Quantity from './Quantity';
import Home from './Home';

const PARENT = '/statistics';
const routes = [
  {
    exact: true,
    path: `${PARENT}`,
    name: 'Dashboard',
    icon: 'home',
    component: Home,
    sidebar: true,
    permission: 1,
  },
  {
    exact: true,
    path: `${PARENT}/source`,
    name: 'Doanh số theo nguồn',
    icon: 'area-chart',
    component: Sale,
    sidebar: true,
    permission: 1,
  },
  {
    exact: true,
    path: `${PARENT}/order`,
    name: 'Đơn hàng',
    icon: 'area-chart',
    component: Order,
    sidebar: true,
    permission: 1,
  },
  {
    exact: true,
    path: `${PARENT}/income`,
    name: 'Doanh thu',
    icon: 'area-chart',
    component: Income,
    sidebar: true,
    permission: 1,
  },
  {
    exact: true,
    path: `${PARENT}/income-by-employee`,
    name: 'Doanh thu theo NV',
    icon: 'area-chart',
    component: IncomeEmployee,
    sidebar: true,
    permission: 1,
  },
  {
    exact: true,
    path: `${PARENT}/product-by-size`,
    name: 'Size',
    icon: 'area-chart',
    component: Product,
    sidebar: true,
  },
  {
    exact: true,
    path: `${PARENT}/product-by-quantity`,
    name: 'SP theo số lượng',
    icon: 'table',
    component: SaleQuantity,
    sidebar: true,
  },
  {
    exact: true,
    path: `${PARENT}/product-by-time-quantity`,
    name: 'SP theo thời gian',
    icon: 'table',
    component: SaleTimeQuantity,
    sidebar: true,
  },
  {
    exact: true,
    path: `${PARENT}/product-by-material`,
    name: 'SP theo chất liêu',
    icon: 'table',
    component: SaleMaterial,
    sidebar: true,
  },
  {
    exact: true,
    path: `${PARENT}/pos-order`,
    name: 'Đơn POS',
    icon: 'home',
    component: PosOrder,
    sidebar: true,
    permission: 1,
  },
  {
    exact: true,
    path: `${PARENT}/product-available`,
    name: 'Hàng tồn',
    icon: 'table',
    component: Quantity,
    sidebar: true,
  },
];

export default routes;
