import request from '@utils/request';

export function getProductApi(params = {}) {
  return request.get('v1/product-meta', params);
}

export function getProductQuantityApi() {
  return request.get('v1/product-meta/quantities');
}

export function postProductApi(payload) {
  const formData = new FormData();
  const { files, ...rest } = payload;
  Object.keys(rest).forEach(key => {
    formData.set(key, payload[key]);
  });
  if (files) {
    formData.append('files', files[0]);
  }
  return request.post('v1/product-meta', formData);
}