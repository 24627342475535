import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { formatTime, formatDate } from '@utils/helper';
import UserAvatar from './userAvatar';


class LogInfo extends PureComponent {
  renderContent = item => (
    <div key={item.id}>
      <span className="time">[{formatTime(item.createdAt)}]</span>
      {` `}
      <span className="user">
        <UserAvatar userId={item.createdBy} mode="short" />
      </span>
      {` : `}
      <span className="note">{item.note}</span>
    </div>
  )
  renderItem = (item) => {
    const itemDate = formatDate(item.createdAt * 1000);
    if (itemDate !== this.lastDate) {
      this.lastDate = itemDate;
      return (
        <Fragment key={`date-${item.id}`}>
          <div className="logDate">{itemDate}</div>
          {this.renderContent(item)}
        </Fragment>
      )
    }
    return this.renderContent(item);
  }
  render() {
    const { logs } = this.props;
    if (!logs) return null;
    const dataSource = [...logs];
    dataSource.sort((a, b) => b.createdAt - a.createdAt)
    this.lastDate = '';
    return (
      <div className="logArea">

        {dataSource.map(this.renderItem)}
      </div>
    );
  }
}

LogInfo.propTypes = {
  logs: PropTypes.arrayOf(PropTypes.any)
};

LogInfo.defaultProps = {
  logs: []
}

export default LogInfo;
