import React, { memo, PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Row, Col, Alert } from 'antd';
import { formatDate } from '@utils/helper';

import OrderDetail from '../Detail';

import { makeSelectOrderList, makeSelectOrgOrderList } from '../order.selectors';
import { onChangeFieldAction, putOrderAction } from '../order.actions';

class OrderList extends PureComponent {
  constructor() {
    super();
    this.lastDate = '';
  }
  handleChangeField = e => {
    const { value, dataset } = e.target;
    const { id, field } = dataset;
    const data = {
      id: parseInt(id, 0),
      fieldName: field,
      fieldValue: value,
    };
    this.props.onChangeField(data);
  };

  handleUpdate = e => {
    const { id, field } = e.target.dataset;
    const { orgOrderList } = this.props;
    const itemId = parseInt(id, 0);
    const orgItem = orgOrderList.find(x => x.id === itemId);
    if (orgItem && orgItem[field] !== e.target.value) {
      const data = {
        id: itemId,
      };
      data[field] = e.target.value;
      this.props.onUpdate(data);
    }
  };

  handleChangeType = id => (fieldName, checked) => {
    const data = {
      id: parseInt(id, 0),
      fieldName,
      fieldValue: checked,
    };
    this.props.onChangeField(data);
    const updateData = {
      id: parseInt(id, 0),
      [fieldName]: checked,
    };
    this.props.onUpdate(updateData);
  };

  initData = data => {
    const anchors = [];
    let lastDate = '';
    const result = data.map((item, ind) => {
      const orderTime = formatDate(item.createdAt * 1000);
      if (lastDate !== orderTime) {
        lastDate = orderTime;
        anchors.push({ ind, count: 1 });
      } else {
        anchors[anchors.length - 1].count += 1;
      }
      return { ...item, orderTime };
    });
    anchors.forEach(item => {
      result[item.ind].count = item.count;
    });
    return result;
  }

  getOrgItem = (list, id) => {
    const orgItem = list.find(x => x.id === id);
    return orgItem;
  };

  getDate = unixTime => {
    const date = formatDate(unixTime * 1000);
    return date;
  }

  renderItem = (item) => {
    const { orgOrderList } = this.props;
    if (item.orderTime !== this.lastDate) {
      this.lastDate = item.orderTime;
      return (<Fragment key={`${item.orderTime}.${item.id}`}>
        <Col xs={24} key={item.orderTime}>
          <Alert type="success" message={`${item.orderTime} (${item.count})`} style={{ marginBottom: 10, fontWeight: 'bold' }} />
        </Col>
        <Col xs={24} sm={12} lg={6} key={item.id} style={{ marginBottom: 16 }}>
          <OrderDetail item={item} orgItem={this.getOrgItem(orgOrderList, item.id)} />
        </Col>
      </Fragment>)
    }
    return (
      <Col xs={24} sm={12} lg={6} key={item.id} style={{ marginBottom: 16 }}>
        <OrderDetail item={item} orgItem={this.getOrgItem(orgOrderList, item.id)} />
      </Col>
    )
  }

  render() {
    const { orderList } = this.props;
    const dataSource = this.initData(orderList);
    this.lastDate = '';
    return (
      <>
        <Row gutter={16}>
          {dataSource.map(this.renderItem)}
        </Row>
      </>
    );
  }
}

OrderList.propTypes = {
  onChangeField: PropTypes.func,
  onUpdate: PropTypes.func,
  orderList: PropTypes.array,
  orgOrderList: PropTypes.array,
};

OrderList.defaultProps = {
  orderList: [],
  orgOrderList: [],
};

const mapStateToProps = createStructuredSelector({
  orderList: makeSelectOrderList(),
  orgOrderList: makeSelectOrgOrderList(),
});

const mapDispatchToProps = dispatch => ({
  onChangeField: data => dispatch(onChangeFieldAction(data)),
  onUpdate: data => dispatch(putOrderAction(data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(OrderList);
