import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { makeSelectUser } from '@/global.selectors';
import { Icon, Tag, Button } from 'antd';
import { orderType } from '@types';
import { formatShortDateTime, getImageUrl } from '@utils/helper';
import UserAvatar from './userAvatar';
import LogInfo from './logInfo';
import './style.less';
import { ORDER_STATUS, DISABLE_ORDER_STATUS } from '../order.constants';
import { ORDER_STATUS_ENUM } from 'containers/Order/order.constants';

const getOrderStatus = stat => {
  const item = ORDER_STATUS.find(x => x.value === stat);
  if (!item) return stat;
  return item.label;
};

const getOrderStatusColor = stat => {
  const item = ORDER_STATUS.find(x => x.value === stat);
  if (!item) return '#ccc';
  return item.color;
};

const showDeleteButton = (order, user) => {
  return user.role === 1 && !order.orderCode && DISABLE_ORDER_STATUS.indexOf(order.status) < 0;
}

const showDoneButton = (order, user) => {
  if (order.createdBy === user.id && ORDER_STATUS_ENUM.PENDING === order.status) {
    return true;
  }
  return false;
}

const OrderInfo = ({ order, onDone, onDelete, onCancel, user }) => (
  <div className="processOrderItemContainer" style={{ backgroundImage: `url(${getImageUrl(order.image)})` }}>
    {order.loading && <Icon className="orderItemStatus" type="redo" spin />}
    {order.error && <Icon className="orderItemStatus" type="info-circle" />}
    <UserAvatar userId={order.createdBy} className="orderCreated" />
    <div className="orderTime">{formatShortDateTime(order.createdAt * 1000)}</div>
    <Tag className={`orderStatus status-${order.status}`} color={getOrderStatusColor(order.status)}>
      {getOrderStatus(order.status)}
    </Tag>
    {showDeleteButton(order, user) && (
      <>
        <Button onClick={() => onDelete({ id: order.id })} className="orderDelete" type="danger">Delete</Button>
        <Button onClick={() => onCancel(order.id)} className="orderCancel" type="danger">Xóa</Button>
      </>
    )}
    {showDoneButton(order, user) && <Button onClick={() => onDone(order.id)} className="orderDone" type="primary">Xong</Button>}
    <LogInfo logs={order.logs} />
  </div>
);

OrderInfo.propTypes = {
  order: orderType,
  disabled: PropTypes.bool,
  user: PropTypes.objectOf(PropTypes.any),
};

OrderInfo.defaultProps = {
  onDone: () => { },
  onDelete: () => { },
  onCancel: () => { },
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, memo)(OrderInfo);