import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Row, Col, Table } from 'antd';
import SapoLink from '@common/SapoLink';


import { makeSelectCashIncomeList } from '../cash.selectors';

class CashList extends PureComponent {

  getTotal = list => {
    const total = list.reduce((partialSum, a) => partialSum + a.totalPaid, 0);
    return total.toLocaleString();
  }
  render() {
    const { cashIncomeList } = this.props;
    const posCompletedList = cashIncomeList.filter(x => x.source === 'Pos' && x.orderCompletedAt);
    const posUnCompletedList = cashIncomeList.filter(x => x.source === 'Pos' && !x.orderCompletedAt);
    const completedList = cashIncomeList.filter(x => x.source !== 'Pos' && x.orderCompletedAt);
    const unCompletedList = cashIncomeList.filter(x => x.source !== 'Pos' && !x.orderCompletedAt);
    const columns = [
      {
        title: 'Mã đơn hàng',
        dataIndex: 'orderCode',
        render: (_, item) => <SapoLink orderId={item.id} orderCode={item.orderCode} />
      },
      {
        title: 'Tên KH',
        dataIndex: 'customerName'
      },
      {
        title: 'Điện thoại',
        dataIndex: 'phone'
      },
      {
        title: 'Số tiền',
        dataIndex: 'totalPaid'
      },
    ]
    return (
      <>
        <Row gutter={16}>
          <Col sm={24}>
            <h2>KHO</h2>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={12}>
            Đơn hoàn tất ({completedList.length}):  {this.getTotal(completedList)}
            <Table
              dataSource={completedList}
              columns={columns}
            />
          </Col>
          <Col sm={12}>
            Đơn chưa hoàn tất ({unCompletedList.length}):  {this.getTotal(unCompletedList)}
            <Table
              dataSource={unCompletedList}
              columns={columns}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={24}>
            <h2>POS</h2>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={12}>
            Đơn hoàn tất ({posCompletedList.length}):  {this.getTotal(posCompletedList)}
            <Table
              dataSource={posCompletedList}
              columns={columns}
            />
          </Col>
          <Col sm={12}>
            Đơn chưa hoàn tất ({posUnCompletedList.length}):  {this.getTotal(posUnCompletedList)}
            <Table
              dataSource={posUnCompletedList}
              columns={columns}
            />
          </Col>
        </Row>
      </>
    );
  }
}

CashList.propTypes = {
  cashIncomeList: PropTypes.array,
};

CashList.defaultProps = {
  cashIncomeList: [],
};

const mapStateToProps = createStructuredSelector({
  cashIncomeList: makeSelectCashIncomeList(),
});

const mapDispatchToProps = dispatch => ({});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(CashList);
