
import React from 'react';
import PropTypes from 'prop-types';
import { Table, Spin, Button, Popconfirm } from 'antd';
import { formatNumber } from '@utils/helper';
import UserInfo from '@common/UserInfo';

function CreditList({ loading, dataSource, categoryList, onSelect, onDelete }) {
  const renderCategory = text => {
    if (text) {
      const id = parseInt(text, 0);
      const cat = categoryList.find(x => x.id === id);
      if (cat) {
        return cat.name;
      }
    }
    return '-';
  }
  const renderDate = (_, rec) => {
    const date = new Date(rec.incomeDate * 1000);
    return `${rec.day}/${rec.month}/${rec.year} ${date.getHours()}:${date.getMinutes()}`;
  }
  const renderAction = (id, rec) => {
    return (
      <>
        <Button icon="edit" onClick={() => onSelect(rec)} />
        <Popconfirm title="Chắc mới xóa nha !!!" onConfirm={() => onDelete(id)}>
          <Button style={{ marginLeft: 10 }} icon="delete" />
        </Popconfirm>
      </>
    )
  }
  const cats = categoryList.map(x => ({text: x.name, value: x.id}));
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Ngày',
      dataIndex: 'incomeDate',
      render: renderDate
    },
    {
      title: 'Nhân viên',
      dataIndex: 'createdBy',
      render: text => <UserInfo userId={text} />
    },
    {
      title: 'Nhóm',
      dataIndex: 'categoryID',
      render: renderCategory,
      filters: cats,
      onFilter: (value, record) => record.categoryID === value,
    },
    {
      title: 'Thu',
      dataIndex: 'inAmount',
      render: t => formatNumber(t)
    },
    {
      title: 'Chi',
      dataIndex: 'outAmount',
      render: t => formatNumber(t)
    },
    {
      title: 'Mã đơn',
      dataIndex: 'orderCode'
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note'
    },
    {
      dataIndex: 'id',
      render: renderAction
    },
  ]
  return (
    <Spin spinning={loading}>
      <Table rowKey="id" dataSource={dataSource} columns={columns} />
    </Spin>
  )
}

CreditList.propTypes = {
  dataSource: PropTypes.array,
  categoryList: PropTypes.array,
  loading: PropTypes.bool,
};

CreditList.defaultProps = {
  dataSource: [],
  categoryList: [],
  loading: false,
};

export default CreditList;
