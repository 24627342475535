import produce from 'immer';
import jwtDecode from 'jwt-decode';
import { POST_SIGN_IN_SUCCESS } from 'containers/SignIn/signin.constants';
import { removeAccent } from 'utils/helper';
import { 
  GET_PRODUCT_LIST_SUCCESS, 
  GET_PRODUCT_LIST_REQUEST, 
  GET_PRODUCT_LIST_FAILURE, 
  GET_PRODUCT_META_LIST_SUCCESS, 
  GET_PRODUCT_META_LIST_REQUEST, 
  GET_PRODUCT_META_LIST_FAILURE, 
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILURE
} from './global.constants';

export const initialState = {
  user: {},
  userList: [],
  userListLoading: false,
  productList: [],
  productListLoading: false,
  productMetaList: [],
  productMetaListLoading: false,
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case POST_SIGN_IN_SUCCESS:
        const newToken = action.payload.data.access_token;
        localStorage.setItem('token', newToken);
        draft.user = jwtDecode(newToken);
        break;
      case GET_PRODUCT_LIST_REQUEST: {
        draft.productListLoading = true;
        break;
      }
      case GET_PRODUCT_LIST_SUCCESS: {
        const productList = [];
        action.payload.forEach(p => {
          const { groupId, groupName, shortName, image } = p;
          if(productList.findIndex(x => x.id === groupId) < 0){
            productList.push({
              id: groupId,
              name: groupName,
              image,
              sku: shortName,
              queryName: removeAccent(groupName),
            })
          }
        })
        draft.productList = productList;
        draft.productListLoading = false;
        break;
      }
      case GET_PRODUCT_LIST_FAILURE: {
        draft.productListLoading = false;
        break;
      }
      case GET_USER_LIST_REQUEST: {
        draft.userListLoading = true;
        break;
      }
      case GET_USER_LIST_FAILURE: {
        draft.userListLoading = false;
        break;
      }
      case GET_USER_LIST_SUCCESS: {
        const userList = action.payload.map(x => ({
          ...x,
          userId: parseInt(x.userId, 0)
        }));
        draft.userList = userList;
        draft.userListLoading = false;
        break;
      }
      case GET_PRODUCT_META_LIST_REQUEST: {
        draft.productMetaListLoading = true;
        break;
      }
      case GET_PRODUCT_META_LIST_SUCCESS: {
        const productList = [];
        action.payload.forEach(p => {
          const { id, pName, pSku, price, image, gallery } = p;
          productList.push({
            id,
            name: pName,
            sku: pSku.toUpperCase(),
            price,
            image,
            gallery
          })
        })
        draft.productMetaList = productList;
        draft.productMetaListLoading = false;
        break;
      }
      case GET_PRODUCT_META_LIST_FAILURE: {
        draft.productMetaListLoading = false;
        break;
      }
    }
  });

export default appReducer;
