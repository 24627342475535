import BankTransfer from './BankTransfer';
import CashBook from './CashBook';
import Campaign from './Campaign/Campaign';
// import ProduceSchedule from './ProduceSchedule';
import InboxTracking from './InboxTracking';
import CampaignLive from './CampaignLive/Campaign';

const PARENT = '/support';
const routes = [
  {
    exact: true,
    path: `${PARENT}`,
    name: 'Chuyển khoản',
    icon: 'bank',
    component: BankTransfer,
    sidebar: true,
    permission: 2,
  },
  {
    exact: true,
    path: `${PARENT}/cash-book`,
    name: 'Quản lý Thu Chi',
    icon: 'bank',
    component: CashBook,
    sidebar: true,
    permission: 2,
  },
  {
    exact: true,
    path: `${PARENT}/campaign`,
    name: 'Campaign',
    icon: 'bank',
    component: Campaign,
    sidebar: true,
  },
  {
    exact: true,
    path: `${PARENT}/inbox-tracking`,
    name: 'Thống kê tin nhắn',
    icon: 'bank',
    component: InboxTracking,
    sidebar: true,
    permission: 2,
  },
  {
    exact: true,
    path: `${PARENT}/campaign-live`,
    name: 'CampaignLive',
    icon: 'bank',
    component: CampaignLive,
    sidebar: true,
  },
];

export default routes;
