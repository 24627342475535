import Payload from './Payload';
import Sale from './Sale';
import Detail from './Detail';
import CheckInHistory from 'containers/Admin/CheckInHistory';

const PARENT = '/users';
const routes = [
  {
    exact: true,
    path: `${PARENT}`,
    name: 'Tài khoản',
    icon: 'user',
    component: Detail,
    sidebar: true,
  },
  {
    exact: true,
    path: `${PARENT}/payload`,
    name: 'Bảng lương',
    icon: 'dollar',
    component: Payload,
    sidebar: true,
  },
  {
    exact: true,
    path: `${PARENT}/sales`,
    name: 'Doanh số',
    icon: 'area-chart',
    component: Sale,
    sidebar: true,
  },
  {
    exact: true,
    path: `${PARENT}/checkin-history`,
    name: 'Chấm công',
    icon: 'history',
    component: CheckInHistory,
    sidebar: true,
  },
];

export default routes;
