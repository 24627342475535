import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'react-query';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import * as htmlToImage from 'html-to-image';
import request from '@utils/request';
import { formatNumber, formatDateTime } from '@utils/helper';
import { makeSelectUserList } from '@/global.selectors';
import { Row, Col, Pagination, Spin, Button } from 'antd';
import Table from '@common/Table';

const PAGE_SIZE = 10;

function BankTransfer(props) {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const ref = {};
  const { isLoading, refetch } = useQuery(['bankTransfer', currentPage], () => {
    const limit = PAGE_SIZE;
    const offset = (currentPage - 1) * PAGE_SIZE;
    const url = `v1/bank-transfers?limit=${limit}&offset=${offset}`;
    return request.get(url);
  },
    {
      onSuccess: (response) => {
        const { count, rows } = response.data;
        rows.forEach(rec => {
          ref[rec.id] = null;
        })
        setData(rows);
        setTotal(count);
      },
    }
  )
  const onRef = (el, id) => {
    ref[id] = el;
  }
  const onChangePage = (page) => {
    console.log(page);
    setCurrentPage(page);
  }
  const handleDownload = async id => {
    const dataUrl = await htmlToImage.toJpeg(ref[id]);

    const link = document.createElement('a');
    link.download = `OTina-CK-${id}`;
    link.href = dataUrl;
    link.click();
    handleComplete(id);
  }
  const handleComplete = async id => {
    await request.put(`v1/bank-transfers/${id}`, { isDone: true });
    refetch();
  }
  const columns = [
    {
      title: 'Ngày',
      dataIndex: 'createdAt',
      render: text => formatDateTime(text * 1000),
    },
    {
      title: 'Số tiền',
      dataIndex: 'amount',
      align: 'right',
      render: formatNumber,
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: 'Nội dung',
      dataIndex: 'message',
      render: (text, record) => (<>
        <div className="bankTransfer" >
          <div className='inner' ref={r => onRef(r, record.id)}>
            <p className='date'>{formatDateTime(record.createdAt * 1000)}</p>
            <p>Số tiền: <b>{formatNumber(record.amount)}</b></p>
            <p>Nội dung{record.message}</p>
          </div>
        </div>
      </>
      )
    },
    {
      title: 'Tải hình',
      dataIndex: 'id',
      render: text => <Button icon="download" onClick={() => handleDownload(text)} />
    },
    {
      title: 'Đã xong',
      dataIndex: 'isDone',
      render: (_, record) => <Button disabled={record.isDone} onClick={() => handleComplete(record.id)}>{record.isDone ? 'Đã xong' : ' Xong'}</Button>
    },
  ]
  return (<>
    <Row gutter={16}>
      <Col sm={14}>
        <h2>Chuyển khoản <Button icon="redo" disabled={isLoading} onClick={() => refetch()} /></h2>

      </Col>
    </Row>
    <Row gutter={16} style={{ marginTop: '2rem' }}>
      <Col sm={24}>
        <Spin spinning={isLoading}>
          <Table
            className="bankTable"
            pagination={false}
            dataSource={data}
            columns={columns}
            rowKey="id" />
          <Pagination total={total} current={currentPage} pageSize={PAGE_SIZE} onChange={onChangePage} />
        </Spin>
      </Col>
    </Row>
  </>)
}

BankTransfer.propTypes = {
};

BankTransfer.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  userList: makeSelectUserList()
});

const mapDispatchToProps = dispatch => ({

});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(BankTransfer);
