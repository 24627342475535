
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import request from '@utils/request';
import { validateFields } from '@utils/helper';
import { Button, Input, Select, Form, Row, Col, Spin, Checkbox } from 'antd';
import BankList from '@common/BankList';
import './style.less';

const { Option } = Select;


function UserDetail({ user, onSave, onCancel, form }) {
  const { getFieldDecorator } = form;
  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])
  const { isLoading, mutate: saveUser } = useMutation((data) => {
    if (user.id) {
      return request.put(`v1/users/${user.id}`, data);
    }
    return request.post(`v1/users`, data);
  },
    {
      onSuccess: (response) => {
        onSave();
        form.resetFields();
      },
    });
  const handleReset = () => {
    onCancel({});
    form.resetFields();
  }
  const handleSubmit = async e => {
    e.preventDefault();
    const [err, values] = await validateFields(form);
    if (!err) {
      const postData = {
        ...values,
      }
      saveUser(postData);
    }
  }
  return (
    <Spin spinning={isLoading}>
      <Form className='UserDetail' onSubmit={handleSubmit}>
        <Row gutter={8}>
          <Col sm={12}>
            <Form.Item label="Họ tên">
              {user.name}
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item label="Loại NV">
              {getFieldDecorator('empType', {
                initialValue: user.empType || 1
              })(
                <Select>
                  <Option key={1} value={1}>Full Time</Option>
                  <Option key={2} value={2}>Part Time</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item label="Bộ phận">
              {getFieldDecorator('dept', {
                initialValue: user.dept || 1
              })(
                <Select>
                  <Option key={1} value={1}>Online</Option>
                  <Option key={2} value={2}>Offline</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col sm={6}>
            <Form.Item label="Active">
              {getFieldDecorator('active', {
                valuePropName: 'checked',
                initialValue: user.active ? true : false
              })(<Checkbox />)}
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item label="Avatar">
              {getFieldDecorator('avatar', {
                initialValue: user.avatar || ''
              })(<Input />)
              }
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item label="Ngân Hàng">
              {getFieldDecorator('bankName', {
                initialValue: user.bankName || ''
              })(<BankList />)
              }
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item label="Số TK">
              {getFieldDecorator('bankAccount', {
                initialValue: user.bankAccount || ''
              })(<Input />)
              }
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col sm={12}>
            <Form.Item label="Điện thoại">
              {getFieldDecorator('phone', {
                initialValue: user.phone || ''
              })(<Input />)
              }
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item label="Email">
              {getFieldDecorator('email', {
                initialValue: user.email || ''
              })(<Input />)
              }
            </Form.Item>
          </Col>
          <Col sm={24}>
            <Button style={{ marginTop: 28 }} type='primary' onClick={handleSubmit}>{user.id ? 'Cập nhật' : 'Lưu'}</Button>
            <Button style={{ marginTop: 28, marginLeft: 20 }} onClick={handleReset}>{user.id ? 'Đóng' : 'Xóa'}</Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  )
}

UserDetail.propTypes = {
  form: PropTypes.object,
  user: PropTypes.object,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

UserDetail.defaultProps = {
  form: {},
  user: {},
  onSave: () => { },
  onCancel: () => { }
};

export default Form.create()(UserDetail);
