export const CHECKIN_REQUEST = 'dashboard/CHECKIN_REQUEST';
export const CHECKIN_SUCCESS = 'dashboard/CHECKIN_SUCCESS';
export const CHECKIN_FAILURE = 'dashboard/CHECKIN_FAILURE';

export const CHECKOUT_REQUEST = 'dashboard/CHECKOUT_REQUEST';
export const CHECKOUT_SUCCESS = 'dashboard/CHECKOUT_SUCCESS';
export const CHECKOUT_FAILURE = 'dashboard/CHECKOUT_FAILURE';

export const GET_PLACES_REQUEST = 'dashboard/GET_PLACES_REQUEST';
export const GET_PLACES_SUCCESS = 'dashboard/GET_PLACES_SUCCESS';
export const GET_PLACES_FAILURE = 'dashboard/GET_PLACES_FAILURE';
export const GET_PLACES_LOCATION_REQUEST = 'dashboard/GET_PLACES_LOCATION_REQUEST';
export const GET_PLACES_LOCATION_SUCCESS = 'dashboard/GET_PLACES_LOCATION_SUCCESS';
export const GET_PLACES_LOCATION_FAILURE = 'dashboard/GET_PLACES_LOCATION_FAILURE';

export const GET_ONLINE_REQUEST = 'dashboard/GET_ONLINE_REQUEST';
export const GET_ONLINE_SUCCESS = 'dashboard/GET_ONLINE_SUCCESS';
export const GET_ONLINE_FAILURE = 'dashboard/GET_ONLINE_FAILURE';

export const GET_CHECKIN_HISTORY_REQUEST = 'dashboard/GET_CHECKIN_HISTORY_REQUEST';
export const GET_CHECKIN_HISTORY_SUCCESS = 'dashboard/GET_CHECKIN_HISTORY_SUCCESS';
export const GET_CHECKIN_HISTORY_FAILURE = 'dashboard/GET_CHECKIN_HISTORY_FAILURE';