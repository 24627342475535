import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Modal } from 'antd';
import OrderDetail from '../Detail';

import {
  makeSelectModalVisible,
  makeSelectModalOrderId,
  makeSelectOrderList,
} from '../order.selectors';
import { handleModalCancelAction } from '../order.actions';

class ModalDetail extends PureComponent {
  render() {
    const { modalOrderId, orderList } = this.props;
    if (!modalOrderId) {
      return null;
    }
    const order = orderList.find(x => x.id === modalOrderId);
    if (!order) return null;
    return (
      <Modal
        visible={this.props.modalVisible}
        // onOk={props.postPosts}
        // confirmLoading={props.modalLoading}
        onCancel={this.props.handleModalCancel}
        footer={null}
      >
        <OrderDetail item={order} detailMode={true} />
      </Modal>
    );
  }
}

ModalDetail.propTypes = {
  modalVisible: PropTypes.bool,
  handleModalCancel: PropTypes.func,
  modalOrderId: PropTypes,
  orderList: PropTypes.arrayOf(PropTypes.any),
};

const mapStateToProps = createStructuredSelector({
  modalVisible: makeSelectModalVisible(),
  modalOrderId: makeSelectModalOrderId(),
  orderList: makeSelectOrderList(),
});

const mapDispatchToProps = dispatch => ({
  handleModalCancel: () => dispatch(handleModalCancelAction()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(ModalDetail);
