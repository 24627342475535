
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { useMutation } from 'react-query';
import request from '@utils/request';

const {Option} = Select;

function BankList(props) {
  const [list, setList] = useState([]);

  const {  mutate: getBankList } = useMutation(() => {
    return request.get('https://api.vietqr.io/v2/banks');
  },
    {
      onSuccess: (res) => {
        setList(res.data.data);
      },
    })

  useEffect(() => {
    
    getBankList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dataSource = list.map(x => ({
    ...x, 
    name: `${x.name} - ${x.code}`
  }))
  return (
    <Select
        // onSearch={handleSearch}
        // filterOption={false}
        defaultValue=''
        size={props.size}
        style={{ width: '100%' }}
        showSearch
        onChange={props.onChange}
        mode={props.mode}
        {...props}
        >
        <Option key='0' >Chọn Ngân Hàng</Option>
        {dataSource.map(bank => <Option key={bank.shortName} value={bank.shortName}>{bank.name}</Option>)}
      </Select>
  );
}

BankList.propTypes = {
  mode: PropTypes.string,
  onChange: PropTypes.func,
  viewMode: PropTypes.string,
  value: PropTypes.string,
  valueKey: PropTypes.string,
  size: PropTypes.string,
  withOutValue: PropTypes.bool,
};

BankList.defaultProps = {
  mode: 'default',
  onChange: () => { },
  viewMode: 'select',
  value: '',
  valueKey: 'id',
  size: 'large',
  withOutValue: true
};

export default BankList;
