import { key } from './index';

export const GET_PRODUCT_REQUEST = `${key}/GET_PRODUCT_REQUEST`;
export const GET_PRODUCT_SUCCESS = `${key}/GET_PRODUCT_SUCCESS`;
export const GET_PRODUCT_FAILURE = `${key}/GET_PRODUCT_FAILURE`;

export const POST_PRODUCT_REQUEST = `${key}/POST_PRODUCT_REQUEST`;
export const POST_PRODUCT_SUCCESS = `${key}/POST_PRODUCT_SUCCESS`;
export const POST_PRODUCT_FAILURE = `${key}/POST_PRODUCT_FAILURE`;

export const ON_SELECT_PRODUCT = `${key}/ON_SELECT_PRODUCT`;

export const SIZE_COLUMN = ['M', 'L', 'XL', 'XXL', 'RRE'];