
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { makeSelectProductList, makeSelectProductListLoading } from '@/global.selectors';
import { getProductListAction } from '@/global.actions';

const { Option } = Select;
function ProductList({ productList, productListLoading, getProductList, mode, onChange, viewMode, value, valueKey, size, withOutValue }) {
  const [keyword, setKeyword] = useState('');
  useEffect(() => {
      getProductList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleSearch = kw => {
    setKeyword(kw);
  }
  const dataSource = productList.filter(x => x.name.includes(keyword) || x.queryName.includes(keyword) || x.sku.includes(keyword)).sort((a, b) => b.id - a.id);
  if (viewMode === 'select'){
    const props = {};
    if(!withOutValue){
      props.value = value;
    }
    return (
      <Select
        onSearch={handleSearch}
        filterOption={false}
        defaultValue='0'
        size={size}
        style={{ width: '100%' }}
        showSearch
        onChange={onChange}
        mode={mode}
        loading={productListLoading}
        {...props}
        >
        <Option key='0' >Chọn sản phẩm</Option>
        {dataSource.map(p => <Option id={`${p[valueKey]}-${p.sku}`} key={`${p[valueKey]}-${p.sku}`} value={p[valueKey]}>{p.name}</Option>)}
      </Select>
    );
  }
  else {
    const item = productList.find(x => x[valueKey] === value);
    if (item) return item[viewMode];
  }
  return '';
}

ProductList.propTypes = {
  mode: PropTypes.string,
  onChange: PropTypes.func,
  viewMode: PropTypes.string,
  value: PropTypes.string,
  valueKey: PropTypes.string,
  size: PropTypes.string,
  withOutValue: PropTypes.bool,
};

ProductList.defaultProps = {
  mode: 'default',
  onChange: () => { },
  viewMode: 'select',
  value: '',
  valueKey: 'id',
  size: 'large',
  withOutValue: true
};

const mapStateToProps = createStructuredSelector({
  productList: makeSelectProductList(),
  productListLoading: makeSelectProductListLoading(),
});

const mapDispatchToProps = dispatch => ({
  getProductList: () => dispatch(getProductListAction()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ProductList);

