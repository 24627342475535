import React, { useState } from 'react';
import { useMutation } from 'react-query';
import request from '@utils/request';
import { formatNumber } from '@utils/helper';
import { Row, Col, Spin } from 'antd';
import RelativeDate from '@common/RelativeDate';
import SumTable from '@common/SumTable';


function SaleMaterial() {
  const [tableData, setTableData] = useState([]);
  const { isLoading, mutate } = useMutation(({ timeRange }) => {
    const { from, to } = timeRange;
    return request.get(`v1/statistics/products?type=material&from=${from}&to=${to}`);
  },
    {
      onSuccess: (response) => {
        initData(response.data);
      },
    })
  const initData = (resData) => {
    const tableData = resData.map(x => ({
      material: x.material,
      total: parseInt(x.qty, 0)
    }));
    tableData.sort((a, b) => b.total - a.total);
    setTableData(tableData);
  }
  const onChangeDate = (type, timeRange) => {
    setTableData([]);
    mutate({ timeRange });
  }
  const columns = [
    {
      title: 'Chất liệu',
      dataIndex: 'material',
    },
    {
      title: 'Tổng',
      dataIndex: 'total',
      align: 'right',
      render: formatNumber,
      sorter: (a, b) => a.total - b.total,
    }
  ];
  const numberColumns = ['total'];
  return (<>
    <Row gutter={16}>
      <Col sm={14}>
        <h2>Sản phẩm đã bán theo chất liệu</h2>
      </Col>
      <Col sm={10}>
      <RelativeDate onChange={onChangeDate} />
      </Col>
    </Row>
    <Spin spinning={isLoading}>
      <Row gutter={16} style={{ marginTop: '2rem' }}>
        <Col sm={24}>
          <SumTable
            numberColumns={numberColumns}
            pagination={false}
            dataSource={tableData}
            columns={columns}
            rowKey="material" />
        </Col>
      </Row>
    </Spin>
  </>)
}

SaleMaterial.propTypes = {
};

SaleMaterial.defaultProps = {
};

export default SaleMaterial;
