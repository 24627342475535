import { takeLatest, call, put, select } from 'redux-saga/effects';
import { makeSelectUser } from '@/global.selectors';
import {
  getOrderSuccess,
  getOrderFailure,
  postOrderFailure,
  postOrderSuccess,
  putOrderSuccess,
  putOrderFailure,
  deleteOrderSuccess,
  deleteOrderFailure,
  getOrderItemSuccess,
  getOrderItemFailure,
  postOrderItemSuccess,
  postOrderItemFailure,
} from './order.actions';
import { GET_ORDER_REQUEST, POST_ORDER_REQUEST, PUT_ORDER_REQUEST, DELETE_ORDER_REQUEST, GET_ORDER_ITEM_REQUEST, POST_ORDER_ITEM_REQUEST } from './order.constants';
import { getOrderAPI, postOrderAPI, putOrderApi, deleteOrderApi, getOrderItemApi, postOrderItemApi } from './order.api';
import { makeSelectFilter } from './order.selectors';

export function* getOrderSaga() {
  try {
    const filter = yield select(makeSelectFilter());
    const user = yield select(makeSelectUser());
    let filterInput = { ...filter };
    if (user.role > 2) {
      filterInput.createdBy = user.id;
    }
    const orderListRes = yield call(getOrderAPI, filterInput);
    yield put(getOrderSuccess(orderListRes.data));
  } catch (error) {
    console.log(error);
    yield put(getOrderFailure(error));
  }
}

export function* postOrderSaga(action) {
  try {
    yield call(postOrderAPI, [action.payload]);
    yield put(postOrderSuccess());
    yield call(getOrderSaga);
  } catch (error) {
    yield put(postOrderFailure(error));
  }
}

export function* putOrdersSaga(action) {
  try {
    const res = yield call(putOrderApi, action.payload);
    yield put(putOrderSuccess(res.data));
  } catch (error) {
    const data = {
      id: action.payload.id,
      error,
    };
    yield put(putOrderFailure(data));
  }
}

export function* deleteOrdersSaga(action) {
  try {
    yield call(deleteOrderApi, action.payload);
    yield put(deleteOrderSuccess({ id: action.payload.id }));
  } catch (error) {
    const data = {
      id: action.payload.id,
      error,
    };
    yield put(deleteOrderFailure(data));
  }
}

export function* getOrderItemSaga(action) {
  try {
    const { id } = action.payload;
    console.log(id);
    const res = yield call(getOrderItemApi, id);
    const data = {
      id,
      list: res.data
    }
    yield put(getOrderItemSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getOrderItemFailure(error));
  }
}

export function* postOrderItemSaga(action) {
  try {
    const res = yield call(postOrderItemApi, action.payload);
    yield put(postOrderItemSuccess(res.data));
  } catch (error) {
    const data = {
      id: action.payload.id,
      error,
    };
    yield put(postOrderItemFailure(data));
  }
}

export default function* boardSaga() {
  yield takeLatest(GET_ORDER_REQUEST, getOrderSaga);
  yield takeLatest(POST_ORDER_REQUEST, postOrderSaga);
  yield takeLatest(PUT_ORDER_REQUEST, putOrdersSaga);
  yield takeLatest(DELETE_ORDER_REQUEST, deleteOrdersSaga);
  yield takeLatest(GET_ORDER_ITEM_REQUEST, getOrderItemSaga);
  yield takeLatest(POST_ORDER_ITEM_REQUEST, postOrderItemSaga);
}
