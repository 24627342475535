
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Row, Col } from 'antd';

const { Option } = Select;
const getMonthList = () => {
  const startYear = 2024;
  const startMonth = 8;
  const result = [];
  const today = new Date();
  const curYear = today.getFullYear();
  const curMonth = today.getMonth() + 1;
  for (let i = startYear; i <= curYear; i += 1) {
    let startM = startMonth; let endM = curMonth;
    if (startYear < curYear) {
      if (i === startYear) {
        endM = 12;
      } else if (i === curYear) {
        startM = 1;
      } else {
        startM = 1; endM = 12;
      }
    }
    for (let j = startM; j <= endM; j += 1) {
      result.push({
        year: i,
        month: j,
        id: i * 100 + j
      })
    }
  }
  return result.reverse();
}
const dateList = getMonthList();

function Filter({ onChange}) {
  const [dayList, setDayList] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [selectedDay, setSelectedDay] = useState(0);
  const getDayList = (anchorDay) => {
    const today = new Date();
    const lastDay = anchorDay ? anchorDay : today.getDate();
    const result = [];
    for (let i = 1; i <= lastDay; i += 1) {
      result.push(i);
    }
    return result.reverse();
  }
  const handleChangeDate = day => {
    const date = dateList.find(x => x.id === selectedMonth);
    setSelectedDay(day);
    onChange({
      year: date.year,
      month: date.month,
      day
    });
  }
  const handleChangeMonth = id => {
    const item = dateList.find(x => x.id === id);
    if (item) {
      setSelectedMonth(id);
      const {year, month} = item;
      let list;
      if(id === dateList[0].id){
        list = getDayList();
      } else {
        if([1,3,5,7,8,10,12].includes(month)){
          list = getDayList(31);
        } else {
          list = getDayList(30);
        }
      }
      const day = list[0];
      setDayList(list);
      setSelectedDay(day);
      onChange({
        year, 
        month,
        day
      });
    }
  }
  useEffect(() => {
    handleChangeMonth(dateList[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Row gutter={16}>
      <Col sm={12}>
        <Select className='filterSelect' placeholder="Tháng" onChange={handleChangeMonth} defaultValue={dateList[0].id}>
          {dateList.map(item => <Option key={item.id} value={item.id}>Tháng {item.month}/{item.year}</Option>)}
        </Select>
      </Col>
      <Col sm={12}>
        <Select className='filterSelect' placeholder="Ngày" onChange={handleChangeDate} defaultValue={dayList[0]} value={selectedDay}>
          {dayList.map(item => <Option key={item} value={item}>Ngày {item}</Option>)}
        </Select>
      </Col>
    </Row>

  )
}

Filter.propTypes = {
  onChange: PropTypes.func,
};

Filter.defaultProps = {
  onChange: () => { },
};

export default Filter;
