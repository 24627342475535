import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, Card, Input, Alert } from 'antd';
import { orderType } from '@types';
import { DISABLE_ORDER_STATUS, ORDER_STATUS_ENUM } from '../order.constants';
import './style.less';
import OrderInfo from './orderInfo';

import { onChangeFieldAction, putOrderAction, deleteOrderAction, handleModalShowAction, postOrderLogAction } from '../order.actions';

class OrderDetail extends PureComponent {
  constructor() {
    super();
    this.state = { log: '' }
  }
  handleChangeField = e => {
    const { value, dataset } = e.target;
    const { id, field } = dataset;
    const data = {
      id: parseInt(id, 0),
      fieldName: field,
      fieldValue: value,
    };
    this.props.onChangeField(data);
  };

  handleUpdate = e => {
    const { id, field } = e.target.dataset;
    const { item } = this.props;
    const { getFieldValue } = this.props.form;
    const fieldValue = getFieldValue(field);
    const itemId = parseInt(id, 0);
    if (fieldValue === '' || (fieldValue !== item[field])) {
      const data = {
        id: itemId,
      };
      data[field] = e.target.value;
      this.props.onUpdate(data);
    }
  };

  handleChangeType = id => (fieldName, checked) => {
    const data = {
      id: parseInt(id, 0),
      fieldName,
      fieldValue: checked,
    };
    this.props.onChangeField(data);
    const updateData = {
      id: parseInt(id, 0),
      [fieldName]: checked,
    };
    this.props.onUpdate(updateData);
  };

  handleCancelOrder = (id, status = ORDER_STATUS_ENUM.DELETE) => {
    const data = {
      id: parseInt(id, 0),
      fieldName: 'status',
      fieldValue: status,
    };
    this.props.onChangeField(data);
    const updateData = {
      id: parseInt(id, 0),
      status
    };
    this.props.onUpdate(updateData);
  }

  handleShowModal = id => {
    if (!this.props.detailMode) {
      this.props.onShowModal(id);
    }
  }

  handleChangeLog = e => {
    this.setState({
      log: e.target.value
    })
  }

  handleAddLog = (e) => {
    const { item } = this.props;
    const { value } = e.target;
    this.props.onPostLog({ id: item.id, log: value });
    this.setState({ log: '' })
  }

  render() {
    const { item, detailMode } = this.props;
    const { getFieldDecorator } = this.props.form;
    const disabled = item.loading || DISABLE_ORDER_STATUS.indexOf(item.status) > -1;
    return (
      <Card
        className={`orderItemArea${item.loading ? ' loading' : ''}${detailMode ? ' orderItemDetail' : ''}`}
        hoverable
        cover={<OrderInfo disabled={disabled} onChangeType={this.handleChangeType(item.id)} order={item} onDelete={this.props.onDelete} onCancel={this.handleCancelOrder} />}
        disabled={disabled}
      >
        {item.error && <Alert className="orderError" type="error" message={item.error} />}
        <Form>
          <Form.Item>
            {getFieldDecorator('orderCode', { initialValue: item.orderCode })(
              <Input
                placeholder="Mã đơn hàng"
                data-field="orderCode"
                data-id={item.id}
                onBlur={this.handleUpdate}
                disabled={disabled}
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('note', { initialValue: item.note })(
              <Input.TextArea
                placeholder="Ghi chú"
                data-field="note"
                data-id={item.id}
                onBlur={this.handleUpdate}
                disabled={disabled}
              />
            )}
          </Form.Item>
        </Form>
        <Input value={this.state.log} onChange={this.handleChangeLog} placeholder="Log - Nhấn [Enter] để lưu." onPressEnter={this.handleAddLog} />
      </Card>
    );
  }
}

OrderDetail.propTypes = {
  onChangeField: PropTypes.func,
  onUpdate: PropTypes.func,
  item: orderType,
  orgItem: orderType,
  detailMode: PropTypes.bool,
};

OrderDetail.defaultProps = {
  item: {},
  orgItem: {},
  detailMode: false,
};


const mapDispatchToProps = dispatch => ({
  onChangeField: data => dispatch(onChangeFieldAction(data)),
  onUpdate: data => dispatch(putOrderAction(data)),
  onDelete: data => dispatch(deleteOrderAction(data)),
  onShowModal: (data) => dispatch(handleModalShowAction(data)),
  onPostLog: data => dispatch(postOrderLogAction(data)),
});

const withConnect = connect(null, mapDispatchToProps);

const OrderDetailWrapper = Form.create()(OrderDetail)

export default compose(withConnect, memo)(OrderDetailWrapper);