import {
  GET_ORDER_TRACKING_REQUEST,
  GET_ORDER_TRACKING_FAILURE,
  GET_ORDER_TRACKING_SUCCESS,
  POST_ORDER_META_REQUEST,
  POST_ORDER_META_FAILURE,
  POST_ORDER_META_SUCCESS,
  ON_SELECT_ORDER
} from './orderTracking.constants';


export const getOrderTrackingAction = payload => ({ type: GET_ORDER_TRACKING_REQUEST, payload });
export const getOrderTrackingSuccess = payload => ({ type: GET_ORDER_TRACKING_SUCCESS, payload });
export const getOrderTrackingFailure = payload => ({ type: GET_ORDER_TRACKING_FAILURE, payload });

export const postOrderMetaAction = payload => ({ type: POST_ORDER_META_REQUEST, payload });
export const postOrderMetaSuccess = payload => ({ type: POST_ORDER_META_SUCCESS, payload });
export const postOrderMetaFailure = payload => ({ type: POST_ORDER_META_FAILURE, payload });

export const onSelectOrderAction = payload => ({ type: ON_SELECT_ORDER, payload });