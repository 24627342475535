import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Table as ATable } from 'antd';

const Table = ({ columns, ...rest }) => {
  const newColumns = columns.map(c => {
    return {
      ...c,
      render: (text, record) => ({
        children: typeof c.render === 'function' ? c.render(text, record) : record[c.dataIndex],
        props: { 'data-name': c.orgTitle ? c.orgTitle : c.title }
      }),
    }
  })
  return (
    <ATable columns={newColumns} {...rest} />
  );
}

Table.propTypes = {
  columns: PropTypes.array,
};

Table.defaultProps = {
  columns: [],
}

export default memo(Table);
