import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Button, Modal, Spin, notification, Row, Col, DatePicker } from 'antd';
import moment from 'moment';
import { makeSelectUser, makeSelectUserList } from '@/global.selectors';
import request from '@utils/request';
import UserInfo from '@common/UserInfo';
import { awaitWrapper } from '@utils/helper';

class CheckInDetail extends PureComponent {
  constructor() {
    super();
    this.state = {
      showModal: false,
      timestamp: 0,
      isLoading: false,
    }
  }
  handleCheckOut = async () => {
    this.setState({ isLoading: true });
    const postData = {
      longitude: 1,
      latitude: 1,
      placeId: this.props.placeId,
      createdAt: this.state.timestamp,
      type: 2,
      userId: this.props.userId,
    }
    const [err] = await awaitWrapper(request.post(`v1/admin/check-in`, postData));
    if (!err) {
      this.props.onSuccess();
      notification.success({ message: "Successfully" });
    }
    this.setState({ isLoading: false });
  }
  handleOpenModal = () => {
    this.setState({ showModal: true });
  }
  handleChange = (value) => {
    const date = value.utc().valueOf();
    this.setState({ timestamp: Math.round(date / 1000) - 7 * 3600 })
  }
  render() {
    if (this.props.user.role > 1) return '';
    const { showModal } = this.state;
    const defaultValue = moment(this.props.selectedDate, 'DD/MM/YYYY');
    return (
      <>
        <Button onClick={this.handleOpenModal}>Check Out</Button>
        {showModal &&
          <Modal title="Check Out" visible onCancel={() => this.setState({ showModal: false })} footer={null}>
            <Spin spinning={this.state.isLoading}>
              <Row gutter={16}>
                <Col sm={12}>
                  <UserInfo userId={this.props.userId} />
                </Col>
                <Col sm={12}>
                  <DatePicker defaultValue={defaultValue} showTime placeholder="Chọn ngày" onChange={this.handleChange} />
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: 10 }}>
                <Col sm={24}>
                  <Button onClick={this.handleCheckOut} type="primary">Check Out</Button>
                </Col>
              </Row>
            </Spin>
          </Modal>}
      </>
    );
  }
}

CheckInDetail.propTypes = {
  selectedDate: PropTypes.string,
  placeId: PropTypes.number,
  onSuccess: PropTypes.func,
};

CheckInDetail.defaultProps = {
  selectedDate: '',
  placeId: 1,
  onSuccess: () => { }
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  userList: makeSelectUserList(),
});

const mapDispatchToProps = dispatch => ({
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(CheckInDetail);
