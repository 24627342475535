import { takeLatest, call, put } from 'redux-saga/effects';
import { calcCrow } from 'utils/helper';
import {
  getPlacesSuccess,
  getPlacesFailure,
  getOnlineSuccess,
  getOnlineFailure,
  checkInSuccess,
  checkInFailure,
  getCheckInHistorySuccess,
  getCheckInHistoryFailure,
  getPlaceLocationAction,
  getPlaceLocationSuccess,
} from './dashboard.actions';
import { GET_ONLINE_REQUEST, GET_PLACES_REQUEST, CHECKIN_REQUEST, GET_CHECKIN_HISTORY_REQUEST } from './dashboard.constants';
import { getPlaces, getOnline, checkIn, getCheckInHistory, } from './dashboard.api';

const initLocation = (data = []) => new Promise(resolve => {
  let result = data;
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        result = data.map(place => {
          let isInPlace = false;
          if (place.type === 1) {
            isInPlace = calcCrow(latitude, longitude, place.latitude, place.longitude) < 30;
          }
          return {
            ...place,
            isInPlace
          }
        });
        return resolve(result);
      },
      () => {
        return resolve(result);
      }
    )
  } else {
    return resolve(result);
  }

});

export function* getPlacesSaga() {
  try {
    const res = yield call(getPlaces);
    yield put(getPlacesSuccess(res.data));
    yield put(getPlaceLocationAction());
    const locationData = yield call(initLocation, res.data);
    yield put(getPlaceLocationSuccess(locationData));
  } catch (error) {
    yield put(getPlacesFailure(error));
  }
}
export function* getOnlineSaga() {
  try {
    const res = yield call(getOnline);
    yield put(getOnlineSuccess(res.data));
  } catch (error) {
    yield put(getOnlineFailure(error));
  }
}

export function* getCheckInHistorySaga() {
  try {
    const res = yield call(getCheckInHistory);
    yield put(getCheckInHistorySuccess(res.data));
  } catch (error) {
    yield put(getCheckInHistoryFailure(error));
  }
}

export function* checkInSaga(action) {
  try {
    const { placeId, longitude, latitude, placeType, type } = action.payload;
    const res = yield call(checkIn, placeId, placeType, type, longitude, latitude);
    yield put(checkInSuccess(res.data));
    yield call(getOnlineSaga);
  } catch (error) {
    yield put(checkInFailure(error));
  }
}

export default function* boardSaga() {
  yield takeLatest(GET_PLACES_REQUEST, getPlacesSaga);
  yield takeLatest(GET_ONLINE_REQUEST, getOnlineSaga);
  yield takeLatest(CHECKIN_REQUEST, checkInSaga);
  yield takeLatest(GET_CHECKIN_HISTORY_REQUEST, getCheckInHistorySaga);
}
