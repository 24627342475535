import React, { useState } from 'react';
import { useQuery } from 'react-query';
import request from '@utils/request';
import { Row, Col, Spin } from 'antd';
import { formatNumber } from '@utils/helper';
import SumTable from '@common/SumTable';

function Payload(props) {
  const numberColumns = ['basicSalary', 'bonus', 'total', 'preSalary', 'forfeit'];
  const [data, setData] = useState([]);
  const { isLoading } = useQuery('payloadGroup', () => {
    return request.get(`v1/payloads/group`);
  },
    {
      onSuccess: (response) => {
        const data = response.data.map(x => ({
          ...x,
          id: `${x.year}-${x.month}`,
          total: parseInt(x.basicSalary, 0) + parseInt(x.bonus, 0),
        }))
        setData(data);
      },
    })
  const renderTotal = (text, record) => {
    const result = formatNumber(text);
    return <a href={`/admin/payload/detail?year=${record.year}&month=${record.month}`}>{result}</a>
  }
  const columns = [
    {
      dataIndex: 'year',
      title: 'Năm'
    },
    {
      dataIndex: 'month',
      title: 'Tháng'
    },
    {
      dataIndex: 'basicSalary',
      title: 'Lương cơ bản',
      align: 'right',
      render: formatNumber
    },
    {
      dataIndex: 'bonus',
      title: 'Thưởng',
      align: 'right',
      render: formatNumber
    },
    {
      dataIndex: 'total',
      title: 'Tổng',
      align: 'right',
      render: renderTotal
    },
    {
      dataIndex: 'preSalary',
      title: 'Tạm ứng',
      align: 'right',
      render: formatNumber
    },
    {
      dataIndex: 'forfeit',
      title: 'Phạt',
      align: 'right',
      render: formatNumber
    },
  ]
  return (<>
    <Row gutter={16}>
      <Col sm={14}>
        <h2>Bảng lương</h2>
      </Col>
    </Row>
    <Spin spinning={isLoading}>
      <Row gutter={16}>
        <SumTable
          dataSource={data}
          columns={columns}
          numberColumns={numberColumns}
          rowKey="id" />
      </Row>
    </Spin>
  </>)
}

Payload.propTypes = {
};

Payload.defaultProps = {
};



export default Payload;
