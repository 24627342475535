import React, { memo, PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Avatar, Dropdown, Menu, Select } from 'antd';

import { makeSelectUserList } from '@/global.selectors';
const {Option} = Select;

class UserList extends PureComponent {
  renderName = user => user.name;
  renderAvatar = user => (<Avatar src={user.avatar} />);
  renderBoth = user => (<Fragment>
    <Avatar src={user.avatar} />
    <span style={{ marginLeft: 10 }}>{user.name}</span>
  </Fragment>)
  renderSelect = () => {
    const userActiveList = this.props.userList.filter(x => x.active);
    return (<Select value={this.props.userId} onChange={this.props.onChange} style={{width: '100%'}}>
      <Option key={0} value={0}> - Nhân viên - </Option>
      {userActiveList.map(user => <Option key={user.id} value={user.id}>{user.name}</Option>)}
    </Select>)
  }
  render() {
    const { type, userId, userList, className, mode, fieldName } = this.props;
    if(type === 'select') return this.renderSelect();
    const user = userList.find(x => x[fieldName] === userId);
    if (!user) { return null; }
    const renderFn = {
      both: this.renderBoth,
      name: this.renderName,
      avatar: this.renderAvatar
    }
    const userOnlineList = userList.filter(x => x.dept === 1 && x.active);
    const menu = (
      <Menu selectedKeys={[`${userId}`]}>
        {userOnlineList.map(u => (
          <Menu.Item key={u[fieldName]}>{renderFn.name(u)}</Menu.Item>
        ))}
      </Menu>
    )
    return (<div className={className}>
      <Dropdown overlay={menu} placement="topLeft">
        {renderFn[mode](user)}
      </Dropdown>
    </div>)
  }
}

UserList.propTypes = {
  userId: PropTypes.number,
  className: PropTypes.string,
  mode: PropTypes.string,
  fieldName: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
};

UserList.defaultProps = {
  userId: 0,
  className: '',
  mode: 'both',
  fieldName: 'id',
  type: 'menu', //select
  onChange: () => {}
};

const mapStateToProps = createStructuredSelector({
  userList: makeSelectUserList(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, memo)(UserList);
