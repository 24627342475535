import React, { memo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route } from 'react-router-dom';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectUser } from 'global.selectors';
import { useInjectSaga } from '@utils/injectSaga';
import { useInjectReducer } from '@utils/injectReducer';

import Header from 'components/Header';
import PrivateRoute from 'components/PrivateRoute';
import mainRoutes from 'routes/mainRoutes';

import { getProductListAction, getUserListAction, getProductMetaListAction } from './global.actions';
import reducer from './global.reducer';
import saga from './global.saga';

import './index.less';

const key = 'global';

function App({ user, getProductList, getUserList, getProductMetaList }) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    // getProductList();
    getUserList();
    // getProductMetaList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routes = mainRoutes.filter(x => !x.permission || x.permission >= user.role)
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Helmet titleTemplate="%s - O'Tina Instore" defaultTitle="O'Tina Instore">
        <meta name="description" content="A big big tool for O'Tina Fashion" />
      </Helmet>
      <Header />
      <Layout.Content style={{ margin: '16px' }}>
        <Switch>
          {routes.map(route => (route.auth ? <PrivateRoute key={route.name} {...route} /> : <Route key={route.name} {...route} />))}
        </Switch>
      </Layout.Content>
      <Layout.Footer style={{ textAlign: 'center' }}>O'Tina Fashion</Layout.Footer>
    </Layout>
  );
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

const mapDispatchToProps = dispatch => ({
  getProductList: () => dispatch(getProductListAction()),
  getUserList: () => dispatch(getUserListAction()),
  getProductMetaList: () => dispatch(getProductMetaListAction()),
});


const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(App);
