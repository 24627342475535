import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import request from '@utils/request';
import { Row, Col } from 'antd';
import { getDate, FILTER_DATE_ENUM } from '@common/RelativeDate';
import { formatMoneyNumber } from '@utils/helper';
import Stat from './Stat';

const POS_BRANCH = {
  1: 'pos48',
  3: 'pos222',
  4: 'pos257'
}

const INIT_DATA = {
  sales: 0,
  pos: 0,
  pos48: 0,
  pos222: 0,
  pos257: 0,
  preOrderQty: '',
  orderQty: 0,
  pastOrderQty: 0,
  productQty: 0,
  oldRate: 0,
}
function Container({ type, interval }) {
  const [data, setData] = useState({ ...INIT_DATA });
  const { isLoadingQty } = useQuery(['statContainerQty', type], () => {
    const timeRange = getDate(type);
    const { from, to } = timeRange;
    return request.get(`v1/statistics/products?type=size&from=${from}&to=${to}&timeRange=month`);
  },
    {
      onSuccess: (response) => {
        initQtyData(response.data);
      },
      refetchInterval: interval > 0 ? interval : false,
    })
  const initQtyData = resData => {
    const newData = {
      ...data
    }
    newData.productQty = 0;
    resData.forEach(item => {
      const qty = parseInt(item.qty);
      newData.productQty += qty;
    });
    setData(newData);
  }

  const { isLoadingPreOrder } = useQuery(['statContainerPreOrder', type], () => {
    const timeRange = getDate(type);
    const { from, to } = timeRange;
    return request.get(`v1/statistics/sales?type=preOrder&from=${from}&to=${to}&timeRange=month`);
  },
    {
      onSuccess: (response) => {
        initPreOrderData(response.data);
      },
      refetchInterval: interval > 0 ? interval : false,
    })
  const initPreOrderData = resData => {
    const newData = {
      ...data
    }
    let total = 0, pending = 0;
    resData.forEach(item => {
      const qty = parseInt(item.qty);
      if (!item.isChange) {
        total += qty;
        if (item.status === 1) {
          pending += qty;
        }
      }
    });
    newData.preOrderQty = `${pending}/${total}`;
    setData(newData);
  }

  const { isLoading } = useQuery(['statContainer', type], () => {
    const timeRange = getDate(type);
    const { from, to } = timeRange;
    return request.get(`v1/statistics/sales?from=${from}&to=${to}&timeRange=month`);
  },
    {
      onSuccess: (response) => {
        initData(response.data);
      },
      refetchInterval: interval > 0 ? interval : false,
    })
  const initData = (resData) => {
    const newData = {
      ...INIT_DATA,
      productQty: data.productQty,
      preOrderQty: data.preOrderQty,
    }
    resData.forEach(item => {
      const { qty, pastQty, total } = item;
      newData.orderQty += parseInt(qty);
      newData.pastOrderQty += parseInt(pastQty);
      newData.sales += parseInt(total);
      if (item.source === 'Pos') {
        newData.pos += parseInt(total);
        const posBranch = POS_BRANCH[item.branchId];// item.branchId === 1 ? 'pos48' : 'pos222';
        newData[posBranch] += parseInt(total);
      }
    })
    newData.oldRate = newData.orderQty ? Math.round(newData.pastOrderQty / newData.orderQty * 100) : 0;
    setData(newData);
  }
  return (<Row gutter={16}>
    <Col xs={12} sm={3}>
      <Stat loading={isLoading} title="Doanh số" value={formatMoneyNumber(data.sales)} />
    </Col>
    <Col xs={12} sm={3}>
      <Stat loading={isLoading} title="Shop" value={formatMoneyNumber(data.pos)} />
    </Col>
    <Col xs={12} sm={3}>
      <Stat loading={isLoading} title="48 HVH" value={formatMoneyNumber(data.pos48)} />
    </Col>
    <Col xs={12} sm={3}>
      <Stat loading={isLoading} title="222 NDC" value={formatMoneyNumber(data.pos222)} />
    </Col>
    <Col xs={12} sm={3}>
      <Stat loading={isLoading} title="257 NH" value={formatMoneyNumber(data.pos257)} />
    </Col>
    <Col xs={12} sm={3}>
      <Stat loading={isLoadingPreOrder} title="Đơn chốt" value={data.preOrderQty} />
    </Col>
    <Col xs={12} sm={3}>
      <Stat loading={isLoading || isLoadingQty} title="Tổng đơn/SL" value={`${data.orderQty}/${data.productQty}`} />
    </Col>
    <Col xs={12} sm={3}>
      <Stat loading={isLoading} title="% khách cũ" value={`${data.oldRate}%`} />
    </Col>
    {/* <Col xs={12} sm={3}>
      <Stat loading={isLoadingQty} title="Số lượng SP" value={`${data.productQty}`} />
    </Col> */}
  </Row>)
}

Container.propTypes = {
  type: PropTypes.string,
  interval: PropTypes.number,
};

Container.defaultProps = {
  type: FILTER_DATE_ENUM.TODAY,
  interval: 0
};


export default memo(Container);
