
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import request from '@utils/request';
import { validateFields } from '@utils/helper';
import { Button, Input, Form, Row, Col, Spin } from 'antd';

function TrackingCategory({ form, categoryList, isLoading, onSave, onCancel }) {
  const { getFieldDecorator } = form;
  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryList])
  const { isLoading: isSaving, mutate: saveCategory } = useMutation((data) => {
    // if (item.id) {
    //   return request.put(`v1/tracking-categories/${item.id}`, data);
    // }
    return request.post(`v1/tracking-categories`, data);
  },
    {
      onSuccess: (response) => {
        onSave();
        form.resetFields();
      },
    });
  const handleReset = () => {
    onCancel({});
    form.resetFields();
  }
  const handleSubmit = async e => {
    e.preventDefault();
    const [err, values] = await validateFields(form);
    if (!err) {
      saveCategory(values);
    }
  }
  return (
    <Spin spinning={isLoading || isSaving}>
      <Form className='creditForm' onSubmit={handleSubmit}>
        <Row gutter={8}>
          <Col sm={8}>
            <Form.Item label="Thêm danh mục">
              {getFieldDecorator('name', {
                require: true
              })(<Input onPressEnter={handleSubmit} />)
              }
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item label="Ghi chú">
              {getFieldDecorator('note')(<Input onPressEnter={handleSubmit} />)
              }
            </Form.Item>
          </Col>
          <Col sm={4}>
            <Button style={{ marginTop: 28 }} type='primary' onClick={handleSubmit}>Lưu</Button>
            <Button style={{ marginTop: 28, marginLeft: 20 }} onClick={handleReset}>Xóa</Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  )
}

TrackingCategory.propTypes = {
  form: PropTypes.object,
  categoryList: PropTypes.array,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
};

TrackingCategory.defaultProps = {
  form: {},
  categoryList: [],
  onSave: () => { },
  onCancel: () => { },
  isLoading: false
};

export default Form.create()(TrackingCategory);
