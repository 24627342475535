import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useMutation } from 'react-query';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import request from '@utils/request';
import { makeSelectUser } from '@/global.selectors';
import { Row, Col, Spin, Table } from 'antd';
import { formatNumber } from '@utils/helper';

function Payload(props) {
  const [data, setData] = useState([]);
  const { isLoading, mutate: getPayload } = useMutation(() => {
    const { user } = props;
    return request.get(`v1/payloads?userId=${user.id}`);
  },
    {
      onSuccess: (response) => {
        setData(response.data.reverse());
      },
    })
  useEffect(() => {
    getPayload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderIncome = (_, record) => {
    const income = record.basicSalary + record.bonus;
    return formatNumber(income);
  }
  const renderPayment = (_, record) => {
    const payment = record.basicSalary + record.bonus - record.forfeit - record.preSalary;
    return formatNumber(payment);
  }
  const columns = [
    {
      dataIndex: 'year',
      title: 'Năm',
    },
    {
      dataIndex: 'month',
      title: 'Tháng',
    },
    {
      dataIndex: 'day',
      title: 'Ngày công',
    },
    {
      dataIndex: 'hour',
      title: 'Giờ công',
    },
    {
      dataIndex: 'basicSalary',
      title: 'Lương căn bản',
      render: formatNumber
    },
    {
      dataIndex: 'sales',
      title: 'Doanh số',
      render: formatNumber
    },
    {
      dataIndex: 'bonus',
      title: 'Thưởng',
      render: formatNumber
    },
    {
      title: 'Thu nhập',
      render: renderIncome,
    },
    {
      dataIndex: 'preSalary',
      title: 'Tạm ứng',
      render: formatNumber
    },
    {
      dataIndex: 'forfeit',
      title: 'Phạt',
      render: formatNumber
    },
    {
      title: 'Thực lãnh',
      render: renderPayment,
    },
    {
      dataIndex: 'note',
      title: 'Ghi chú',
    },
  ]
  return (<>
    <Row gutter={16}>
      <Col sm={14}>
        <h2>Bảng lương</h2>
      </Col>
    </Row>
    <Spin spinning={isLoading}>
      <Table dataSource={data} columns={columns} rowKey="id" pagination={false} />
    </Spin>
  </>)
}

Payload.propTypes = {
};

Payload.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser()
});

const mapDispatchToProps = dispatch => ({

});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Payload);
