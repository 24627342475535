import produce from 'immer';
import { get } from 'lodash';
import {
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILURE,
  HANDLE_MODAL_SHOW,
  HANDLE_MODAL_CANCEL,
  POST_ORDER_REQUEST,
  POST_ORDER_SUCCESS,
  POST_ORDER_FAILURE,
  PUT_ORDER_REQUEST,
  PUT_ORDER_SUCCESS,
  PUT_ORDER_FAILURE,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
  ON_CHANGE_FIELD,
  ON_CHANGE_FILTER,
  ORDER_TYPE_ENUM,
  FILTER_DATE_ENUM,
  ON_CHANGE_ADD_PHOTO,
  ON_CHANGE_DEL_PHOTO,
  ORDER_STATUS_ENUM,
  UPDATE_STATUS_ORDER_REQUEST,
  UPDATE_STATUS_ORDER_FAILURE,
  POST_ORDER_LOG_REQUEST,
  POST_ORDER_LOG_SUCCESS,
  POST_ORDER_LOG_FAILURE,
} from './order.constants';

export const initialState = {
  orderList: [],
  orderListLoading: false,
  orgOrderList: [],
  orderType: {
    [ORDER_TYPE_ENUM.ALL]: 0,
    [ORDER_TYPE_ENUM.NORMAL]: 0,
    [ORDER_TYPE_ENUM.URGENT]: 0,
    [ORDER_TYPE_ENUM.CHANGE]: 0,
  },
  filter: {
    date: { type: 'relative', value: FILTER_DATE_ENUM.ALL },
    note: '',
    orderCode: '',
    status: ORDER_STATUS_ENUM.PENDING,
    createdBy: 0,
  },
  postForm: {
    photo: [],
  },
  postLoading: false,
  postError: false,
  modalVisible: false,
  modalOrderId: 0,
  updateStatusLoading: false
};

/* eslint-disable default-case, no-param-reassign */
const orderReducer = (state = initialState, action) =>
  produce(state, draft => {
    const { orderList, orgOrderList } = draft;
    switch (action.type) {
      case GET_ORDER_REQUEST: {
        draft.orderListLoading = true;
        break;
      }
      case GET_ORDER_SUCCESS: {
        const data = action.payload;
        const orderType = {
          ...initialState.orderType,
        };
        orderType[ORDER_TYPE_ENUM.ALL] = data.length;
        orderType[ORDER_TYPE_ENUM.NORMAL] = data.filter(x => !x.isUrgent && !x.isChange).length;
        orderType[ORDER_TYPE_ENUM.URGENT] = data.filter(x => x.isUrgent).length;
        orderType[ORDER_TYPE_ENUM.CHANGE] = data.filter(x => x.isChange).length;
        draft.orderList = data;
        draft.orgOrderList = data;
        draft.orderType = orderType;
        draft.orderListLoading = false;
        break;
      }
      case GET_ORDER_FAILURE: {
        draft.orderListLoading = false;
        break;
      }
      case POST_ORDER_REQUEST: {
        draft.postLoading = true;
        break;
      }
      case POST_ORDER_SUCCESS: {
        draft.postLoading = false;
        draft.postForm = {
          photo: [],
        };
        break;
      }
      case POST_ORDER_FAILURE: {
        draft.postLoading = false;
        break;
      }
      case HANDLE_MODAL_SHOW: {
        draft.modalVisible = true;
        draft.modalOrderId = action.payload;
        break;
      }
      case HANDLE_MODAL_CANCEL: {
        draft.modalVisible = false;
        break;
      }

      case PUT_ORDER_REQUEST: {
        const { id } = action.payload;
        const ind = orderList.findIndex(x => x.id === id);
        if (ind > -1) {
          const item = orderList[ind];
          item.loading = true;
          delete item.error;
          draft.orderList = [...orderList.slice(0, ind), { ...item }, ...orderList.slice(ind + 1)];
        }
        break;
      }
      case PUT_ORDER_SUCCESS: {
        const order = action.payload;
        const ind = orderList.findIndex(x => x.id === order.id);
        if (ind > -1) {
          draft.orderList = [...orderList.slice(0, ind), { ...order }, ...orderList.slice(ind + 1)];
        }
        const orgInd = orgOrderList.findIndex(x => x.id === order.id);
        if (orgInd > -1) {
          draft.orgOrderList = [...orgOrderList.slice(0, orgInd), { ...order }, ...orgOrderList.slice(orgInd + 1)];
        }
        break;
      }
      case PUT_ORDER_FAILURE: {
        const { id, error } = action.payload;
        const errorMessage = get(error, 'response.data.error', error.toString());
        const ind = orderList.findIndex(x => x.id === id);
        if (ind > -1) {
          const item = orderList[ind];
          delete item.loading;
          item.error = errorMessage;
          draft.orderList = [...orderList.slice(0, ind), { ...item }, ...orderList.slice(ind + 1)];
        }
        break;
      }
      case DELETE_ORDER_REQUEST: {
        const { id } = action.payload;
        const ind = orderList.findIndex(x => x.id === id);
        if (ind > -1) {
          const item = orderList[ind];
          item.loading = true;
          delete item.error;
          draft.orderList = [...orderList.slice(0, ind), { ...item }, ...orderList.slice(ind + 1)];
        }
        break;
      }
      case DELETE_ORDER_SUCCESS: {
        const { id } = action.payload;
        const ind = orderList.findIndex(x => x.id === id);
        if (ind > -1) {
          draft.orderList = [...orderList.slice(0, ind), ...orderList.slice(ind + 1)];
        }
        break;
      }
      case DELETE_ORDER_FAILURE: {
        const { id, error } = action.payload;
        const errorMessage = get(error, 'response.data.error', error.toString());
        const ind = orderList.findIndex(x => x.id === id);
        if (ind > -1) {
          const item = orderList[ind];
          delete item.loading;
          item.error = errorMessage;
          draft.orderList = [...orderList.slice(0, ind), { ...item }, ...orderList.slice(ind + 1)];
        }
        break;
      }

      case ON_CHANGE_FIELD: {
        const { fieldName, fieldValue } = action.payload;
        const itemInd = orderList.findIndex(x => x.id === action.payload.id);
        if (itemInd > -1) {
          const item = orderList[itemInd];
          item[fieldName] = fieldValue;
          draft.orderList = [...orderList.slice(0, itemInd), { ...item }, ...orderList.slice(itemInd + 1)];
        }
        break;
      }
      case ON_CHANGE_FILTER: {
        const { fieldName, fieldValue } = action.payload;
        let filterValue = fieldValue;
        if (fieldName === 'date') {
          const [type, value] = fieldValue.split(',');
          filterValue = { type, value };
        }
        draft.filter[fieldName] = filterValue;
        break;
      }
      case ON_CHANGE_ADD_PHOTO: {
        draft.postForm.photo = [action.payload];
        break;
      }
      case ON_CHANGE_DEL_PHOTO: {
        draft.postForm.photo = [];
        break;
      }
      case UPDATE_STATUS_ORDER_REQUEST: {
        draft.updateStatusLoading = true;
        break;
      }
      case UPDATE_STATUS_ORDER_FAILURE: {
        draft.updateStatusLoading = false;
        break;
      }
      case POST_ORDER_LOG_REQUEST: {
        const { id } = action.payload
        const ind = orderList.findIndex(x => x.id === id);
        if (ind > -1) {
          const item = orderList[ind];
          item.logLoading = true;
          item.logError = false;
          draft.orderList = [...orderList.slice(0, ind), { ...item }, ...orderList.slice(ind + 1)];
        }
        break;
      }
      case POST_ORDER_LOG_SUCCESS: {
        const order = action.payload
        const ind = orderList.findIndex(x => x.id === order.id);
        if (ind > -1) {
          draft.orderList = [...orderList.slice(0, ind), { ...order }, ...orderList.slice(ind + 1)];
        }
        break;
      }
      case POST_ORDER_LOG_FAILURE: {
        const { id, error } = action.payload
        const ind = orderList.findIndex(x => x.id === id);
        if (ind > -1) {
          const item = orderList[ind];
          item.logLoading = false;
          item.logError = error;
          draft.orderList = [...orderList.slice(0, ind), { ...item }, ...orderList.slice(ind + 1)];
        }
        break;
      }
    }
  });

export default orderReducer;
