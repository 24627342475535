import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { removeAccent } from '@utils/helper';

const getFirstChar = (str = '') => {
  if (!str) return '';
  return str[0].toUpperCase();
}

const getName = (name = '') => {
  const names = removeAccent(name).split(' ');
  let result = names[names.length - 1];
  for (let i = 0; i < names.length - 1; i++) {
    result += getFirstChar(names[i]);
  }
  return result;
}

class BankQR extends PureComponent {
  handleOk = () => {
    this.props.onOk();
    this.props.onCancel();
  }
  render() {
    const { bankName, bankAccount, amount, reason, name, visible, onCancel } = this.props;
    const info = `${reason} - ${getName(name)}`
    const bankImage = `https://img.vietqr.io/image/${bankName}-${bankAccount}-compact2.jpg?amount=${amount}&addInfo=${info}&accountName=OTina`;
    return (
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={this.handleOk}
      >
        <p>{name}</p>
        <p><img alt={info} width={300} src={bankImage} /></p>
      </Modal>
    )
  }
}

BankQR.propTypes = {
  bankName: PropTypes.string,
  bankAccount: PropTypes.string,
  reason: PropTypes.string,
  name: PropTypes.string,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showFooter: PropTypes.bool,
};

BankQR.defaultProps = {
  bankName: '',
  bankAccount: '',
  reason: '',
  name: '',
  visible: false,
  onCancel: () => { },
  onOk: () => { },
  amount: 0,
  showFooter: true
};

export default BankQR;
