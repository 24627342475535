import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Card, Input, Alert } from 'antd';
import { orderType } from '@types';
import { DISABLE_ORDER_STATUS, ORDER_STATUS_ENUM } from '../order.constants';
import './style.less';
import OrderInfo from './orderInfo';

import { onChangeFieldAction, putOrderAction, deleteOrderAction, handleModalShowAction, postOrderItemAction } from '../order.actions';

class OrderDetail extends PureComponent {
  constructor() {
    super();
    this.state = { log: '' }
  }
  handleChangeField = e => {
    const { value, dataset } = e.target;
    const { id, field } = dataset;
    const data = {
      id: parseInt(id, 0),
      fieldName: field,
      fieldValue: value,
    };
    this.props.onChangeField(data);
  };

  handleUpdate = e => {
    const { id, field } = e.target.dataset;
    const { orgItem } = this.props;
    const itemId = parseInt(id, 0);
    if (e.target.value === '' || (orgItem && orgItem[field] !== e.target.value)) {
      const data = {
        id: itemId,
      };
      data[field] = e.target.value;
      this.props.onUpdate(data);
    }
  };

  handleChangeType = id => (fieldName, checked) => {
    const data = {
      id: parseInt(id, 0),
      fieldName,
      fieldValue: checked,
    };
    this.props.onChangeField(data);
    const updateData = {
      id: parseInt(id, 0),
      [fieldName]: checked,
    };
    this.props.onUpdate(updateData);
  };

  handleCancelOrder = id => {
    const data = {
      id: parseInt(id, 0),
      fieldName: 'status',
      fieldValue: ORDER_STATUS_ENUM.DELETE,
    };
    this.props.onChangeField(data);
    const updateData = {
      id: parseInt(id, 0),
      status: ORDER_STATUS_ENUM.DELETE,
    };
    this.props.onUpdate(updateData);
  }

  handleDoneOrder = id => {
    const data = {
      id: parseInt(id, 0),
      fieldName: 'status',
      fieldValue: ORDER_STATUS_ENUM.REVIEW,
    };
    this.props.onChangeField(data);
    const updateData = {
      id: parseInt(id, 0),
      status: ORDER_STATUS_ENUM.REVIEW,
    };
    this.props.onUpdate(updateData);
  }

  handleShowModal = id => {
    if (!this.props.detailMode) {
      this.props.onShowModal(id);
    }
  }

  handleChangeLog = e => {
    this.setState({
      log: e.target.value
    })
  }

  handleAddLog = (e) => {
    const { item } = this.props;
    const { value } = e.target;
    this.props.onPostItem({ id: item.id, note: value });
    this.setState({ log: '' })
  }

  render() {
    const { item, detailMode } = this.props;
    const disabled = item.loading || DISABLE_ORDER_STATUS.indexOf(item.status) > -1;
    return (
      <Card
        className={`processOrderItemArea${item.loading ? ' loading' : ''}${detailMode ? ' orderItemDetail' : ''}`}
        hoverable
        cover={<OrderInfo disabled={disabled} onChangeType={this.handleChangeType(item.id)} order={item} onDelete={this.props.onDelete} onCancel={this.handleCancelOrder} onDone={this.handleDoneOrder} />}
        disabled={disabled}
      // onClick={e => this.handleShowModal(item.id)}
      >
        {item.error && <Alert className="orderError" type="error" message={item.error} />}
        <Input.TextArea
          placeholder="Ghi chú"
          data-field="note"
          data-id={item.id}
          onBlur={this.handleUpdate}
          value={item.note}
          disabled={disabled}
          onChange={this.handleChangeField}
        />
        <Input value={this.state.log} onChange={this.handleChangeLog} placeholder="Log" onPressEnter={this.handleAddLog} />
      </Card>
    );
  }
}

OrderDetail.propTypes = {
  onChangeField: PropTypes.func,
  onUpdate: PropTypes.func,
  item: orderType,
  orgItem: orderType,
  detailMode: PropTypes.bool,
};

OrderDetail.defaultProps = {
  item: {},
  orgItem: {},
  detailMode: false,
};

// const mapStateToProps = createStructuredSelector({
//   orderList: makeSelectOrderList(),
//   orgOrderList: makeSelectOrgOrderList(),
// });

const mapDispatchToProps = dispatch => ({
  onChangeField: data => dispatch(onChangeFieldAction(data)),
  onUpdate: data => dispatch(putOrderAction(data)),
  onDelete: data => dispatch(deleteOrderAction(data)),
  onShowModal: (data) => dispatch(handleModalShowAction(data)),
  onPostItem: data => dispatch(postOrderItemAction(data)),
});

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(OrderDetail);
