import {
  GET_CASH_INCOME_REQUEST,
  GET_CASH_INCOME_FAILURE,
  GET_CASH_INCOME_SUCCESS,
  ON_CHANGE_FILTER,
  ON_CHANGE_DATE_FILTER,
} from './cash.constants';

export const getCashIncomeAction = payload => ({ type: GET_CASH_INCOME_REQUEST, payload });
export const getCashIncomeSuccess = payload => ({ type: GET_CASH_INCOME_SUCCESS, payload });
export const getCashIncomeFailure = payload => ({ type: GET_CASH_INCOME_FAILURE, payload });

export const onChangeFilterAction = payload => ({ type: ON_CHANGE_FILTER, payload });
export const onChangeDateFilterAction = payload => ({ type: ON_CHANGE_DATE_FILTER, payload });
