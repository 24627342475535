import { takeLatest, call, put } from 'redux-saga/effects';
import {
  getProductSuccess,
  getProductFailure,
  postProductSuccess,
  postProductFailure,
} from './actions';
import { GET_PRODUCT_REQUEST, POST_PRODUCT_REQUEST } from './constants';
import { getProductApi, postProductApi } from './api';


export function* getProductSaga() {
  try {
    const res = yield call(getProductApi);
    yield put(getProductSuccess(res.data));
  } catch (error) {
    yield put(getProductFailure(error));
  }
}
export function* postProductSaga(action) {
  try {
    const res = yield call(postProductApi, action.payload);
    yield put(postProductSuccess(res.data));
  } catch (error) {
    yield put(postProductFailure(error));
  }
}

export default function* boardSaga() {
  yield takeLatest(GET_PRODUCT_REQUEST, getProductSaga);
  yield takeLatest(POST_PRODUCT_REQUEST, postProductSaga);
}
