import { createSelector } from 'reselect';
import { initialState } from './order.reducer';

const selectBoardDomain = state => state.processOrder || initialState;

const makeSelectOrderList = () => createSelector(selectBoardDomain, substate => substate.orderList);
const makeSelectOrgOrderList = () => createSelector(selectBoardDomain, substate => substate.orgOrderList);
const makeSelectOrderType = () => createSelector(selectBoardDomain, substate => substate.orderType);

const makeSelectModalVisible = () => createSelector(selectBoardDomain, substate => substate.modalVisible);
const makeSelectModalOrderId = () => createSelector(selectBoardDomain, substate => substate.modalOrderId);

const makeSelectFilter = () => createSelector(selectBoardDomain, substate => substate.filter);
const makeSelectPhoto = () => createSelector(selectBoardDomain, substate => substate.postForm.photo);
const makeSelectPostLoading = () => createSelector(selectBoardDomain, substate => substate.postLoading);

export {
  selectBoardDomain,
  makeSelectOrderList,
  makeSelectOrgOrderList,
  makeSelectOrderType,
  makeSelectModalVisible,
  makeSelectFilter,
  makeSelectPhoto,
  makeSelectPostLoading,
  makeSelectModalOrderId,
};
