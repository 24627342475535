import {
  GET_ORDER_REQUEST,
  GET_ORDER_FAILURE,
  GET_ORDER_SUCCESS,
  POST_ORDER_REQUEST,
  POST_ORDER_SUCCESS,
  POST_ORDER_FAILURE,
  PUT_ORDER_REQUEST,
  PUT_ORDER_SUCCESS,
  PUT_ORDER_FAILURE,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
  HANDLE_MODAL_SHOW,
  HANDLE_MODAL_CANCEL,
  ON_CHANGE_FIELD,
  ON_CHANGE_FILTER,
  ON_CHANGE_ADD_PHOTO,
  ON_CHANGE_DEL_PHOTO,
  UPDATE_STATUS_ORDER_REQUEST,
  UPDATE_STATUS_ORDER_SUCCESS,
  UPDATE_STATUS_ORDER_FAILURE,
  POST_ORDER_LOG_REQUEST,
  POST_ORDER_LOG_SUCCESS,
  POST_ORDER_LOG_FAILURE,
} from './order.constants';

export const getOrderAction = payload => ({ type: GET_ORDER_REQUEST, payload });
export const getOrderSuccess = payload => ({ type: GET_ORDER_SUCCESS, payload });
export const getOrderFailure = payload => ({ type: GET_ORDER_FAILURE, payload });

export const postOrderAction = payload => ({ type: POST_ORDER_REQUEST, payload });
export const postOrderSuccess = payload => ({ type: POST_ORDER_SUCCESS, payload });
export const postOrderFailure = payload => ({ type: POST_ORDER_FAILURE, payload });

export const putOrderAction = payload => ({ type: PUT_ORDER_REQUEST, payload });
export const putOrderSuccess = payload => ({ type: PUT_ORDER_SUCCESS, payload });
export const putOrderFailure = payload => ({ type: PUT_ORDER_FAILURE, payload });

export const handleModalShowAction = payload => ({ type: HANDLE_MODAL_SHOW, payload });
export const handleModalCancelAction = payload => ({ type: HANDLE_MODAL_CANCEL, payload });

export const onChangeFieldAction = payload => ({ type: ON_CHANGE_FIELD, payload });
export const onChangeFilterAction = payload => ({ type: ON_CHANGE_FILTER, payload });

export const onChangeAddPhotoAction = payload => ({ type: ON_CHANGE_ADD_PHOTO, payload });
export const onChangeDelPhotoAction = payload => ({ type: ON_CHANGE_DEL_PHOTO, payload });

export const deleteOrderAction = payload => ({ type: DELETE_ORDER_REQUEST, payload });
export const deleteOrderSuccess = payload => ({ type: DELETE_ORDER_SUCCESS, payload });
export const deleteOrderFailure = payload => ({ type: DELETE_ORDER_FAILURE, payload });

export const updateStatusOrderAction = payload => ({ type: UPDATE_STATUS_ORDER_REQUEST, payload });
export const updateStatusOrderSuccess = payload => ({ type: UPDATE_STATUS_ORDER_SUCCESS, payload });
export const updateStatusOrderFailure = payload => ({ type: UPDATE_STATUS_ORDER_FAILURE, payload });

export const postOrderLogAction = payload => ({ type: POST_ORDER_LOG_REQUEST, payload });
export const postOrderLogSuccess = payload => ({ type: POST_ORDER_LOG_SUCCESS, payload });
export const postOrderLogFailure = payload => ({ type: POST_ORDER_LOG_FAILURE, payload });