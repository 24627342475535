import React from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { makeSelectUser } from '@/global.selectors';
import { FILTER_DATE_ENUM } from '@common/RelativeDate';
import Container from './Container';

import './style.less';

function Home({ user }) {
  const showYear = user.role === 1 && user.dept === 0;
  return (<>
    <Row gutter={16} className="statTitle">
      <Col sm={24}>
        <h2>Tổng Hợp</h2>
      </Col>
    </Row>
    <div className="statContainer">
      <h4>Hôm nay</h4>
      <Container type={FILTER_DATE_ENUM.TODAY} interval={60000} />
    </div>
    <div className="statContainer">
      <h4>Hôm qua</h4>
      <Container type={FILTER_DATE_ENUM.YESTERDAY} />
    </div>
    <div className="statContainer">
      <h4>Tháng này</h4>
      <Container type={FILTER_DATE_ENUM.THISMONTH} interval={60000} />
    </div>
    <div className="statContainer">
      <h4>Tháng trước</h4>
      <Container type={FILTER_DATE_ENUM.LASTMONTH} />
    </div>
    {showYear && (
      <>
        <div className="statContainer">
          <h4>Năm nay</h4>
          <Container type={FILTER_DATE_ENUM.THISYEAR} />
        </div>
        {/* <div className="statContainer">
          <h4>Năm trước</h4>
          <Container type={FILTER_DATE_ENUM.LASTYEAR} />
        </div> */}
      </>
    )}
  </>)
}

Home.propTypes = {
};

Home.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser()
});

const mapDispatchToProps = dispatch => ({

});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Home);
