import produce from 'immer';
import {
  GET_PLACES_REQUEST,
  GET_PLACES_SUCCESS,
  GET_PLACES_FAILURE,
  GET_ONLINE_SUCCESS,
  CHECKIN_REQUEST,
  CHECKIN_FAILURE,
  CHECKIN_SUCCESS,
  CHECKOUT_REQUEST,
  CHECKOUT_FAILURE,
  CHECKOUT_SUCCESS,
  GET_CHECKIN_HISTORY_REQUEST,
  GET_CHECKIN_HISTORY_SUCCESS,
  GET_CHECKIN_HISTORY_FAILURE,
  GET_PLACES_LOCATION_REQUEST,
  GET_PLACES_LOCATION_SUCCESS,
} from './dashboard.constants';
import { formatDate } from 'utils/helper';

export const initialState = {
  places: [],
  placeLoading: false,
  onlineList: [],
  onlineListLoading: false,
  checkInLoading: false,
  checkInHistory: [],
  checkInHistoryLoading: false,
  placeLocationLoading: false,
};

/* eslint-disable default-case, no-param-reassign */
const dashboardReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_PLACES_REQUEST: {
        draft.placeLoading = true;
        break;
      }
      case GET_PLACES_SUCCESS: {
        const data = action.payload;
        draft.places = data;
        draft.placeLoading = false;
        break;
      }
      case GET_PLACES_FAILURE: {
        draft.placeLoading = false;
        break;
      }
      case GET_PLACES_LOCATION_REQUEST: {
        draft.placeLocationLoading = true;
        break;
      }
      case GET_PLACES_LOCATION_SUCCESS: {
        const data = action.payload;
        draft.places = data;
        draft.placeLocationLoading = false;
        break;
      }
      case GET_ONLINE_SUCCESS: {
        const data = action.payload;
        draft.onlineList = data;
        break;
      }
      case CHECKIN_REQUEST:
      case CHECKOUT_REQUEST:
        draft.checkInLoading = true;
        break;
      case CHECKIN_SUCCESS:
      case CHECKIN_FAILURE:
      case CHECKOUT_SUCCESS:
      case CHECKOUT_FAILURE:
        draft.checkInLoading = false;
        break;
      case GET_CHECKIN_HISTORY_REQUEST: {
        draft.checkInHistoryLoading = true;
        break;
      }
      case GET_CHECKIN_HISTORY_SUCCESS: {
        const data = action.payload;
        const list = [];
        data.forEach(item => {
          const { id, isInPlace, userId, placeId, type, createdAt } = item;
          const createdDate = formatDate(createdAt * 1000);
          const ind = list.findLastIndex(x => x.placeId === placeId && x.userId === userId);
          const newItem = {
            id,
            userId,
            placeId,
            createdDate,
            startInPlace: isInPlace,
            start: createdAt
          }
          if (ind < 0) {
            list.push(newItem)
          } else {
            if (list[ind].createdDate !== createdDate) {
              list.push(newItem)
            } else if (type === 2) {
              list[ind].end = createdAt;
              list[ind].endInPlace = isInPlace;
            }
          }
        })
        list.reverse();
        draft.checkInHistory = list;
        draft.checkInHistoryLoading = false;
        break;
      }
      case GET_CHECKIN_HISTORY_FAILURE: {
        draft.checkInHistoryLoading = false;
      }
    }
  });

export default dashboardReducer;
