import React, { memo, PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Form, Card, Input, Button } from 'antd';
import request from '@utils/request';
import { makeSelectUser } from '@/global.selectors';
import { validateFields, awaitWrapper } from '@utils/helper';
import BankList from '@common/BankList';

class UserDetail extends PureComponent {
  constructor() {
    super();
    this.state = {
      loading: false,
    }
  }
  handleSubmit = async (e) => {
    e.preventDefault();
    const [err, values] = await validateFields(this.props.form);
    if (!err) {
      this.setState({ loading: true });
      const [err] = await awaitWrapper(request.put(`v1/users/${this.props.user.id}`, values));
      this.setState({ loading: false });
      if (!err) {
        window.location.reload();
      }
    }
  }

  render() {
    const { user, form } = this.props;
    const { getFieldDecorator } = form;
    const { loading } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return (
      <>
        <Card
          hoverable
          title='Thông tin tài khoản'
          disabled={loading}
          actions={[
            <Button loading={loading} icon="save" onClick={this.handleSubmit}>Cập nhật</Button>,
          ]}
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          <Form {...formItemLayout}>
            <Form.Item label="Tên nhân viên">
              {getFieldDecorator('name', { initialValue: user.name })(
                <Input
                  placeholder="Tên nhân viên"
                />
              )}
            </Form.Item>
            <Form.Item label="Ngân hàng">
              {getFieldDecorator('bankName', { initialValue: user.bankName })(
                <BankList />
              )}
            </Form.Item>
            <Form.Item label="Tài khoản">
              {getFieldDecorator('bankAccount', { initialValue: user.bankAccount })(
                <Input
                  placeholder="Tài khoản"
                />
              )}
            </Form.Item>
            <Form.Item label="Điện thoại">
              {getFieldDecorator('phone', { initialValue: user.phone })(
                <Input
                  placeholder="Điện thoại"
                />
              )}
            </Form.Item>
            <Form.Item label="Email">
              {getFieldDecorator('email', { initialValue: user.email })(
                <Input
                  placeholder="Email"
                />
              )}
            </Form.Item>
          </Form>
        </Card>
      </>
    );
  }
}

UserDetail.propTypes = {
};

UserDetail.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser()
});
const mapDispatchToProps = dispatch => ({
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const UserDetailWrapper = Form.create()(UserDetail)

export default compose(withConnect, memo)(UserDetailWrapper);