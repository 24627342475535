import { takeLatest, call, put, all } from 'redux-saga/effects';
import { uid } from '@utils/helper';
import { PLACE_LIST } from '@utils/constants';
import {
  getProductSuccess,
  getProductFailure,
  postProductSuccess,
  postProductFailure,
  onSelectProductAction,
} from './actions';
import { GET_PRODUCT_REQUEST, POST_PRODUCT_REQUEST, FULL_SIZE_COLUMN, FULL_SIZE } from './constants';
import { getProductApi, getProductQuantityApi, postProductApi } from './api';


export function* getProductSaga() {
  try {
    const res = yield all([
      call(getProductApi),
      call(getProductQuantityApi)
    ]);
    const productQuantityList = res[1].data;
    const productList = res[0].data;
    const data = [];
    productList.forEach(pm => {
      const productQuantities = productQuantityList.filter(x => x.groupId === pm.pId);
      const initBranch = {};
      FULL_SIZE_COLUMN.forEach(size => {
        initBranch[`${size}`] = 0;
        PLACE_LIST.forEach(branch => {
          initBranch[`branch${branch.value}${size}`] = 0;
        })
      });
      const result = {
        ...pm,
        uid: uid(),
        ...initBranch,
      }

      const customProductSize = productQuantities.filter(x => !FULL_SIZE.includes(`size${x.size}`));
      const customResult = {};
      customProductSize.forEach(x => {
        if (!customResult[x.size]) {
          customResult[x.size] = {
            ...pm,
            pName: x.name,
            uid: uid(),
            ...initBranch,
          }
        }
        customResult[x.size][`sizeM`] += x.available > 0 ? x.available : 0;
        customResult[x.size][`branch${x.branchId}sizeM`] = x.available;
      })
      Object.keys(customResult).forEach(key => {
        data.push({
          ...customResult[key],
        })
      })
      const fullSizeProduct = productQuantities.filter(x => FULL_SIZE.includes(`size${x.size}`));
      fullSizeProduct.forEach(pq => {
        const { branchId } = pq;
        let size = pq.size;
        if (size === 'X') size = 'XL';
        else if (size === 'XX') size = 'XXL';
        result[`size${size}`] += pq.available > 0 ? pq.available : 0;
        result[`branch${branchId}size${size}`] = pq.available;
      });
      if (fullSizeProduct.length) {
        data.push(result);
      }
      // result.pName = `${recasult.pName}`;

    })
    yield put(getProductSuccess(data));
  } catch (error) {
    yield put(getProductFailure(error));
  }
}
export function* postProductSaga(action) {
  try {
    const res = yield call(postProductApi, action.payload);
    yield put(postProductSuccess(res.data));
    yield put(onSelectProductAction({}));
  } catch (error) {
    yield put(postProductFailure(error));
  }
}

export default function* boardSaga() {
  yield takeLatest(GET_PRODUCT_REQUEST, getProductSaga);
  yield takeLatest(POST_PRODUCT_REQUEST, postProductSaga);
}
