import produce from 'immer';
import {
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_FAILURE,
  POST_PRODUCT_SUCCESS,
  POST_PRODUCT_REQUEST,
  POST_PRODUCT_FAILURE,
} from './constants';
import { parseJson } from '@utils/helper';

const DEFAULT_FILTER = {
  name: ''
}

export const initialState = {
  list: [],
  loading: false,
  postLoading: false,
  selectedProduct: {},
  filter: { ...DEFAULT_FILTER }
};

const parseMeta = item => {
  const { metaInfo, ...rest } = item;
  return {
    ...rest,
    metaInfo: parseJson(metaInfo, {})
  }
}

const initData = list => {
  return list.map(parseMeta);
}

/* eslint-disable default-case, no-param-reassign */
const mainReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_PRODUCT_REQUEST: {
        draft.loading = true;
        break;
      }
      case GET_PRODUCT_SUCCESS: {
        const data = action.payload;
        draft.list = initData(data);
        draft.loading = false;
        break;
      }
      case GET_PRODUCT_FAILURE: {
        draft.list = [];
        draft.loading = false;
        break;
      }
      case POST_PRODUCT_REQUEST: {
        draft.postLoading = true;
        break;
      }
      case POST_PRODUCT_SUCCESS: {
        const data = parseMeta(action.payload);
        const { productId } = data;
        const ind = draft.list.findIndex(x => x.productId === productId);
        if (ind > -1) {
          draft.list[ind] = {
            ...state.list[ind],
            ...data
          }
        }
        draft.postLoading = false;
        break;
      }
      case POST_PRODUCT_FAILURE: {
        draft.postLoading = false;
        break;
      }
    }
  });

export default mainReducer;
