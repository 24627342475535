import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { useInjectSaga } from '@utils/injectSaga';
import { useInjectReducer } from '@utils/injectReducer';
import { getProductAction } from './actions';
import { selectProduct } from './selectors';
import reducer from './reducer';
import saga from './saga';

import List from './List';
import Detail from './Detail';
import './style.less';

export const key = 'productMeta';

function ProductMeta(props) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    props.getList();
  });

  return (
    <>
      <Helmet>
        <title>Sản phẩm</title>
      </Helmet>
      <List />
      {props.product.pId && <Detail />}
    </>
  );
}

ProductMeta.propTypes = {
  getList: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  product: selectProduct(),
});

const mapDispatchToProps = dispatch => ({
  getList: () => dispatch(getProductAction()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(ProductMeta);
