import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Layout, Menu, Icon, Drawer, Button } from 'antd';

import './style.less';

const SubNav = ({ routes, selectedKeys }) => {
  const [isCollapse, setIsCollapse] = useState(false);
  const handleCollapse = collapsed => {
    setIsCollapse(collapsed);
  }
  const renderMenu = () => (
    <Menu theme="light" selectedKeys={selectedKeys} mode="inline" onClick={() => handleCollapse(false)}>
      {routes.map(route =>
        route.sidebar ? (
          <Menu.Item key={route.path || '/notfound'}>
            <Link to={route.path || '/notfound'}>
              <Icon type={route.icon} />
              <span>{route.name}</span>
            </Link>
          </Menu.Item>
        ) : (
          <></>
        ),
      )}
    </Menu>
  )
  return (
    <>
      <Layout.Sider theme="light" collapsible onCollapse={handleCollapse} collapsed={isCollapse} className="appSider">
        {renderMenu()}
      </Layout.Sider>
      <nav className="subNav">
        <Button icon={isCollapse ? "left" : "right"} onClick={() => handleCollapse(!isCollapse)} />
      </nav>
      <Drawer className="subNavDrawer" visible={isCollapse} placement="left" onClose={() => setIsCollapse(false)}>
        {renderMenu()}
      </Drawer>
    </>
  );
}

SubNav.propTypes = {
  routes: PropTypes.array,
  selectedKeys: PropTypes.array,

};

SubNav.defaultProps = {
  routes: [],
  selectedKeys: []
}

export default memo(SubNav);
