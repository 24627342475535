import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useMutation } from 'react-query';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import request from '@utils/request';
import { formatNumber, groupBy } from '@utils/helper';
import { makeSelectUserList } from '@/global.selectors';
import { Row, Col, Spin } from 'antd';
import { Column, Area } from '@ant-design/plots';
import RelativeDate from '@common/RelativeDate';
import SumTable from '@common/SumTable';

function Income(props) {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [source, setSource] = useState([]);
  const [isColumn, setIsColumn] = useState(false);
  const { isLoading, mutate } = useMutation(({ type, timeRange }) => {
    const { from, to, range } = timeRange;
    return request.get(`v1/statistics/income?type=source&from=${from}&to=${to}&timeRange=${range || 'day'}`);
  },
    {
      onSuccess: (response) => {
        initData(response.data);
      },
    })
  const initData = (resData) => {
    const tableData = [];
    const sourceData = groupBy(resData, 'source');
    const tData = resData.map(x => {
      const item = {
        ...x,
        total: parseInt(x.total, 0),
      }
      const tableInd = tableData.findIndex(t => t.timeRange === x.timeRange);
      if (tableInd < 0) {
        const tableItem = {
          timeRange: item.timeRange,
          total: item.total,
        };
        sourceData.forEach(s => tableItem[s] = 0);
        tableItem[item.source] = item.total;
        tableData.push(tableItem);
      } else {
        tableData[tableInd][item.source] = item.total;
        tableData[tableInd].total += item.total;
      }
      return item;
    });
    setTableData(tableData);
    setSource(sourceData);
    setIsColumn(tableData.length === 1)
    setData(tData);
  }
  const onChangeDate = (type, timeRange) => {
    setData([]);
    setTableData([]);
    mutate({ type, timeRange });
  }
  const config = {
    data,
    isStack: true,
    xField: 'timeRange',
    yField: 'total',
    seriesField: 'source',
    yAxis: {
      label: {
        formatter: formatNumber,
      }
    },
    xAxis: {
      label: {
        formatter: formatNumber,
      }
    },

    label: {
      formatter: obj => {
        return formatNumber(obj.total);
      },
    },
  };
  const columns = [
    {
      title: 'Ngày',
      dataIndex: 'timeRange',
    },
    {
      title: 'Tổng',
      dataIndex: 'total',
      align: 'right',
      render: formatNumber,
      sorter: (a, b) => a.total - b.total,
    }
  ]
  source.forEach(s => {
    columns.push({
      title: s,
      dataIndex: s,
      align: 'right',
      render: formatNumber,
      sorter: (a, b) => a[s] - b[s],
    })
  })
  const numberColumns = ['total', ...source];
  return (<>
    <Row gutter={16}>
      <Col sm={14}>
        <h2>Doanh thu theo nguồn bán hàng</h2>
      </Col>
      <Col sm={10}>
        <RelativeDate onChange={onChangeDate} />
      </Col>
    </Row>
    <Row gutter={16}>
      <Col sm={24}>
        <Spin spinning={isLoading}>
          {isColumn ?
            <Column {...config} />
            : <Area {...config} />
          }
        </Spin>
      </Col>
    </Row>
    <Row gutter={16} style={{ marginTop: '2rem' }}>
      <Col sm={24}>
        <SumTable
          pagination={false}
          dataSource={tableData}
          columns={columns}
          numberColumns={numberColumns}
          rowKey="timeRange" />
      </Col>
    </Row>
  </>)
}

Income.propTypes = {
};

Income.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  userList: makeSelectUserList()
});

const mapDispatchToProps = dispatch => ({

});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Income);
