import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  getCashIncomeSuccess,
  getCashIncomeFailure,
} from './cash.actions';
import { GET_CASH_INCOME_REQUEST } from './cash.constants';
import { getCashIncomeApi } from './cash.api';
import { makeSelectFilterDate } from './cash.selectors';

export function* getCashIncomeSaga() {
  try {
    const filterDate = yield select(makeSelectFilterDate());
    let filterInput = {
      date: { ...filterDate }
    };
    const res = yield call(getCashIncomeApi, filterInput);
    yield put(getCashIncomeSuccess(res.data));
  } catch (error) {
    console.log(error);
    yield put(getCashIncomeFailure(error));
  }
}

export default function* boardSaga() {
  yield takeLatest(GET_CASH_INCOME_REQUEST, getCashIncomeSaga);
}
