import React, { useState } from 'react';
import { useMutation } from 'react-query';
import request from '@utils/request';
import { formatNumber } from '@utils/helper';
import { Row, Col, Spin } from 'antd';
import { Treemap } from '@ant-design/plots';
import RelativeDate from '@common/RelativeDate';
import SumTable from '@common/SumTable';
import { SIZE_COLUMN } from '../constants';


function SaleQuantity() {
  const [tableData, setTableData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const { isLoading, mutate } = useMutation(({ timeRange }) => {
    const { from, to } = timeRange;
    return request.get(`v1/statistics/products?type=qty&from=${from}&to=${to}`);
  },
    {
      onSuccess: (response) => {
        initData(response.data);
      },
    })
  const initData = (resData) => {
    const tableData = [];
    resData.forEach(x => {
      let { groupName } = x;
      let size = x.size;
      if (size === 'X' || size === 'XX') {
        size += 'L';
      } else if (size === 'XLX') {
        size = 'XXL';
      }
      if (!SIZE_COLUMN.includes(size)) {
        groupName = `${groupName} - ${size}`;
        size = 'M';
      }

      let qty = parseInt(x.qty, 0);
      //table data
      const tableInd = tableData.findIndex(t => t.groupName === groupName);
      if (tableInd < 0) {
        const tableItem = {
          groupName: groupName,
          total: qty,
        };
        SIZE_COLUMN.forEach(s => tableItem[s] = 0);
        tableItem[size] = qty;
        tableData.push(tableItem);
      } else {
        tableData[tableInd][size] = qty;
        tableData[tableInd].total += qty;
      }

    });
    tableData.sort((a, b) => b.total - a.total);
    const treeData = tableData.map(t => ({
      name: t.groupName,
      value: t.total,
    }))
    setTableData(tableData);
    setChartData({
      name: 'root',
      children: treeData
    });
  }
  const onChangeDate = (type, timeRange) => {
    setTableData([]);
    mutate({ timeRange });
  }
  const columns = [
    {
      title: 'Sản phẩm',
      dataIndex: 'groupName',
    },
    {
      title: 'Tổng',
      dataIndex: 'total',
      align: 'right',
      render: formatNumber,
      sorter: (a, b) => a.total - b.total,
    }
  ]
  SIZE_COLUMN.forEach(s => {
    columns.push({
      title: s,
      dataIndex: s,
      align: 'right',
      render: formatNumber,
      sorter: (a, b) => a[s] - b[s],
    })
  })
  const numberColumns = ['total', ...SIZE_COLUMN];
  return (<>
    <Row gutter={16}>
      <Col sm={14}>
        <h2>Sản phẩm đã bán theo số lượng</h2>
      </Col>
      <Col sm={10}>
        <RelativeDate onChange={onChangeDate} />
      </Col>
    </Row>
    <Spin spinning={isLoading}>
      <Row gutter={16} style={{ marginTop: '2rem' }}>
        <Treemap data={chartData} colorField="name" />
      </Row>
      <Row gutter={16} style={{ marginTop: '2rem' }}>
        <Col sm={24}>
          <SumTable
            numberColumns={numberColumns}
            pagination={false}
            dataSource={tableData}
            columns={columns}
            rowKey="timeRange" />
        </Col>
      </Row>
    </Spin>
  </>)
}

SaleQuantity.propTypes = {
};

SaleQuantity.defaultProps = {
};

export default SaleQuantity;
