import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Tabs, Row, Col, Spin } from 'antd';

import { useInjectSaga } from '@utils/injectSaga';
import { useInjectReducer } from '@utils/injectReducer';
import { makeSelectUser } from '@/global.selectors';
import { getOrderAction, handleModalShowAction, updateStatusOrderAction } from './order.actions';
import reducer from './order.reducer';
import saga from './order.saga';
import { ORDER_TYPE_ENUM, ORDER_TYPE, ORDER_STATUS } from './order.constants';
import { makeSelectOrderType, makeSelectFilter, makeSelectUpdateStatusLoading, makeSelectOrderListLoading } from './order.selectors';

import OrderList from './List';
import FilterDate from './Filter/filterDate';
import Filter from './Filter';
import OrderCreate from './Create';
import UpdateStatusButton from './UpdateStatusButton';

const { TabPane } = Tabs;
const key = 'order';

function Order(props) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    props.getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTitle = () => {
    const { filter } = props;
    if (filter.status) {
      const statusItem = ORDER_STATUS.find(x => x.value === filter.status);
      if (statusItem) {
        return `Đơn Hàng ${statusItem.label}`;
      }
    }
    return 'Đơn hàng';
  }
  const renderUpdateButton = () => {
    const { filter, user } = props;
    return <UpdateStatusButton role={user.role} status={filter.status} />
  }

  return (
    <>
      <Helmet>
        <title>{getTitle()}</title>
      </Helmet>
      <div style={{ marginBottom: 16 }}>
        <h2>
          {getTitle()}
          {renderUpdateButton()}
        </h2>
        <Row>
          <Col sm={12}>
            <OrderCreate />
          </Col>
          <Col sm={6} />
          <Col sm={6}>
            <FilterDate />
          </Col>
        </Row>
        <Row>
          <Filter />
        </Row>
      </div>
      <Spin spinning={props.orderLoading}>
        <Tabs defaultActiveKey={`${ORDER_TYPE_ENUM.ALL}`}>
          {ORDER_TYPE.map(type => (
            <TabPane tab={`${type.label} (${props.orderType[type.value]})`} key={type.value}>
              <OrderList type={type.value} />
            </TabPane>
          ))}
        </Tabs>
      </Spin>
    </>
  );
}

Order.propTypes = {
  getOrders: PropTypes.func,
  handleModalShow: PropTypes.func,
  orderType: PropTypes.objectOf(PropTypes.any),
  filter: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any),
};
Order.defaultProps = {
  orderType: {},
};

const mapStateToProps = createStructuredSelector({
  orderType: makeSelectOrderType(),
  filter: makeSelectFilter(),
  updateStatusLoading: makeSelectUpdateStatusLoading(),
  user: makeSelectUser(),
  orderLoading: makeSelectOrderListLoading(),
});

const mapDispatchToProps = dispatch => ({
  getOrders: () => dispatch(getOrderAction()),
  handleModalShow: () => dispatch(handleModalShowAction()),
  handleUpdateStatusOrder: () => dispatch(updateStatusOrderAction()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Order);
