
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import querystring from 'query-string';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { makeSelectUserList, makeSelectUser } from '@/global.selectors';
import { useMutation, useQuery } from 'react-query';
import request from '@utils/request';
import { Row, Col, Spin, Button, Drawer } from 'antd';
import CreditForm from './CreditForm';
import CreditList from './CreditList';
import CreditStat from './CreditStat';
import Filter from './Filter';


function CashBook(props) {
  const lastDeptId = parseInt(localStorage.getItem('LAST_DEPT_ID') || '1', 0);
  const [deptId, setDeptId] = useState(lastDeptId);
  const [showStat, setShowStat] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [timeRange, setTimeRange] = useState({});
  const [placeList, setPlaceList] = useState([]);
  const [list, setList] = useState([]);
  const [cashStatus, setCashStatus] = useState({});
  const [creditItem, setCreditItem] = useState({});
  const { refetch: getStatus } = useQuery(['getStatus', deptId, timeRange], () => {
    const option = {
      deptID: deptId,
      year: timeRange.year,
      month: timeRange.month
    }
    const qs = querystring.stringify(option);
    return request.get(`v1/credit/status?${qs}`);
  },
    {
      onSuccess: (response) => {
        const { data } = response;
        if (data.length) {
          const item = data[0];
          setCashStatus({
            income: parseInt(item.income, 0),
            outcome: parseInt(item.outcome, 0),
          })
        }
        else {
          setCashStatus({
            income: 0,
            outcome: 0,
          })
        }
      },
    });
  const { isLoading: isLoadingCategory, mutate: getCategoryList } = useMutation(() => {
    return request.get(`v1/credit/categories`);
  },
    {
      onSuccess: (response) => {
        setCategoryList(response.data);
      },
    });

  const { isLoading: isLoadingPlace, mutate: getPlaceList } = useMutation(() => {
    return request.get('v1/admin/places');
  },
    {
      onSuccess: (response) => {
        setPlaceList(response.data);
      },
    });
  const { isLoadingList, refetch: getCreditList } = useQuery(['getCreditList', deptId, timeRange], () => {
    const option = {
      deptID: deptId,
      year: timeRange.year,
      month: timeRange.month
    }
    const qs = querystring.stringify(option);
    return request.get(`v1/credit?${qs}`);
  },
    {
      onSuccess: (response) => {
        setList(response.data);
      },
    });
  const { mutate: deleteItem } = useMutation((id) => {
    return request.delete(`v1/credit/${id}`);
  },
    {
      onSuccess: (response) => {
        getCreditList();
        getStatus();
      },
    });
  useEffect(() => {
    getCategoryList();
    getPlaceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleSave = () => {
    getCreditList();
    getStatus();
    setCreditItem({})
  }
  const handleSelect = (item) => {
    setCreditItem(item);
  }
  const handleDelete = (id) => {
    deleteItem(id);
  }
  return (<>
    <Spin spinning={isLoadingCategory || isLoadingPlace}>
      <Row gutter={16}>
        <Col sm={8}>
          <h2>Quản lý thu chi</h2>
        </Col>
        <Col sm={4}>
          {props.user.role < 2 && <Button onClick={() => setShowStat(true)}>Thống kê</Button>}
        </Col>
        <Col sm={12}>
          <Filter
            placeList={placeList}
            placeId={deptId}
            onChange={setDeptId}
            onChangeDate={setTimeRange}
            cashStatus={cashStatus}
          />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: '2rem' }}>
        <Col sm={24}>
          <CreditForm
            deptID={deptId}
            categoryList={categoryList}
            year={timeRange.year}
            month={timeRange.month}
            onSave={handleSave}
            onCancel={handleSelect}
          />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: '2rem' }}>
        <Col sm={24}>
          <CreditList
            loading={isLoadingList}
            deptID={deptId}
            categoryList={categoryList}
            year={timeRange.year}
            month={timeRange.month}
            dataSource={list}
            onSelect={handleSelect}
            onDelete={handleDelete}
          />
        </Col>
      </Row>
    </Spin>
    <Drawer visible={creditItem.id} onClose={handleSelect} width="50%" title="Cập nhật phiếu thu chi">
      <CreditForm
        credit={creditItem}
        deptID={deptId}
        categoryList={categoryList}
        year={timeRange.year}
        month={timeRange.month}
        onSave={handleSave}
        onCancel={handleSelect}
      />
    </Drawer>
    {showStat &&
      <Drawer visible onClose={() => setShowStat(false)} width="50%" title="Thống kê thu chi">
        <CreditStat
          deptID={deptId}
          categoryList={categoryList}
          year={timeRange.year}
          month={timeRange.month}
        />
      </Drawer>}
  </>)
}

CashBook.propTypes = {
};

CashBook.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  userList: makeSelectUserList(),
  user: makeSelectUser(),
});

const mapDispatchToProps = dispatch => ({

});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CashBook);
