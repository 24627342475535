import { createSelector } from 'reselect';
import { initialState } from './orderTracking.reducer';
import { key } from './index';

const selectBoardDomain = state => state[key] || initialState;


const makeSelectList = () => createSelector(selectBoardDomain, substate => substate.list);
const makeSelectLoading = () => createSelector(selectBoardDomain, substate => substate.loading);

const makeSelectPostLoading = () => createSelector(selectBoardDomain, substate => substate.postLoading);
const makeSelectSelectedOrder = () => createSelector(selectBoardDomain, substate => substate.selectedOrder);

export {
  selectBoardDomain,
  makeSelectList,
  makeSelectLoading,
  makeSelectPostLoading,
  makeSelectSelectedOrder
};
