import {
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_FAILURE,
  GET_PRODUCT_SUCCESS,
  POST_PRODUCT_REQUEST,
  POST_PRODUCT_FAILURE,
  POST_PRODUCT_SUCCESS,
  ON_SELECT_PRODUCT,
  ON_CHANGE_FILTER
} from './constants';


export const getProductAction = payload => ({ type: GET_PRODUCT_REQUEST, payload });
export const getProductSuccess = payload => ({ type: GET_PRODUCT_SUCCESS, payload });
export const getProductFailure = payload => ({ type: GET_PRODUCT_FAILURE, payload });

export const postProductAction = payload => ({ type: POST_PRODUCT_REQUEST, payload });
export const postProductSuccess = payload => ({ type: POST_PRODUCT_SUCCESS, payload });
export const postProductFailure = payload => ({ type: POST_PRODUCT_FAILURE, payload });

export const onSelectProductAction = payload => ({ type: ON_SELECT_PRODUCT, payload });

export const onChangeFilterAction = payload => ({ type: ON_CHANGE_FILTER, payload });