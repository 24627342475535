export const GET_CASH_INCOME_REQUEST = 'cash/GET_CASH_INCOME_REQUEST';
export const GET_CASH_INCOME_SUCCESS = 'cash/GET_CASH_INCOME_SUCCESS';
export const GET_CASH_INCOME_FAILURE = 'cash/GET_CASH_INCOME_FAILURE';


export const ON_CHANGE_FILTER = 'cash/ON_CHANGE_FILTER';
export const ON_CHANGE_DATE_FILTER = 'cash/ON_CHANGE_DATE_FILTER';

export const FILTER_DATE_ENUM = {
  ALL: 'all',
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  THISMONTH: 'this_month',
  LASTMONTH: 'last_month',
  CUSTOM: 'custom',
};

export const FILTER_DATE = [
  { value: FILTER_DATE_ENUM.ALL, label: 'Tất cả' },
  { value: FILTER_DATE_ENUM.TODAY, label: 'Hôm nay' },
  { value: FILTER_DATE_ENUM.YESTERDAY, label: 'Hôm qua' },
  { value: FILTER_DATE_ENUM.THISMONTH, label: 'Tháng này' },
  { value: FILTER_DATE_ENUM.LASTMONTH, label: 'Tháng trước' },
  { value: FILTER_DATE_ENUM.CUSTOM, label: 'Tùy chọn' },
];
