import Payload from './Payload';
import PayloadItem from './Payload/item';
import Bonus from './Bonus';
import CheckInHistory from './CheckInHistory';
import UserList from './UserList';

const PARENT = '/admin';
const routes = [
  {
    exact: true,
    path: `${PARENT}`,
    name: 'Danh sách NV',
    icon: 'area-chart',
    component: UserList,
    sidebar: true,
  },
  {
    exact: true,
    path: `${PARENT}/payload`,
    name: 'Bảng lương',
    icon: 'area-chart',
    component: Payload,
    sidebar: true,
  },
  {
    exact: true,
    path: `${PARENT}/bonus`,
    name: 'Thưởng',
    icon: 'dollar',
    component: Bonus,
    sidebar: true,
  },
  {
    exact: true,
    path: `${PARENT}/checkin-history`,
    name: 'Chấm công',
    icon: 'history',
    component: CheckInHistory,
    sidebar: true,
  },
  {
    exact: true,
    path: `${PARENT}/payload/detail`,
    name: 'Bảng lương chi tiết',
    icon: 'area-chart',
    component: PayloadItem,
  },
];

export default routes;
