import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import { Layout, Menu, Icon, Dropdown, Button } from 'antd';

import { makeSelectUser } from 'global.selectors';
import mainRoutes from 'routes/mainRoutes';

import './style.less';

const getSelectedKey = (path = '') => {
  let selectedKey = '/';
  if (path) {
    const paths = path.split('/');
    if (paths.length > 1) {
      selectedKey = `/${paths[1]}`;
    }
  }
  return selectedKey;
}

function Header(props) {
  const location = useLocation();
  const router = mainRoutes.filter(x => x.sidebar);
  const selectedKey = getSelectedKey(location.pathname);
  const renderMenu = (mode = 'horizontal', className = 'headerMenu') => (
    <Menu
      theme="light"
      mode={mode}
      defaultSelectedKeys={[selectedKey]}
      className={className}
    >
      {router.map(route =>
        route.sidebar && (!route.permission || route.permission >= props.user.role) ? (
          <Menu.Item key={route.path || '/notfound'}>
            <Link to={route.path || '/notfound'}>
              <Icon type={route.icon} />
              <span>{route.name}</span>
            </Link>
          </Menu.Item>
        ) : (
          <></>
        ),
      )}
    </Menu>
  )
  return (
    <Layout.Header className="header">
      <Dropdown trigger={["click"]} overlay={renderMenu('vertical', 'mobileMenu')} placement="bottomLeft">
        <Button icon="menu" className="mobileMenuBtn" />
      </Dropdown>
      <Link to="/" className="logo">
        <img src="/OTina.png" alt="logo" />
      </Link>
      {renderMenu()}
      <Link to="/users" style={{ cursor: 'pointer' }}>
        <span className="avatar" style={{}}>
          <img alt="avatar" src={props.user.avatar || '/favicon.ico'} />
          <span>{props.user.name}</span>
        </span>
      </Link>
    </Layout.Header>
  );
}

Header.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, memo)(Header);
