import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Button } from 'antd';
import request from '@utils/request';

import { makeSelectOrderList } from '../order.selectors';
import { ORDER_STATUS_ENUM } from '../order.constants';

const QUANTITY = 10;
const params = {
  from: 0,
  to: 0,
  isRunning: false,
};
function UpdateStatusButton(props) {
  const [processing, setProcessing] = useState(false);
  const [updated, setUpdated] = useState(0);
  let tTime = null;
  const { isLoading, mutate: updateStatusFn, } = useMutation(({ from, to, orderList }) => {
    const orderCodes = [];
    for (let i = from; i < to; i++) {
      const { orderCode } = orderList[i];
      if (orderCode.indexOf('SON') > -1) {
        orderCodes.push(orderCode.trim());
      }
    }
    return request.post(`v1/pre-orders/update-status`, { orderCodes });
  },
    {
      onSuccess: () => {
        setUpdated(updated => updated + QUANTITY);
        clearTimeout(tTime);
        tTime = setTimeout(() => {
          initStatus();
        }, 1000);
      },
    })
  const callApi = () => {
    if (!params.from && params.to) {
      clearTimeout(tTime);
      return;
    }
    if (!params.to) {
      params.to = props.orderList.length;
    } else {
      params.to = params.from;
    }
    params.from = params.to > QUANTITY ? params.to - QUANTITY : 0;
    updateStatusFn({ ...params, orderList: props.orderList });
  }
  const initStatus = () => {
    if (params.isRunning) {
      callApi();
    } else {
      clearTimeout(tTime);
    }
  }
  const handleClickStatus = () => {
    setProcessing(true);
    params.isRunning = true;
    callApi();
  }
  if (props.role > 1 || props.status !== ORDER_STATUS_ENUM.DELIVERY) return null;
  return (
    <Button style={{ marginLeft: 20 }}
      loading={isLoading}
      disabled={processing}
      onClick={handleClickStatus}>
      {processing ? `Updating ${updated}/${props.orderList.length}` : 'Update Status'}
    </Button>
  );
}

UpdateStatusButton.propTypes = {
  role: PropTypes.number,
  status: PropTypes.number,
};

UpdateStatusButton.defaultProps = {
  role: 0,
  status: 0,
};

const mapStateToProps = createStructuredSelector({
  orderList: makeSelectOrderList(),
});

const mapDispatchToProps = dispatch => ({
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(UpdateStatusButton);
