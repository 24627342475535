import { key } from './index';

export const GET_PRODUCT_REQUEST = `${key}/GET_PRODUCT_REQUEST`;
export const GET_PRODUCT_SUCCESS = `${key}/GET_PRODUCT_SUCCESS`;
export const GET_PRODUCT_FAILURE = `${key}/GET_PRODUCT_FAILURE`;

export const POST_PRODUCT_REQUEST = `${key}/POST_PRODUCT_REQUEST`;
export const POST_PRODUCT_SUCCESS = `${key}/POST_PRODUCT_SUCCESS`;
export const POST_PRODUCT_FAILURE = `${key}/POST_PRODUCT_FAILURE`;

export const ON_SELECT_PRODUCT = `${key}/ON_SELECT_PRODUCT`;

export const SIZE_COLUMN = ['sizeM', 'sizeL', 'sizeXL', 'sizeXXL'];
export const FULL_SIZE_COLUMN = ['sizeM', 'sizeL', 'sizeXL', 'sizeXXL', 'sizeRRE'];
export const FULL_SIZE = ['sizeM', 'sizeL', 'sizeXL', 'sizeX', 'sizeXXL', 'sizeXX', 'sizeRRE'];
export const BRANCH = [
  { value: 2, label: 'Kho' },
  { value: 1, label: 'Shop' },
];
export const COLUMN = [
  { label: 'V1', value: 'bust' },
  { label: 'V2', value: 'waist' },
  { label: 'V3', value: 'butt' },
  { label: 'Vai', value: 'shoulder' },
  // { label: 'Nách', value: 'armhole' },
  { label: 'Dài tay', value: 'handLength' },
  { label: 'Rộng tay', value: 'handWidth' },
]

export const ON_CHANGE_FILTER = `${key}/ON_CHANGE_FILTER`;

export const PRODUCE_STATUS = {
  NOTHING: 0,
  PENDING: 1,
  PRODUCING: 2
}

export const PRODUCE_STATUS_LIST = [
  {value: PRODUCE_STATUS.NOTHING, label: '-'},
  {value: PRODUCE_STATUS.PENDING, label: 'Đã đặt vải'},
  {value: PRODUCE_STATUS.PRODUCING, label: 'Đang may'},
]