import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Row, Col, Card, Button, Spin } from 'antd';
import { formatTime, duration } from 'utils/helper';

import UserInfo from '@common/UserInfo';
import { makeSelectUser } from '@/global.selectors';
import { checkInAction } from '../dashboard.actions';
import { makeSelectPlaces, makeSelectOnlineList, makeSelectCheckInLoading, makeSelectPlaceLoading, makeSelectPlaceLocationLoading } from '../dashboard.selectors';
import './style.less';

class Place extends PureComponent {
  handleCheckIn = async (place, type = 1) => {
    const data = {
      type,
      placeId: place.id,
      placeType: place.type
    }
    this.props.checkIn(data);
  }
  initData = (places, onlineList, user) => {
    let loginPlaceId = 0;
    const result = places.map(x => {
      const place = { ...x }
      const onlines = onlineList.filter(x => x.placeId === place.id);
      const userList = [];
      onlines.forEach(o => {
        const ind = userList.findIndex(x => x.id === o.userId);
        if (ind < 0) {
          if (o.type === 1) {
            userList.push({
              id: o.userId,
              start: o.createdAt
            })
          }
        } else {
          if (o.type === 1) {
            userList[ind].start = o.createdAt;
            userList[ind].end = 0;
          } else {
            userList[ind].end = o.createdAt;
            userList[ind].duration = userList[ind].end - userList[ind].start;
          }
        }
      });
      place.userList = userList;
      if (!loginPlaceId) {
        const uInd = userList.find(x => x.id === user.id)
        if (uInd && !uInd.end) {
          loginPlaceId = place.id;
        }
      }
      return place;
    })
    return [result, loginPlaceId];
  }
  renderTime = user => {
    let result = `${formatTime(user.start * 1000)}`;
    if (user.end) {
      result += ` - ${formatTime(user.end * 1000)}`;
      return result;
    }
    const now = Math.round(new Date() / 1000);
    let durationTime = duration(now - user.start);
    return `${result} (${durationTime} trước)`;
  }
  renderButton = (place, loginPlaceId, loading, locationLoading) => {
    if (loginPlaceId && place.id === loginPlaceId) {
      return <Button loading={loading} disabled={loading} onClick={() => this.handleCheckIn(place, 2)}>Check Out</Button>;
    } else if (!loginPlaceId && (place.isInPlace || place.type === 2)) {
      return <Button loading={loading} disabled={loading} onClick={() => this.handleCheckIn(place)}>Check In</Button>;
    } else if (locationLoading) {
      return <Spin spinning={true} />
    }
    return '';
  }
  render() {
    const { places, onlineList, user, checkInLoading, placeLoading, placeLocationLoading } = this.props;
    const [dataSource, loginPlaceId] = this.initData(places, onlineList, user);
    return (
      <Spin spinning={placeLoading}>
        <Row gutter={16}>
          {dataSource.map(place => (
            <Col xs={24} sm={6} key={place.id}>
              <Card title={place.name} extra={this.renderButton(place, loginPlaceId, checkInLoading, placeLocationLoading)} style={{ marginBottom: 10 }}>
                {place.userList.map(user => (
                  <Row key={user.start} gutter={8} className={user.end ? 'userOffline' : ''}>
                    <Col xs={12}><UserInfo className="userOnlineInfo" userId={user.id} /></Col>
                    <Col xs={12} style={{ textAlign: 'right' }}>{this.renderTime(user)}</Col>
                  </Row>
                ))}
              </Card>
            </Col>
          ))}
        </Row>
      </Spin>
    );
  }
}

Place.propTypes = {
  onChangeFilter: PropTypes.func,
  getCashIncome: PropTypes.func,
  filter: PropTypes.objectOf(PropTypes.any),
  checkIn: PropTypes.func,
  checkOut: PropTypes.func,
  checkInLoading: PropTypes.bool,
  placeLoading: PropTypes.bool,
};

Place.defaultProps = {
  places: [],
  onlineList: [],
  checkInLoading: false,
  placeLoading: false,
};

const mapStateToProps = createStructuredSelector({
  places: makeSelectPlaces(),
  onlineList: makeSelectOnlineList(),
  user: makeSelectUser(),
  checkInLoading: makeSelectCheckInLoading(),
  placeLoading: makeSelectPlaceLoading(),
  placeLocationLoading: makeSelectPlaceLocationLoading(),
});

const mapDispatchToProps = dispatch => ({
  checkIn: data => dispatch(checkInAction(data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Place);
