import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Drawer, Form, Input, Button, Switch, Table } from 'antd';
import { validateFields, formatDateTime } from '@utils/helper';

import { makeSelectSelectedOrder, makeSelectPostLoading } from '../orderTracking.selectors';
import { postOrderMetaAction, onSelectOrderAction } from '../orderTracking.actions';

class OrderMetaDetail extends PureComponent {
  handleUpdate = async (e) => {
    e.preventDefault();
    const [err, values] = await validateFields(this.props.form);
    if (!err) {
      const { order } = this.props;
      const updateData = {
        orderCode: order.orderCode,
        ...values,
      }
      this.props.updateMeta(updateData);
    }
  }
  handleClose = () => {
    this.props.onSelectOrder();
  }
  renderLog = () => {
    const log = this.props.order.log;
    const columns = [
      {
        title: 'Ngày',
        dataIndex: 'createdAt',
        render: text => formatDateTime(text * 1000)
      },
      {
        title: 'Ghi chú',
        dataIndex: 'note',
      }
    ];
    return <Table
      dataSource={log}
      columns={columns}
      rowKey="createdAt"
    />
  }
  render() {
    const { order, loading } = this.props;
    if (!order.orderCode) return '';
    const { getFieldDecorator } = this.props.form;
    return (
      <Drawer
        title="Cập nhật trạng thái đơn hàng"
        placement="right"
        onClose={this.handleClose}
        visible
        width="50%"
        className="updateProductDrawer"
      >
        <Form onSubmit={this.handleUpdate}>
          <Form.Item label="Ghi chú">
            {getFieldDecorator('log', { initialValue: '' })(
              <Input />
            )}
          </Form.Item>
          <Form.Item label="Đơn bồi hoàn" labelAlign='left' labelCol={{ md: 4 }} wrapperCol={{ md: 20 }}>
            {getFieldDecorator('isChargeBack', {
              initialValue: order.isChargeBack,
              valuePropName: 'checked'
            })(
              <Switch />
            )}
          </Form.Item>
          <Form.Item>
            <Button disabled={loading} loading={loading} type="primary" htmlType="submit">Lưu</Button>
          </Form.Item>
        </Form>
        {this.renderLog()}
      </Drawer >
    );
  }
}

OrderMetaDetail.propTypes = {
  order: PropTypes.object,
};

OrderMetaDetail.defaultProps = {
  order: {},
};

const mapStateToProps = createStructuredSelector({
  order: makeSelectSelectedOrder(),
  loading: makeSelectPostLoading(),
});

const mapDispatchToProps = dispatch => ({
  onSelectOrder: () => dispatch(onSelectOrderAction({})),
  updateMeta: data => dispatch(postOrderMetaAction(data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const OrderMetaDetailWrapper = Form.create()(OrderMetaDetail)

export default compose(withConnect, memo)(OrderMetaDetailWrapper);
