import { takeLatest, call, put } from 'redux-saga/effects';
import {
  getProductListSuccess,
  getProductListFailure,
  getUserListSuccess,
  getUserListFailure,
  getProductMetaListSuccess,
  getProductMetaListFailure,
} from './global.actions';
import { GET_PRODUCT_LIST_REQUEST, GET_USER_LIST_REQUEST, GET_PRODUCT_META_LIST_REQUEST } from './global.constants';
import { getProductListApi, getUserListApi, getProductMetaListApi } from './global.api';


export function* getProductListSaga() {
  try {
    const res = yield call(getProductListApi);
    yield put(getProductListSuccess(res.data));
  } catch (error) {
    yield put(getProductListFailure(error));
  }
}

export function* getProductMetaListSaga() {
  try {
    const res = yield call(getProductMetaListApi);
    yield put(getProductMetaListSuccess(res.data));
  } catch (error) {
    yield put(getProductMetaListFailure(error));
  }
}

export function* getUserListSaga() {
  try {
    const res = yield call(getUserListApi);
    yield put(getUserListSuccess(res.data));
  } catch (error) {
    yield put(getUserListFailure(error));
  }
}


export default function* boardSaga() {
  yield takeLatest(GET_PRODUCT_LIST_REQUEST, getProductListSaga);
  yield takeLatest(GET_USER_LIST_REQUEST, getUserListSaga);
  yield takeLatest(GET_PRODUCT_META_LIST_REQUEST, getProductMetaListSaga);
}
