import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Menu, Layout, Icon } from 'antd';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import routes from './routes';



export const key = 'admin';

function Admin(props) {

  const [isCollapse, setIsCollapse] = useState(false);
  const handleCollapse = collapsed => {
    setIsCollapse(collapsed);
  }
  let title = 'Admin';
  const selectedRoute = routes.find(x => x.path === props.location.pathname);
  if (selectedRoute) {
    title = selectedRoute.name;
  }
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Layout style={{ minHeight: '100vh', margin: '-16px' }}>
        <Layout.Sider theme="light" collapsible onCollapse={handleCollapse} collapsed={isCollapse} className="appSider">
          <Menu theme="light" selectedKeys={[props.location.pathname]} mode="inline">
            {routes.map(route =>
              route.sidebar ? (
                <Menu.Item key={route.name}>
                  <Link to={route.path || '/notfound'}>
                    <Icon type={route.icon} />
                    <span>{route.name}</span>
                  </Link>
                </Menu.Item>
              ) : (
                <></>
              ),
            )}
          </Menu>
        </Layout.Sider>
        <Layout.Content style={{ margin: '16px' }}>
          <Route path="/admin">
            <Switch>
              {routes.map(route => (<Route key={route.name} {...route} />))}
            </Switch>
          </Route>
        </Layout.Content>
      </Layout>
    </>
  );
}

Admin.propTypes = {
};

const mapStateToProps = createStructuredSelector({
});

const mapDispatchToProps = dispatch => ({
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Admin);
