import {
  GET_PLACES_REQUEST,
  GET_PLACES_FAILURE,
  GET_PLACES_SUCCESS,
  GET_ONLINE_REQUEST,
  GET_ONLINE_FAILURE,
  GET_ONLINE_SUCCESS,
  CHECKIN_REQUEST,
  CHECKIN_FAILURE,
  CHECKIN_SUCCESS,
  CHECKOUT_REQUEST,
  CHECKOUT_FAILURE,
  CHECKOUT_SUCCESS,
  GET_CHECKIN_HISTORY_REQUEST,
  GET_CHECKIN_HISTORY_FAILURE,
  GET_CHECKIN_HISTORY_SUCCESS,
  GET_PLACES_LOCATION_REQUEST,
  GET_PLACES_LOCATION_FAILURE,
  GET_PLACES_LOCATION_SUCCESS,
} from './dashboard.constants';

export const getPlacesAction = payload => ({ type: GET_PLACES_REQUEST, payload });
export const getPlacesSuccess = payload => ({ type: GET_PLACES_SUCCESS, payload });
export const getPlacesFailure = payload => ({ type: GET_PLACES_FAILURE, payload });

export const getOnlineAction = payload => ({ type: GET_ONLINE_REQUEST, payload });
export const getOnlineSuccess = payload => ({ type: GET_ONLINE_SUCCESS, payload });
export const getOnlineFailure = payload => ({ type: GET_ONLINE_FAILURE, payload });

export const checkInAction = payload => ({ type: CHECKIN_REQUEST, payload });
export const checkInSuccess = payload => ({ type: CHECKIN_SUCCESS, payload });
export const checkInFailure = payload => ({ type: CHECKIN_FAILURE, payload });

export const checkOutAction = payload => ({ type: CHECKOUT_REQUEST, payload });
export const checkOutSuccess = payload => ({ type: CHECKOUT_SUCCESS, payload });
export const checkOutFailure = payload => ({ type: CHECKOUT_FAILURE, payload });

export const getCheckInHistoryAction = payload => ({ type: GET_CHECKIN_HISTORY_REQUEST, payload });
export const getCheckInHistorySuccess = payload => ({ type: GET_CHECKIN_HISTORY_SUCCESS, payload });
export const getCheckInHistoryFailure = payload => ({ type: GET_CHECKIN_HISTORY_FAILURE, payload });

export const getPlaceLocationAction = payload => ({ type: GET_PLACES_LOCATION_REQUEST, payload });
export const getPlaceLocationSuccess = payload => ({ type: GET_PLACES_LOCATION_SUCCESS, payload });
export const getPlaceLocationFailure = payload => ({ type: GET_PLACES_LOCATION_FAILURE, payload });