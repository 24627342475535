
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, DatePicker, Row, Col } from 'antd';
import moment from 'moment';
import { getStartUnix, getEndUnix } from '@utils/helper';

export const FILTER_DATE_ENUM = {
  ALL: 'all',
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST7DAYS: 'last_7_days',
  LAST14DAYS: 'last_14_days',
  LAST30DAYS: 'last_30_days',
  LAST60DAYS: 'last_60_days',
  LAST90DAYS: 'last_90_days',
  THISMONTH: 'this_month',
  LASTMONTH: 'last_month',
  LAST3MONTHS: 'last_3_months',
  LAST6MONTHS: 'last_6_months',
  THISYEAR: 'this_year',
  LASTYEAR: 'last_year',
  CUSTOM: 'custom',
};

export const FILTER_DATE = [
  { value: FILTER_DATE_ENUM.ALL, label: 'Tối đa' },
  { value: FILTER_DATE_ENUM.TODAY, label: 'Hôm nay' },
  { value: FILTER_DATE_ENUM.YESTERDAY, label: 'Hôm qua' },
  { value: FILTER_DATE_ENUM.LAST7DAYS, label: '7 ngày trước' },
  { value: FILTER_DATE_ENUM.LAST14DAYS, label: '14 ngày trước' },
  { value: FILTER_DATE_ENUM.LAST30DAYS, label: '30 ngày trước' },
  { value: FILTER_DATE_ENUM.LAST60DAYS, label: '60 ngày trước' },
  { value: FILTER_DATE_ENUM.LAST90DAYS, label: '90 ngày trước' },
  { value: FILTER_DATE_ENUM.THISMONTH, label: 'Tháng này' },
  { value: FILTER_DATE_ENUM.LASTMONTH, label: 'Tháng trước' },
  { value: FILTER_DATE_ENUM.LAST3MONTHS, label: '3 tháng trước' },
  { value: FILTER_DATE_ENUM.LAST6MONTHS, label: '6 tháng trước' },
  { value: FILTER_DATE_ENUM.THISYEAR, label: 'Năm nay' },
  { value: FILTER_DATE_ENUM.LASTYEAR, label: 'Năm trước' },
  { value: FILTER_DATE_ENUM.CUSTOM, label: 'Tùy chọn' },
];

export const getDate = type => {
  let from = 0, to = 0, range = 'day';
  const date = new Date();
  switch (type) {
    case FILTER_DATE_ENUM.ALL: {
      const firstDay = new Date(2020, 2, 1);
      from = getStartUnix(firstDay);
      to = getEndUnix(date);
      range = 'year';
      break;
    }
    case FILTER_DATE_ENUM.TODAY: {
      from = moment().startOf('day').unix();
      to = moment().endOf('day').unix();
      break;
    }
    case FILTER_DATE_ENUM.YESTERDAY: {
      from = moment().subtract(1, 'days').startOf('day').unix();
      to = moment().subtract(1, 'days').endOf('day').unix();
      break;
    }
    case FILTER_DATE_ENUM.LAST7DAYS:
    case FILTER_DATE_ENUM.LAST14DAYS:
    case FILTER_DATE_ENUM.LAST30DAYS:
    case FILTER_DATE_ENUM.LAST60DAYS:
    case FILTER_DATE_ENUM.LAST90DAYS: {
      const aDays = type.split('_');
      const days = parseInt(aDays[1], 0);
      from = moment().subtract(days, 'days').startOf('day').unix();
      to = moment().subtract(1, 'days').endOf('day').unix();
      break;
    }
    case FILTER_DATE_ENUM.THISMONTH: {
      from = moment().startOf('month').unix();
      to = moment().endOf('month').unix();
      break;
    }
    case FILTER_DATE_ENUM.LASTMONTH: {
      from = moment().subtract(1, 'months').startOf('month').unix();
      to = moment().subtract(1, 'months').endOf('month').unix();
      break;
    }
    case FILTER_DATE_ENUM.LAST3MONTHS:
    case FILTER_DATE_ENUM.LAST6MONTHS: {
      const aMonths = type.split('_');
      const months = parseInt(aMonths[1], 0);
      from = moment().subtract(months*30, 'days').startOf('day').unix();
      to = moment().subtract(1, 'days').endOf('day').unix();
      break;
    }
    case FILTER_DATE_ENUM.THISYEAR: {
      from = moment().startOf('year').unix();
      to = moment().endOf('year').unix();
      range = 'month';
      break;
    }
    case FILTER_DATE_ENUM.LASTYEAR: {
      from = moment().subtract(1, 'years').startOf('year').unix();
      to = moment().subtract(1, 'years').endOf('year').unix();
      range = 'month';
      break;
    }
    default: {

      break;
    }
  }
  return { from, to, range };
}

const { SubMenu } = Menu;
const { RangePicker } = DatePicker;


function RelativeDate(props) {
  const [dateType, setDateType] = useState(FILTER_DATE_ENUM.TODAY);
  const [timeRange, setTimeRange] = useState({});

  const handleChangeType = item => {
    setDateType(item.key);
    if (item.key !== FILTER_DATE_ENUM.CUSTOM) {
      const newTimeRange = getDate(item.key);
      setTimeRange(newTimeRange);
      props.onChange(item.key, newTimeRange);
    } else {
      props.onChange(item.key, timeRange);
    }
  };
  const handleChangeDate = ranges => {
    const from = ranges[0].unix();
    const to = ranges[1].unix();
    let range = 'day';
    if (to - from > 3600 * 24 * 465) {
      range = 'year';
    } else if (to - from > 3600 * 24 * 40) {
      range = 'month'
    }
    props.onChange(dateType, { from, to, range });
    setTimeRange({ from, to });
  }
  useEffect(() => {
    handleChangeType({ key: props.type });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let title = 'Chọn thời gian';
  const filterDate = FILTER_DATE.find(x => x.value === dateType);
  if (filterDate) {
    title = filterDate.label;
  }
  const from = moment.unix(timeRange.from || 0);
  const to = moment.unix(timeRange.to || 0);
  return (
    <Row gutter={16}>
      <Col sm={12}>
        <Menu onClick={handleChangeType} selectedKeys={dateType}>
          <SubMenu key={'relSub'} title={title} popupClassName="relativeDatePicker">
            {FILTER_DATE.map(item => (
              <Menu.Item key={item.value}>{item.label}</Menu.Item>
            ))}
          </SubMenu>
        </Menu>
      </Col>
      <Col sm={12}>
        {dateType === FILTER_DATE_ENUM.CUSTOM && <RangePicker onChange={handleChangeDate} value={[from, to]} />}
      </Col>
    </Row>
  );
}

RelativeDate.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func
};

RelativeDate.defaultProps = {
  type: FILTER_DATE_ENUM.TODAY,
  onChange: () => { },
};

export default RelativeDate;
