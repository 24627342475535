
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import querystring from 'query-string';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { makeSelectUserList, makeSelectUser } from '@/global.selectors';
import { useMutation, useQuery } from 'react-query';
import request from '@utils/request';
import { Row, Col, Spin, Button, Drawer } from 'antd';
import UserList from '@common/UserList';
import TrackingForm from './TrackingForm';
import TrackingCategory from './TrackingCategory';
import Filter from './Filter';
import Statistic from './Statistic';


function InboxTracking(props) {
  const [categoryList, setCategoryList] = useState([]);
  const [showStat, setShowStat] = useState(false);
  const [list, setList] = useState([]);
  const [timeRange, setTimeRange] = useState({});
  const [userId, setUserId] = useState(props.user.id);

  const { isLoading: isLoadingCategory, mutate: getCategoryList } = useMutation(() => {
    return request.get(`v1/tracking-categories`);
  },
    {
      onSuccess: (response) => {
        setCategoryList(response.data);
      },
    });

  const { isLoading: isLoadingList } = useQuery(['getTrackingList', timeRange, userId], () => {
    const option = {
      userId: userId,
      year: timeRange.year,
      month: timeRange.month,
      day: timeRange.day
    }
    const qs = querystring.stringify(option);
    return request.get(`v1/tracking-inboxs?${qs}`);
  },
    {
      onSuccess: (response) => {
        setList(response.data);
      },
    });
  useEffect(() => {
    getCategoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getTrackingItem = categoryId => {
    const item = list.find(x => x.trackingCategoryId === categoryId);
    return item || {};
  }
  const handleSaveCategory = () => {
    getCategoryList();
  }
  const handleChangeUser = value => {
    setUserId(value);
  }
  return (<>
    <Spin spinning={isLoadingCategory || isLoadingList}>
      <Row gutter={16}>
        <Col sm={8}>
          <h2>Thống kê inbox</h2>
        </Col>
        <Col sm={4}>
          {props.user.role < 2 && <Button onClick={() => setShowStat(true)}>Thống kê</Button>}
        </Col>
        <Col sm={12}>
          <Filter
            onChange={setTimeRange}
          />
        </Col>
      </Row>
      {props.user.role < 2 &&
      <Row gutter={16} style={{ marginTop: '2rem' }}>
        <Col sm={4}>
          <UserList type="select" userId={userId} onChange={handleChangeUser} />
        </Col>
      </Row>}
      <Row gutter={16} style={{ marginTop: '2rem' }}>
        {categoryList.map(cat => (
          <Col sm={24}>
            <TrackingForm 
              category={cat}
              item={getTrackingItem(cat.id)}
              year={timeRange.year}
              month={timeRange.month}
              day={timeRange.day}
              userId={userId}
            />
          </Col>  
        ))}
      </Row>
      <Row gutter={16} style={{ marginTop: '2rem' }}>
        <Col sm={24}>
          <TrackingCategory 
            categoryList={categoryList}
            onSave={handleSaveCategory}
          />
        </Col>  
      </Row>
    </Spin>
    {showStat &&
      <Drawer visible onClose={() => setShowStat(false)} width="50%" title="Thống kê">
        <Statistic
          categoryList={categoryList}
          year={timeRange.year}
          month={timeRange.month}
        />
      </Drawer>}
  </>)
}

InboxTracking.propTypes = {
};

InboxTracking.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  userList: makeSelectUserList(),
  user: makeSelectUser(),
});

const mapDispatchToProps = dispatch => ({

});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(InboxTracking);
