import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useMutation } from 'react-query';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import request from '@utils/request';
import { formatNumber, groupBy } from '@utils/helper';
import { makeSelectUserList } from '@/global.selectors';
import { Row, Col, Spin } from 'antd';
import { Column, Area } from '@ant-design/plots';
import RelativeDate from '@common/RelativeDate';
import SumTable from '@common/SumTable';

function Order(props) {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [source, setSource] = useState([]);
  const [isColumn, setIsColumn] = useState(false);
  const { isLoading, mutate } = useMutation(({ type, timeRange }) => {
    const { from, to, range } = timeRange;
    return request.get(`v1/statistics/sales?from=${from}&to=${to}&timeRange=${range || 'day'}`);
  },
    {
      onSuccess: (response) => {
        initData(response.data);
      },
    })
  const initData = (resData) => {
    const sourceData = groupBy(resData, 'source', 'Unknown');
    const tData = [];
    const tableData = [];
    resData.forEach(x => {
      const { timeRange, qty } = x;
      let source = x.source || 'Unknown';
      const pastQty = parseInt(x.pastQty, 0);
      const oldRate = Math.round(pastQty / qty * 100);
      let tDataIndex = tData.findIndex(x => x.source === source && x.timeRange === timeRange);
      if (tDataIndex < 0) {
        sourceData.forEach(s => {
          tData.push({
            timeRange,
            quantity: 0,
            pastQty: 0,
            source: s,
            oldRate: 0,
          });
        });
      }
      tDataIndex = tData.findIndex(x => x.source === source && x.timeRange === timeRange);
      if (tDataIndex > -1) {
        tData[tDataIndex].quantity += qty;
        tData[tDataIndex].pastQty += pastQty;
        tData[tDataIndex].oldRate = Math.round(tData[tDataIndex].pastQty / tData[tDataIndex].quantity * 100);
      }
      const tableInd = tableData.findIndex(x => x.timeRange === timeRange);
      if (tableInd < 0) {
        tableData.push({
          timeRange,
          quantity: qty,
          [source]: qty,
          [`${source}OldRate`]: oldRate,
        });
      } else {
        tableData[tableInd].quantity += qty;
        tableData[tableInd][source] = tableData[tableInd][source] ? tableData[tableInd][source] + qty : qty;
        tableData[tableInd][`${source}OldRate`] = oldRate;
      }
    });
    console.log(tData);
    setTableData(tableData);
    setIsColumn(tableData.length === 1);
    setData(tData);
    setSource(sourceData);
  }
  const onChangeDate = (type, timeRange) => {
    setData([]);
    setTableData([]);
    mutate({ type, timeRange });
  }
  const config = {
    data,
    isStack: true,
    xField: 'timeRange',
    yField: 'quantity',
    seriesField: 'source',
    yAxis: {
      label: {
        formatter: formatNumber,
      }
    },
    xAxis: {
      label: {
        formatter: formatNumber,
      }
    },

    label: {
      formatter: obj => {
        return `${formatNumber(obj.quantity)} (Khách cũ: ${obj.oldRate}%)`;
      },
    },
  };
  const columns = [
    {
      title: 'Thời gian',
      dataIndex: 'timeRange',
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      align: 'right',
      render: formatNumber,
      sorter: (a, b) => a.qty - b.qty,
    },
  ]
  source.forEach(s => {
    columns.push({
      title: s,
      dataIndex: s,
      align: 'right',
      render: (text, record) => formatNumber(text, '-', ` (Cũ: ${record[`${s}OldRate`] || 0}%)`),
      sorter: (a, b) => a[s] - b[s],
    })
  })
  const numberColumns = ['quantity', ...source];
  return (<>
    <Row gutter={16}>
      <Col sm={14}>
        <h2>Thống kê theo đơn hàng tạo ra</h2>
      </Col>
      <Col sm={10}>
        <RelativeDate onChange={onChangeDate} />
      </Col>
    </Row>
    <Row gutter={16}>
      <Col sm={24}>
        <Spin spinning={isLoading}>
          {isColumn ?
            <Column {...config} />
            : <Area {...config} />
          }
        </Spin>
      </Col>
    </Row>
    <Row gutter={16} style={{ marginTop: '2rem' }}>
      <Col sm={24}>
        <SumTable
          numberColumns={numberColumns}
          pagination={false}
          dataSource={tableData}
          columns={columns}
          rowKey="timeRange" />
      </Col>
    </Row>
  </>)
}

Order.propTypes = {
};

Order.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  userList: makeSelectUserList()
});

const mapDispatchToProps = dispatch => ({

});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Order);
